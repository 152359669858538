import React, { useCallback, useEffect } from 'react';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@material-ui/core';
import { CustomTableRow } from '../../../../../components/TableComponents/TableRow';
import { CustomTHeadTextCell } from '../../../../../components/TableComponents/TableHead/TextHeadCell';
import { useQuery } from '../../../../../utils/useQuery';
import { IApplication } from '../../../../../services/http/company/interfaces';
import { useParams } from 'react-router-dom';
import { AccountRole, CompaniesParams } from '../../../../../enums';
import { useModalApplicationBodyStyles } from './ModalApplicationBodyStyles';
import { GoToProfileLink } from '../../../../../components/GoToProfileLink';
import { getWorkingExperienceByMonths } from '../../../../../utils/getMonthExperience';
import { useInfiniteScrolling } from '../../../../../utils/useInfiniteScrolling';
import { CompaniesService } from '../../../../../services/http/company';
import { Loading } from '../../../../../components/Loading';

const tableHeaders = [
  { text: '#' },
  {
    text: 'NAME',
  },
  {
    text: 'LOCATION',
  },
  {
    text: 'LANGUAGES',
  },
  {
    text: 'EXPERIENCE',
  },
];

interface IModalApplicationBodyProps {
  setModalItemsCount: (count: number) => void;
}

export const ModalApplicationsBody = ({ setModalItemsCount }: IModalApplicationBodyProps) => {
  const classNames = useModalApplicationBodyStyles();
  const { currQueryParams } = useQuery();
  const { companyId } = useParams<{ companyId: string }>();
  const vacancyId = currQueryParams.get(CompaniesParams.MODAL_VACANCY_ID);
  const status = currQueryParams.get(CompaniesParams.APPLICANTS_STATUS);

  const fetchApplicationsFunc = useCallback(
    (id: string, pageNumber: number, count: string) =>
      CompaniesService.getCompanyVacancyApplications(companyId, id, status, pageNumber, count),
    [companyId, vacancyId, status]
  );
  const { lastElement, page, isLoading, totalCount } = useInfiniteScrolling<IApplication>(
    fetchApplicationsFunc,
    vacancyId || ''
  );

  useEffect(() => {
    totalCount && setModalItemsCount(totalCount);
  }, [totalCount]);

  return (
    <TableContainer className={classNames.Container}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {tableHeaders.map((el) => (
              <CustomTHeadTextCell key={el.text} text={el.text} style={{ background: '#F2F5F9' }} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {page.map((el, index, arr) => {
            return (
              <CustomTableRow key={el.Id} lastElement={index === arr.length - 1 ? lastElement : undefined}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <GoToProfileLink id={el.Candidate.Id} role={AccountRole.CANDIDATE}>
                    {el.Candidate.FullName}
                  </GoToProfileLink>
                </TableCell>
                <TableCell>
                  {Object.values(el.Candidate.LocationInfo).length > 0 &&
                    `${el.Candidate.LocationInfo.Country}, ${el.Candidate.LocationInfo.City}`}
                </TableCell>
                <TableCell>{el.Candidate.Languages.join(', ')}</TableCell>
                <TableCell>{getWorkingExperienceByMonths(el.Candidate.RelevantExperience)}</TableCell>
              </CustomTableRow>
            );
          })}

          {isLoading && (
            <CustomTableRow>
              <TableCell colSpan={4}>
                <Loading />
              </TableCell>
            </CustomTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
