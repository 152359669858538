import { makeStyles } from '@material-ui/core/styles';

export const useCommonMainHeaderStyles = makeStyles((theme) => ({
  Header: {
    marginTop: 20,
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    paddingBottom: 29,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Profile: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  Title: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  ProfileInfoSection: {
    marginLeft: 22,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
}));
