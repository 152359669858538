import React, { useEffect, useState } from 'react';
import { HomeLayout } from '../../layouts/Home';
import { CandidatesMain } from './Main';
import { Filters } from '../Candidates/Filters';
import { useToggle } from '../../utils/useToggle';
import { useQuery } from 'utils/useQuery';
import { QueryParams } from 'enums';

export const Candidates = () => {
  const { show, hide, isToggled } = useToggle(false);
  const [initialized, setInitialized] = useState(false);
  const { currQueryParams, setParam } = useQuery();

  useEffect(() => {
    !currQueryParams.get(QueryParams.SORT_BY) &&
      !currQueryParams.get(QueryParams.SORT_BY_DESC) &&
      setParam(QueryParams.SORT_BY_DESC, 'CreatedOn');
    setInitialized(true);
  }, []);

  return (
    <HomeLayout filters={<Filters isShow={isToggled} hideFilters={hide} />} isFiltersShown={isToggled}>
      {initialized ? <CandidatesMain showFilters={show} isFiltersShow={isToggled} /> : <></>}
    </HomeLayout>
  );
};
