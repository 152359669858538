import { makeStyles } from '@material-ui/core/styles';

export const useModalApplicationBodyStyles = makeStyles({
  Root: {
    width: '100%',
  },
  Container: {
    maxHeight: 745,
    '&::-webkit-scrollbar': {
      width: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      height: 139,
      background: '#CCD7E6',
      borderRadius: 20,
    },
  },
  NumberCell: {
    width: 30,
  },
});
