import React, { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { AccountRole, JobsParams, QueryParams } from '../../../../enums';
import { useQuery } from '../../../../utils/useQuery';
import useAxios from 'axios-hooks';
import { HttpService } from '../../../../services/http/axios';
import { TableLayout } from '../../../../layouts/Table';
import { ITableHeadCellData } from '../../../../components/TableComponents/TableHead';
import { IGetPaginationResponse } from '../../../../services/http/interfaces';
import { IApplication } from '../../../../services/http/company/interfaces';
import { CustomTHeadTextCell } from '../../../../components/TableComponents/TableHead/TextHeadCell';
import { CustomTableRow } from '../../../../components/TableComponents/TableRow';
import { createDateTime, DateTimeFormat } from '../../../../utils/createDateTime';
import { StyledTab, StyledTabs } from '../../../../components/ui/StyledTabs';
import { useApplicationsStyles } from './ApplicationsStyles';
import { GoToProfileLink } from '../../../../components/GoToProfileLink';
import { IGetVacancy } from 'services/http/jobs/interfaces';
import { TabLabelWithCount } from 'components/TabLabelWithCount';
import { getWorkingExperienceByMonths } from 'utils/getMonthExperience';

const count = '9';

enum SortNames {
  NAME = 'Name',
  LOCATION = 'Location',
  WORKING_EXPERIENCE = 'WorkingExperience',
  LANGUAGES = 'Languages',
  DATE = 'Date',
}

const tableHeaders: ITableHeadCellData[] = [
  { text: '#' },
  {
    text: 'NAME',
    sortName: SortNames.NAME,
  },
  {
    text: 'LOCATION',
    sortName: SortNames.LOCATION,
  },
  { text: 'WORKING EXPERIENCE', sortName: SortNames.WORKING_EXPERIENCE },
  {
    text: 'LANGUAGES',
    sortName: SortNames.LANGUAGES,
  },
  {
    text: 'DATE',
    sortName: SortNames.DATE,
  },
];

const getApplicationStatusName = (status: string) => {
  switch (status) {
    case '0':
      return 'Applied';
    case '2':
      return 'Shortlisted';
    case '3':
      return 'Rejected';
    case '1':
      return 'Expired';
    default:
      return '';
  }
};

interface IApplicationsProps {
  statistics: IGetVacancy['Statistics'];
}

export const Applications = ({ statistics }: IApplicationsProps) => {
  const { currQueryParams, setParam } = useQuery();
  const { jobId, companyId } = useParams<{ jobId: string; companyId: string }>();
  const status = currQueryParams.get(JobsParams.STATUS);
  const classNames = useApplicationsStyles();

  useEffect(() => {
    const currStatus = currQueryParams.get(JobsParams.STATUS);

    if (!currStatus) {
      setParam(JobsParams.STATUS, '0');
    }
  }, [currQueryParams]);

  const page = currQueryParams.get(QueryParams.PAGE) || '1';

  const [{ data: applicationsResponse, loading: applicationsLoading }] = useAxios<IGetPaginationResponse<IApplication>>(
    {
      url: `${HttpService.env.adminAPI}/companies/${companyId}/jobs/${jobId}/applications`,
      params: { status, count, page, sortByDesc: 'CreatedOn' },
    },
    { useCache: false }
  );

  const statusFilterOnChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      setParam(JobsParams.STATUS, newValue);
      setParam(QueryParams.PAGE, '1');
    },
    [setParam]
  );
  const currStatusFilter = currQueryParams.get(JobsParams.STATUS) || '0';

  return (
    <>
      <StyledTabs
        className={classNames.StatusTabs}
        value={currStatusFilter}
        indicatorColor="primary"
        onChange={statusFilterOnChange}
      >
        <StyledTab
          value={'0'}
          label={
            <TabLabelWithCount
              title="Applied"
              count={
                statistics
                  ? statistics.ApplicationsCount -
                    statistics.ShortlistsCount -
                    statistics.RejectsCount -
                    statistics.ExpiresCount
                  : null
              }
              isSelected={currStatusFilter === '0'}
            />
          }
        />
        <StyledTab
          value={'2'}
          label={
            <TabLabelWithCount
              title="Shortlisted"
              count={statistics ? statistics.ShortlistsCount : null}
              isSelected={currStatusFilter === '2'}
            />
          }
        />
        <StyledTab
          value={'3'}
          label={
            <TabLabelWithCount
              title="Rejected"
              count={statistics ? statistics.RejectsCount : null}
              isSelected={currStatusFilter === '3'}
            />
          }
        />
        <StyledTab
          value={'1'}
          label={
            <TabLabelWithCount
              title="Expired"
              count={statistics ? statistics.ExpiresCount : null}
              isSelected={currStatusFilter === '1'}
            />
          }
        />
      </StyledTabs>
      <TableLayout
        loading={applicationsLoading}
        numberOfPages={Math.ceil((applicationsResponse?.TotalItemCount || 0) / parseInt(count))}
        currentPage={parseInt(page)}
        isEmpty={applicationsResponse?.Page.length === 0}
        noItemsMessage={`No ${getApplicationStatusName(currStatusFilter)} applications found`}
      >
        <TableHead style={{ background: '#F2F5F9' }}>
          <TableRow>
            {tableHeaders.map((el) => (
              <CustomTHeadTextCell key={el.text} text={el.text} />
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {applicationsResponse?.Page.map((el, index) => {
            return (
              <CustomTableRow key={el.Id}>
                <TableCell>{(applicationsResponse.PageIndex - 1) * parseInt(count) + index + 1}</TableCell>
                <TableCell>
                  <GoToProfileLink id={el.Candidate.Id} role={AccountRole.CANDIDATE}>
                    {el.Candidate.FullName}
                  </GoToProfileLink>
                </TableCell>
                <TableCell>
                  {Object.values(el.Candidate.LocationInfo).length > 0 &&
                    `${el.Candidate.LocationInfo.Country}, ${el.Candidate.LocationInfo.City}`}
                </TableCell>
                <TableCell>{getWorkingExperienceByMonths(el.Candidate.RelevantExperience)}</TableCell>
                <TableCell>{el.Candidate.Languages.join(', ')}</TableCell>
                <TableCell>{createDateTime(el.CreatedOn).toFormat(DateTimeFormat.FULL)}</TableCell>
              </CustomTableRow>
            );
          })}
        </TableBody>
      </TableLayout>
    </>
  );
};
