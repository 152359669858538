import { INotificationContext } from './notificationContext';
import { ErrorMessage } from '../enums';
import { ConfirmOptions } from 'material-ui-confirm';

type Handler = (id: string) => Promise<unknown>;

// handle confirmation cancellation at catch
export const deleteByIds = async (
  ids: string[],
  handler: Handler,
  setNotification: INotificationContext['setNotification'],
  successMessage: string,
  confirm: (options?: ConfirmOptions) => Promise<void>,
  description?: string
) => {
  try {
    await confirm({ description: description || 'This action is permanent!' });
    const deletePromises = ids.map((x) => handler(x));
    await Promise.all(deletePromises);

    setNotification({ text: successMessage, severity: 'success' });
  } catch (error) {
    if (!error) {
      throw 'Confirmation cancelled';
    }

    const status = error?.response?.status;

    if (error?.response === undefined) {
      setNotification({ text: ErrorMessage.NO_RESPONSE, severity: 'error' });
    } else if (status >= 400 && status < 500) {
      setNotification({
        text: ErrorMessage.UNABLE_TO_PROCESS_REQUEST,
        severity: 'error',
      });
    } else {
      setNotification({ text: ErrorMessage.SERVER_ERROR, severity: 'error' });
    }
  }
};
