import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useLoadingStyles } from './LoadingStyles';

interface ILoading {
  size?: number | string;
}

export const Loading = ({ size }: ILoading) => {
  const classNames = useLoadingStyles();

  return (
    <div className={classNames.LoaderWrapper}>
      <CircularProgress color="inherit" size={size} />
    </div>
  );
};
