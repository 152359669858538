import React from 'react';
import { useFormikContext, getIn } from 'formik';
import { useErrorOutputStyles } from './ErrorOutputStyles';
import { FormHelperText } from '@material-ui/core';

interface IErrorOutputProps<FormType> {
  name: (keyof FormType & string) | string;
  forceShow?: boolean;
}

export function ErrorOutput<FormType>({ name, forceShow = false }: IErrorOutputProps<FormType>) {
  const { errors, touched } = useFormikContext<FormType>();

  const classNames = useErrorOutputStyles();
  const error = getIn(errors, name) || (errors as any)[name];

  if ((!error || !getIn(touched, name)) && !forceShow) {
    return null;
  }

  return <FormHelperText className={classNames.Error}>{error}</FormHelperText>;
}
