import { makeStyles } from '@material-ui/core/styles';

export const useDataSortStyles = makeStyles((theme) => ({
  Container: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  DateRangeWrapper: {
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ClearDataSort: {
    marginLeft: 7,
    paddingBottom: 10,
    color: theme.palette.text.primary,
    fontSize: 10,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
  Button: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    border: `1px solid ${theme.palette.secondary.dark}`,
    fontSize: 14,
    color: '#78849B',
    borderRadius: 28,
    marginLeft: 12,
    textAlign: 'center',
    transition: '.5s ease',
    padding: '6px 16px',
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      color: '#FFF',
    },
  },
  DataSortIcon: {
    width: 15,
    height: 13,
  },
  DropdownIconSvg: {
    width: 10,
    height: 6,
  },
  Button_active: {
    backgroundColor: theme.palette.text.primary,
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF',
      color: theme.palette.text.primary,
    },
  },
  MenuList: {
    padding: 9,
    width: 200,
    height: 132,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#FFF',
    borderRadius: 10,
    boxShadow: '0px 4px 12px rgba(120, 132, 155, 0.1)',
  },
  MenuItem: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  Active: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));
