import { makeStyles } from '@material-ui/core/styles';

export const useFormInputStyles = makeStyles((theme) => ({
  TextArea: {
    width: '100%',
    height: 48,
    borderRadius: 6,
    border: `1px solid ${theme.palette.secondary.dark}`,
    boxSizing: 'border-box',
    padding: '16px 0 14px 16px',
    transition: '0.3s',
    outline: 0,
    '&:focus-within': {
      borderColor: theme.palette.primary.main,
    },
  },
}));
