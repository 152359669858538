import React from 'react';
import { TableCell } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { useTableMessageOfCellStyles } from './TableMessageStyles';
import { useToggle } from '../../../../utils/useToggle';

interface ICustomTableMessageCellProps {
  className?: string;
  text: string;
}

export const TableMessageCell = ({ text, className }: ICustomTableMessageCellProps) => {
  const classNames = useTableMessageOfCellStyles();
  const { isToggled, toggle } = useToggle(false);

  return (
    <TableCell className={`${className ?? ''} ${classNames.TableCell}`} onClick={toggle}>
      {text ? (
        <Collapse
          className={`${classNames.Collapse} ${isToggled ? classNames._expanded : ''}`}
          in={isToggled}
          collapsedHeight={20}
        >
          {text}
        </Collapse>
      ) : null}
    </TableCell>
  );
};
