import React from 'react';
import { List, ListItem, Typography } from '@material-ui/core';
import { IVacancyQuestion } from '../../../../services/http/jobs/interfaces';
import { useQuestionsListStyles } from './QuestionsListStyles';

interface IQuestionsList {
  questions: IVacancyQuestion[];
}

export const QuestionsList = ({ questions }: IQuestionsList) => {
  const classNames = useQuestionsListStyles();

  return (
    <div className={classNames.QuestionsList}>
      <Typography variant="h5">Additional questions</Typography>
      <List>
        {questions.map((el, index) => (
          <ListItem key={el.Text + index}>
            <Typography variant="h5">{index + 1}</Typography>.
            <Typography className={classNames.QuestionText} variant="subtitle1">
              {el.Text}
            </Typography>
          </ListItem>
        ))}
      </List>
    </div>
  );
};
