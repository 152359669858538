import React, { useCallback, useMemo } from 'react';
import { Box, Drawer, Grid, Typography } from '@material-ui/core';
import { AuthService } from 'services/http/auth';
import useAxios from 'axios-hooks';
import { HttpService } from 'services/http/axios';
import { IAccount } from 'services/http/auth/interfaces';
import { CustomBackdrop } from 'components/CustomBackdrop';
import { Form, Formik, FormikProps } from 'formik';
import { getFormInput, getFormRadioGroup } from 'components/FormComponents/getFormInput';
import { AccountRole } from 'enums';
import { getRoleLabel } from './AddTeamMember';
import { StyledCancelButton } from 'components/ui/CancelButton';
import { useConfirm } from 'material-ui-confirm';
import { useAccount } from 'utils/useAccount';
import { validateEditTeamMember } from 'utils/validations/validateEditTeamMember';
import { StyledSaveButton } from 'components/ui/SaveButton';
import { Loading } from 'components/Loading';
import { formSubmitCatchAsync } from 'utils/submitFormCatchAsync';
import { useNotificationContext } from 'utils/notificationContext';

interface IEditTeamMemberDrawerProps {
  accountId: string;
  closeDrawer: () => void;
  onEdited: () => void;
}

export const EditTeamMemberDrawer = ({ accountId, closeDrawer, onEdited }: IEditTeamMemberDrawerProps) => {
  return (
    <Drawer anchor="right" open={!!accountId} onClose={closeDrawer} ModalProps={{ disableBackdropClick: true }}>
      {accountId && <EditTeamMember accountId={accountId} onCancel={closeDrawer} onEdited={onEdited} />}
    </Drawer>
  );
};

export interface IEditAccountForm {
  fullname: string;
  role: string;
}
interface IEditTeamMemberProps {
  accountId: string;
  onCancel: () => void;
  onEdited: () => void;
}

const initialValues: IEditAccountForm = {
  fullname: '',
  role: '',
};

const getInitialValues = (account?: IAccount): IEditAccountForm => {
  if (!account) {
    return initialValues;
  }

  return {
    fullname: account.FullName,
    role: '' + account.Role,
  };
};

const EditTeamMember = ({ accountId, onCancel, onEdited }: IEditTeamMemberProps) => {
  const [{ data: account, loading }] = useAxios<IAccount>(`${HttpService.env.adminAPI}/users/${accountId}`, {
    useCache: false,
  });
  const { setNotification } = useNotificationContext();

  const confirm = useConfirm();

  const submitHandler = useCallback(
    formSubmitCatchAsync<IEditAccountForm>(async (formData, formikHelpers) => {
      await AuthService.patchUser(accountId, {
        FullName: formData.fullname,
        Role: +formData.role,
      });
      setNotification({
        text: `${formData.fullname} was edited`,
        severity: 'success',
      });
      formikHelpers.setSubmitting(false);
      onEdited();
    }),
    [accountId]
  );

  const cancelHandler = useCallback(() => {
    confirm({ description: 'Data will be lost, are you sure you want to cancel current changes?' })
      .then(() => {
        onCancel();
      })
      .catch(() => console.log(''));
  }, []);

  const initialValues = useMemo(() => getInitialValues(account), [account]);

  return (
    <Box m={3} style={{ width: 500 }}>
      {loading && <CustomBackdrop />}
      {account && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1">Edit Team member</Typography>
          </Grid>
          <Grid item xs={12}>
            <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validateEditTeamMember}>
              {(props) => <InnerForm {...props} cancelHandler={cancelHandler} accountId={accountId} />}
            </Formik>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

interface IInnerFormProps {
  cancelHandler: () => void;
  accountId: string;
}
const options = [
  {
    value: '' + AccountRole.OPERATOR,
    label: getRoleLabel(AccountRole.OPERATOR),
  },
  {
    value: '' + AccountRole.ADMIN,
    label: getRoleLabel(AccountRole.ADMIN),
  },
];
const FormInput = getFormInput<IEditAccountForm>();
const FormFormRadioGroup = getFormRadioGroup<IEditAccountForm>();

const InnerForm = ({ cancelHandler, accountId, isSubmitting }: FormikProps<IEditAccountForm> & IInnerFormProps) => {
  const { myAccount } = useAccount();

  return (
    <Form>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormInput label="Fullname" name="fullname" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <FormFormRadioGroup
            label=" Company role"
            name="role"
            options={options}
            disabled={myAccount.Id === accountId}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2} justify="flex-end">
            <Grid item>
              <StyledCancelButton onClick={cancelHandler}>Cancel</StyledCancelButton>
            </Grid>
            <Grid item>
              <StyledSaveButton type="submit" disabled={isSubmitting}>
                {isSubmitting ? <Loading /> : 'Save'}
              </StyledSaveButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};
