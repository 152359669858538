import React, { useEffect, useState } from 'react';
import { CandidateDetails } from 'pages/CandidateDetails';
import { Candidates } from 'pages/Candidates';
import { Companies } from 'pages/Companies';
import { CompanyDetails } from 'pages/CompanyDetails';
import { CompanyEdit } from 'pages/CompanyEdit';
import { JobDetails } from 'pages/JobDetails';
import { JobEdit } from 'pages/JobEdit';
import { Jobs } from 'pages/Jobs';
import { ExternalJobs } from 'pages/ExternalJobs';
import { Reports } from 'pages/Reports';
import { Sities } from 'pages/Sities';
import { Team } from 'pages/Team';
import { System } from 'pages/System';
import { Redirect, Route } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'intefaces';
import { CustomBackdrop } from 'components/CustomBackdrop';
import { AuthService } from 'services/http/auth';
import { setUser } from 'reduxStore/actions/user';
import { useAccount } from 'utils/useAccount';
import { ExternalJobsEdit } from 'pages/ExternalJobsEdit';

export const ProtectedLayout = () => {
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const { isAdmin } = useAccount();
  const { isAuthorized, user } = useSelector((state: IRootState) => ({
    isAuthorized: state.user.isAuthorized,
    user: state.user.user,
  }));

  const dispatch = useDispatch();

  useEffect(() => {
    AuthService.getUser()
      .then((user) => {
        dispatch(setUser(user));
      })
      .finally(() => {
        setUserLoading(false);
      });
  }, []);

  if (!isAuthorized) {
    return <Redirect to="/signin" />;
  }

  if (userLoading || !user) {
    return <CustomBackdrop />;
  }

  return (
    <>
      <Route exact path="/" render={() => <Redirect to="/companies" />} />
      <Route exact path="/companies" component={Companies} />
      <Route exact path="/company/:companyId" component={CompanyDetails} />
      {isAdmin && <Route exact path="/company/:companyId/edit" component={CompanyEdit} />}
      <Route exact path="/candidates" component={Candidates} />
      <Route exact path="/candidate/:candidateId" component={CandidateDetails} />
      <Route exact path="/reports" component={Reports} />
      <Route exact path="/jobs" component={Jobs} />
      <Route exact path="/externaljobs" component={ExternalJobs} />
      <Route exact path="/externaljobs/:jobId" component={ExternalJobsEdit} />
      <Route exact path="/company/:companyId/job/:jobId" component={JobDetails} />
      {isAdmin && <Route exact path="/company/:companyId/job/:jobId/edit" component={JobEdit} />}
      {isAdmin && <Route exact path="/sities" component={Sities} />}
      {isAdmin && <Route exact path="/team" component={Team} />}
      {isAdmin && <Route exact path="/system" component={System} />}
      {/* <Redirect from="*" exact to="/companies" /> */}
    </>
  );
};
