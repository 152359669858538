import React, { useCallback, useEffect } from 'react';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { useTableStyles } from '../../../styles';
import { Box, Button, Grid, TableBody, TableCell } from '@material-ui/core';
import { Search } from '../../../components/Search';
import { FilterButton } from '../../../components/FilterButton';
import { TableLayout } from '../../../layouts/Table';
import { CustomTableHead, ITableHeadCellData } from '../../../components/TableComponents/TableHead';
import useAxios from 'axios-hooks';
import { IGetJobsItem, VacancyType } from '../../../services/http/jobs/interfaces';
import { HttpService } from '../../../services/http/axios';
import { IGetPaginationResponse } from '../../../services/http/interfaces';
import { useQuery } from '../../../utils/useQuery';
import { JobsParams, QueryParams } from '../../../enums';
import { useCheckboxState } from '../../../utils/useCheckboxState';
import { useConfirm } from 'material-ui-confirm';
import { createDateTime, DateTimeFormat } from '../../../utils/createDateTime';
import { CustomTableRow } from '../../../components/TableComponents/TableRow';
import { CustomTableBodyCheckboxCell } from '../../../components/TableComponents/TableCheckboxCell';
import { Badge } from '../../../components/Badge';
import { getJobStatusBadgeData } from '../../../utils/getJobStatusBadgeData';
import { CompaniesService } from '../../../services/http/company';
import { useNotificationContext } from '../../../utils/notificationContext';
import { DataSortButton } from 'components/DataSort';
import { useRowClickHandler } from '../../../utils/useRowClickHandler';
import { PageTitleWithCount } from '../../../components/PageTitleWithCount';
import { StyledTab, StyledTabs } from '../../../components/ui/StyledTabs';
import { TableNumberOfCell } from '../../../components/TableComponents/TableRow/TableNumberOfCell';
import { TabLabelWithCount } from '../../../components/TabLabelWithCount';
import { useAccount } from 'utils/useAccount';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import AddIcon from '@material-ui/icons/Add';
import { useHistory } from 'react-router';
import { deleteByIds } from 'utils/deleteByIds';
import { JobService } from 'services/http/jobs';

interface IJobsMainProps {
  showFilters: () => void;
  isFiltersShow: boolean;
}

const count = '9';

enum SortNames {
  JOB_TITLE = 'Name',
  COMPANY = 'Company.Name',
  CATEGORY = 'Category.Name',
  LOCATION = 'Location',
  APPLICATIONS = 'Statistics.ApplicationsCount',
  SHORTLISTED = 'Statistics.ShortlistsCount',
  REJECTED = 'Statistics.RejectsCount',
  VIEWS = 'Statistics.ViewsCount',
  STATUS = 'Status',
  DATE = 'PublishDate',
}

const tableHeaders: ITableHeadCellData[] = [
  { text: '#' },
  {
    text: 'JOB TITLE',
    sortName: SortNames.JOB_TITLE,
  },
  {
    text: 'COMPANY',
    sortName: SortNames.COMPANY,
  },
  { text: 'CATEGORY', sortName: SortNames.CATEGORY },
  {
    text: 'LOCATION',
    sortName: SortNames.LOCATION,
  },
  {
    text: 'VIEWS',
    sortName: SortNames.VIEWS,
  },
  {
    text: 'STATUS',
    sortName: SortNames.STATUS,
  },
  {
    text: 'DATE',
    sortName: SortNames.DATE,
  },
];

const getJobStatusName = (status: string) => {
  switch (status) {
    case '0':
      return 'Active';
    case '1':
      return 'Closed';
    case '2':
      return 'Deleted';
    default:
      return '';
  }
};

export const JobsMain = ({ showFilters, isFiltersShow }: IJobsMainProps) => {
  const tableClassNames = useTableStyles();
  const { currQueryParams, setParam } = useQuery();
  const confirm = useConfirm();
  const { setNotification } = useNotificationContext();
  const rowClickHandler = useRowClickHandler();
  const { isAdmin } = useAccount();
  const history = useHistory();

  const newQueryParams = new URLSearchParams(currQueryParams.toString());
  newQueryParams.append('vacancyType', VacancyType.External.toString());
  const params = newQueryParams.toString();

  const [{ data: vacanciesResponse, loading }, fetchJobs] = useAxios<IGetPaginationResponse<IGetJobsItem>>(
    {
      url: `${HttpService.env.adminAPI}/jobs?${params}`,
      params: {
        count,
      },
    },
    { useCache: false }
  );

  useEffect(() => {
    if (!currQueryParams.get('sortBy') && !currQueryParams.get('sortByDesc')) {
      setParam(QueryParams.SORT_BY_DESC, SortNames.DATE);
    }

    if (!currQueryParams.get(JobsParams.STATUS)) {
      setParam(JobsParams.STATUS, '0');
    }
  }, []);

  const {
    checkedItemsState,
    getCheckedItemsIds,
    selectPage,
    unselectPage,
    checkboxOnChange,
    resetCheckboxes,
  } = useCheckboxState(vacanciesResponse?.Page.map((el) => ({ Id: el.Id })) || []);

  // need to work with job id and company id, workaround on my abstraction
  const deleteHandler = useCallback(() => {
    const checkedItemsIds = getCheckedItemsIds();

    deleteByIds(
      checkedItemsIds,
      (idsStr: string) => {
        return JobService.deleteExternalJob(idsStr);
      },
      setNotification,
      `${checkedItemsIds.length} jobs deleted`,
      confirm,
      'These jobs will be immediately deleted'
    )
      .then(() => {
        fetchJobs();
        // fetchJobsCounts();
      })
      .catch(() => {});
  }, [CompaniesService.deleteCompany, setNotification, confirm, getCheckedItemsIds]);

  const statusFilterOnChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      setParam(JobsParams.STATUS, newValue);
      setParam(QueryParams.PAGE, '1');
      resetCheckboxes();
    },
    [setParam]
  );

  const addNewHandle = useCallback(() => {
    history.push('/externaljobs/0');
  }, []);

  const page = currQueryParams.get(QueryParams.PAGE) || '1';
  const currStatusFilter = currQueryParams.get(JobsParams.STATUS) || '0';

  return (
    <MainLayout>
      <section className={tableClassNames.TableContainer}>
        <PageTitleWithCount title={'All external jobs'} />
        <Box mt={1}>
          <StyledTabs value={currStatusFilter} indicatorColor="primary" onChange={statusFilterOnChange}>
            <StyledTab
              value={'0'}
              label={<TabLabelWithCount title="Active" count={null} isSelected={currStatusFilter === '0'} />}
            />
            <StyledTab
              value={'1'}
              label={<TabLabelWithCount title="Closed" count={null} isSelected={currStatusFilter === '1'} />}
            />
            <StyledTab
              value={'2'}
              label={<TabLabelWithCount title="Deleted" count={null} isSelected={currStatusFilter === '2'} />}
            />
          </StyledTabs>
        </Box>
        <Box mt={3} mb={1}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Search />
                </Grid>
                <Grid item>
                  <FilterButton onClick={showFilters} isFiltersShow={isFiltersShow} />
                </Grid>
                <Grid item>
                  <DataSortButton fromKey={JobsParams.PUBLISH_DATE_FROM} toKey={JobsParams.PUBLISH_DATE_TO} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Button color="primary" variant="contained" startIcon={<AddIcon />} onClick={addNewHandle}>
                    Add job
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        {vacanciesResponse && (
          <TableLayout
            loading={loading}
            numberOfPages={Math.ceil(vacanciesResponse.TotalItemCount / parseInt(count))}
            currentPage={parseInt(page)}
            isEmpty={vacanciesResponse.Page.length === 0}
            noItemsMessage={`No ${getJobStatusName(currStatusFilter)} jobs found`}
          >
            <CustomTableHead
              tableHeaders={tableHeaders}
              selectPage={selectPage}
              unselectPage={unselectPage}
              checkedItemsState={checkedItemsState}
              deleteHandler={deleteHandler}
              editable={isAdmin}
            />
            <TableBody>
              {vacanciesResponse.Page.map((el, index) => {
                const badgeData = getJobStatusBadgeData(el.Status);
                const itemIdsStr = el.Id;

                return (
                  <CustomTableRow
                    key={el.Id}
                    clickHandler={(event: React.MouseEvent<HTMLTableRowElement>) =>
                      rowClickHandler(event, `externaljobs/${itemIdsStr}`)
                    }
                  >
                    <CustomTableBodyCheckboxCell
                      isChecked={Boolean(checkedItemsState[itemIdsStr])}
                      onChange={checkboxOnChange}
                      id={itemIdsStr}
                      editable={isAdmin}
                    />
                    <TableCell>{(vacanciesResponse?.PageIndex - 1) * parseInt(count) + index + 1}</TableCell>
                    <TableCell>{el.Name}</TableCell>
                    <TableCell>{el.Company.Name}</TableCell>
                    <TableCell>{el.Category.Name}</TableCell>
                    <TableCell>
                      {el.Location.Country},<br />
                      {el.Location.City}
                    </TableCell>
                    <TableNumberOfCell count={el.Statistics?.ViewsCount} itemName={'Views'} />
                    <TableCell style={{ width: 100 }}>
                      <Badge type={badgeData.type} text={badgeData.text} />
                    </TableCell>
                    <TableCell style={{ width: 100 }}>
                      {createDateTime(el.PublishDate).toFormat(DateTimeFormat.FULL)}
                    </TableCell>
                  </CustomTableRow>
                );
              })}
            </TableBody>
          </TableLayout>
        )}
      </section>
    </MainLayout>
  );
};
