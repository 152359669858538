import { useHistory } from 'react-router';
import { useCallback, useMemo } from 'react';

export function useQuery() {
  const history = useHistory();
  const { search } = history.location;
  const params = useMemo(() => new URLSearchParams(search), [search]);

  const setParam = useCallback(
    (name: string, value: string) => {
      params.set(name, value);

      history.replace(`${history.location.pathname}?${params.toString()}`);
    },
    [params]
  );

  const setParamArr = useCallback(
    (name: string, values: string[]) => {
      params.delete(name);

      values.forEach((value) => params.append(name, value));

      history.replace(`${history.location.pathname}?${params.toString()}`);
    },
    [params]
  );

  const removeParams = useCallback(
    (names: string[]) => {
      const currParamsStr = params.toString();
      names.forEach((el) => params.delete(el));

      if (currParamsStr !== params.toString()) {
        history.replace(`${history.location.pathname}?${params.toString()}`);
      }
    },
    [params]
  );

  const getFilteredParams = useCallback(
    (names: string[]) => {
      let newParams = new URLSearchParams('');
      params.forEach((value, paramName) => {
        if (!names.some((name) => name === paramName)) {
          newParams.append(paramName, value);
        }
      });

      return newParams;
    },
    [params]
  );

  return useMemo(
    () => ({
      currQueryParams: params,
      setParam,
      removeParams,
      setParamArr,
      getFilteredParams,
    }),
    [params]
  );
}
