import { makeStyles } from '@material-ui/core/styles';

export const useMainStyles = makeStyles({
  TableCell: {
    verticalAlign: 'baseline',
  },
  _clickable: {
    cursor: 'pointer',
  },
  StatusTabs: {
    marginTop: 23,
  },
});
