import { makeStyles } from '@material-ui/core/styles';

export const useToggleHeaderStyles = makeStyles((theme) => ({
  ToggleHeader: {
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
    borderRadius: 6,
    cursor: 'pointer',
  },
  Title: {
    color: '#2F3A4E',
    fontWeight: 'normal',
    cursor: 'pointer',
    fontSize: 14,
  },
  ToggleSign: {
    borderRadius: '50%',
    width: 10,
    height: 10,
    padding: 5,
    background: '#FFF8E8',
  },
  _open: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    backgroundColor: '#FFF8E8',
    position: 'relative',
    '&:after, &:before': {
      content: '""',
      position: 'absolute',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '2px',
      transition: 'transform 0.25s ease-out',
    },
    '&:before': {
      left: '45%',
      width: 2,
      height: 9,
      transform: 'translateY(64%)',
    },
    '&:after': {
      top: '45%',
      width: 9,
      height: 2,
      transform: 'translateX(64%)',
    },
  },
  _close: {
    '&:before': {
      transform: 'rotate(90deg)',
      display: 'none',
    },
    '&:after': {
      transform: 'rotate(180deg) translateX(-64%)',
    },
  },
}));
