import ValidatorJs from 'validatorjs';
import { ICompanyDetailsForm } from '../../pages/CompanyEdit/Main/DetailsForm';

ValidatorJs.register(
  'check_cvr_code',
  (value) => {
    if (typeof value !== 'string') {
      return false;
    }

    return /^(\d{8})$|^(\d{6}(-|\s)\d{4})$|^([a-zA-Z]{3}(-|\s)\d{4,6})$/i.test(value);
  },
  'Invalid CVR code'
);

ValidatorJs.register(
  'check_location_available',
  (value) => {
    if (value !== false && value !== true) {
      return false;
    }

    return value;
  },
  'The location is not available'
);

const companyDetailsRules = {
  companyName: 'required',
  cvrCode: 'check_cvr_code',
  location: {
    IsAvailable: 'check_location_available',
  },
};

export function validateEditCompanyDetailsData(companyDetails: ICompanyDetailsForm) {
  const validation = new ValidatorJs<ICompanyDetailsForm>(companyDetails, companyDetailsRules, {
    cvrCode: 'Invalid CVR code',
  });

  validation.check();
  const errors = validation.errors.errors;

  return errors['location.IsAvailable']
    ? {
        ...errors,
        location: {
          IsAvailable: errors['location.IsAvailable'],
        },
      }
    : errors;
}
