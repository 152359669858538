import React, { ReactElement, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { ReactComponent as JobsIcon } from '../../../assets/sidebar/jobsIcon.svg';
import { ReactComponent as CompaniesIcon } from '../../../assets/sidebar/companiesIcon.svg';
import { ReactComponent as CandidatesIcon } from '../../../assets/sidebar/candidatesIcon.svg';
import { ReactComponent as ProblemReportsIcon } from '../../../assets/sidebar/problemReportsIcon.svg';
import { useSideBarStyles } from './SideBarStyles';
import LogoIcon from '../../../assets/logo.svg';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PeopleIcon from '@material-ui/icons/People';
import SystemIcon from '@material-ui/icons/SettingsInputComponent';
import { version } from '../../../../package.json';
import { useAccount } from 'utils/useAccount';

interface ISideBarItem {
  title: string;
  icon?: ReactElement;
  route: string;
}

export const SideBar = () => {
  const classNames = useSideBarStyles();
  const location = useLocation();
  const { isAdmin } = useAccount();

  const sideBarItems: ISideBarItem[] = useMemo(() => {
    const items = [
      {
        title: 'Jobs',
        icon: <JobsIcon />,
        route: '/jobs',
      },
      {
        title: 'External Jobs',
        icon: <JobsIcon />,
        route: '/externaljobs',
      },
      {
        title: 'Companies',
        icon: <CompaniesIcon />,
        route: '/companies',
      },
      {
        title: 'Candidates',
        icon: <CandidatesIcon />,
        route: '/candidates',
      },
      {
        title: 'Problem Reports',
        icon: <ProblemReportsIcon />,
        route: '/reports',
      },
    ];

    if (isAdmin) {
      items.push(
        {
          title: 'City manager',
          icon: <LocationCityIcon color="inherit" style={{ fontSize: 18 }} />,
          route: '/sities',
        },
        {
          title: 'Workee Team',
          icon: <PeopleIcon color="inherit" style={{ fontSize: 18 }} />,
          route: '/team',
        },
        {
          title: 'System',
          icon: <SystemIcon color="inherit" style={{ fontSize: 18 }} />,
          route: '/system',
        }
      );
    }

    return items;
  }, [isAdmin]);
  const versionText = useMemo(() => process.env.REACT_APP_CI_BUILD_NUMBER || version, []);

  return (
    <aside className={classNames.SideBar}>
      <div>
        <img className={classNames.LogoIcon} src={LogoIcon} />

        <List>
          {sideBarItems.map((item) => (
            <ListItem
              classes={{ root: classNames.ListItem }}
              selected={item.route === location.pathname}
              component={NavLink}
              button
              key={item.title}
              to={item.route}
            >
              <ListItemIcon classes={{ root: classNames.ListItemIcon }}>{item.icon}</ListItemIcon>
              <ListItemText classes={{ root: classNames.ListItemText }} primary={item.title} />
            </ListItem>
          ))}
        </List>
      </div>
      <div className={classNames.version}>{versionText}</div>
    </aside>
  );
};
