import React, { useCallback, useEffect, useState } from 'react';
import { FilterItemLayout } from 'layouts/Home/Filters/FilterItemLayout';
import LocationField from 'components/FormComponents/LocationField';
import { useQuery } from 'utils/useQuery';
import { useSelectedFiltersContext } from 'utils/selectedFiltersContext';
import { CandidatesParams, QueryParams } from 'enums';
import { ILocation } from 'services/http/company/interfaces';
import { GeoService } from 'services/http/geo';

const title = 'Location';
const queryParamNames = [CandidatesParams.LATITUDE, CandidatesParams.LONGITUDE, CandidatesParams.IS_CITY];

const isILocation = (location: string | ILocation): location is ILocation => {
  return (location as ILocation).Latitude !== undefined;
};

export const LocationFilter = () => {
  const [locationValue, setLocationValue] = useState<string | ILocation>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { currQueryParams, setParam, removeParams } = useQuery();
  const selectedFiltersContext = useSelectedFiltersContext();

  const removeLocationFilter = useCallback(() => {
    selectedFiltersContext.remove(title);
    removeParams(queryParamNames);
  }, [removeParams, selectedFiltersContext]);

  useEffect(() => {
    const lat = currQueryParams.get(CandidatesParams.LATITUDE);
    const lon = currQueryParams.get(CandidatesParams.LONGITUDE);
    const isCity = currQueryParams.get(CandidatesParams.IS_CITY);

    if (!lat || !lon) {
      return;
    }

    setLoading(true);
    GeoService.getLocationByCoordinates({ Latitude: parseFloat(lat), Longitude: parseFloat(lon) })
      .then((location) => {
        const { City, ...data } = location;

        if (isCity === 'true') {
          (data as ILocation)['City'] = City;
        }

        setLocationValue(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const lat = currQueryParams.get(CandidatesParams.LATITUDE);
    const lon = currQueryParams.get(CandidatesParams.LONGITUDE);

    if (!lat || !lon) {
      setLocationValue('');

      return;
    }

    selectedFiltersContext.set({
      label: title,
      params: queryParamNames,
    });
  }, [currQueryParams, selectedFiltersContext]);

  const applyHandler = useCallback(() => {
    if (!locationValue || !isILocation(locationValue)) {
      removeLocationFilter();

      return;
    }

    const { Latitude, Longitude, City } = locationValue;

    if (!Latitude || !Longitude) {
      removeLocationFilter();

      return;
    }

    selectedFiltersContext.set({
      label: title,
      params: queryParamNames,
    });

    setParam(CandidatesParams.LATITUDE, `${Latitude}`);
    setParam(CandidatesParams.LONGITUDE, `${Longitude}`);
    setParam(CandidatesParams.IS_CITY, `${!!City}`);

    setParam(QueryParams.PAGE, '1');
  }, [locationValue, setParam, removeLocationFilter, removeParams]);

  return (
    <FilterItemLayout title={'Location'} applyHandler={applyHandler}>
      <div>
        <div>
          <LocationField value={locationValue} setValue={setLocationValue} disabled={loading} />
        </div>
      </div>
    </FilterItemLayout>
  );
};
