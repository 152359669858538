import React, { useCallback, useMemo, useState } from 'react';
import { Marker, Polygon } from '@react-google-maps/api';
import { IGeoCity } from 'services/http/geo/interfaces';
import { makeStyles } from '@material-ui/styles';

interface IPolygonWrapperProps {
  city: IGeoCity;
  setHovered: (poligonId?: string) => void;
  hoveredId?: string;
  setEditable: (poligonId?: string) => void;
  editableId?: string;
  addPolygon: (cityId: string, polygon: google.maps.Polygon) => void;
  removePolygon: (cityId: string) => void;
}
const defaultPolygonColor = {
  fillColor: '#78849b',
  strokeColor: '#78849b',
};

const hoverPolygonColor = {
  fillColor: '#FFB002',
  strokeColor: '#FFB002',
};
const useStyles = makeStyles({
  label: {
    background: '#ffffff',
    padding: '2px 5px',
    marginTop: 15,
  },
});

export const PolygonWrapper = ({
  city,
  setHovered,
  hoveredId,
  setEditable,
  editableId,
  addPolygon,
  removePolygon,
}: IPolygonWrapperProps) => {
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const classes = useStyles();

  const markerCoords = useMemo(() => {
    if (!polygon) {
      return;
    }

    const bounds = new google.maps.LatLngBounds();
    polygon
      .getPath()
      .getArray()
      .forEach((latLng) => {
        bounds.extend(latLng);
      });

    return bounds.getCenter();
  }, [polygon]);

  const onLoad = useCallback(
    (pol: google.maps.Polygon) => {
      addPolygon(city.Id, pol);
      setPolygon(pol);
    },
    [setPolygon, addPolygon, city]
  );

  const onUnmount = useCallback(() => {
    removePolygon(city.Id);
    setPolygon(null);
  }, [setPolygon]);

  const onMouseOver = useCallback(() => {
    if (editableId) {
      return;
    }

    setHovered(city.Id);
  }, [editableId]);

  const onMouseOut = useCallback(() => {
    if (editableId) {
      return;
    }

    setHovered();
  }, [editableId]);

  const onRightClickHanler = useCallback(() => {
    if (editableId) {
      return;
    }

    setEditable(city.Id);
  }, [editableId]);

  const options = useMemo(() => {
    const colors = hoveredId === city.Id ? hoverPolygonColor : defaultPolygonColor;

    return {
      ...colors,
      editable: editableId === city.Id,
    };
  }, [hoveredId, editableId]);

  return (
    <>
      <Polygon
        path={city.Polygon.Exterior.map((e) => ({ lat: e.Latitude, lng: e.Longitude }))}
        options={options}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onRightClick={onRightClickHanler}
      />
      {markerCoords && (
        <Marker
          position={markerCoords}
          options={{
            label: {
              text: city.Name,
              className: classes.label,
            },
            icon: '/',
          }}
        />
      )}
    </>
  );
};
