import { HttpService } from '../axios';
import { CancelToken } from 'axios';
import { ICandidate } from './interfaces';
import { IEvent, IGetPaginationResponse } from '../interfaces';

export interface IBirthdayRange {
  birthdayFrom?: string;
  birthdayTo?: string;
}

export class CandidatesService {
  public static getAll(queryParams: string, count: string, cancelToken: CancelToken, birthdayRange?: IBirthdayRange) {
    return HttpService.get<IGetPaginationResponse<ICandidate>>(
      `${HttpService.env.adminAPI}/candidates?${queryParams}&count=${count}`,
      {
        cancelToken,
        params: birthdayRange
          ? {
              birthdayFrom: birthdayRange.birthdayFrom,
              birthdayTo: birthdayRange.birthdayTo,
            }
          : undefined,
      }
    );
  }

  public static deleteCandidates(ids: string[]) {
    return HttpService.delete(`${HttpService.env.adminAPI}/users`, { params: { ids } });
  }

  public static getLogs(id: string, pageNumber: number, count: string) {
    return HttpService.get<IGetPaginationResponse<IEvent>>(`${HttpService.env.adminAPI}/users/${id}/events`, {
      params: {
        Page: pageNumber,
        Count: count,
      },
    });
  }
}
