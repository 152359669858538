import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useMainStyles } from './MainStyles';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { MainHeader } from './Header';
import { Info } from './Info';
import { IGetCompanyDetails } from '../../../services/http/company/interfaces';
import { useHistory } from 'react-router';
import useAxios from 'axios-hooks';
import { HttpService } from '../../../services/http/axios';
import { CompanyVacancies } from './CompanyVacancies';
import { deleteByIds } from '../../../utils/deleteByIds';
import { CompaniesService } from '../../../services/http/company';
import { useNotificationContext } from '../../../utils/notificationContext';
import { useConfirm } from 'material-ui-confirm';
import { DeleteButton, EditButton } from '../../../components/DeleteEditButtons';
import { DetailsDescription } from '../../../components/DetailsDescription';
import { Logs } from '../../../components/Logs';
import { NotFound } from '../../../components/NotFound';
import { useAccount } from 'utils/useAccount';

export const CompanyDetailsMain = () => {
  const classNames = useMainStyles();
  const { companyId: id } = useParams<{ companyId: string }>();
  const { setNotification } = useNotificationContext();
  const confirm = useConfirm();
  const history = useHistory();
  const { isAdmin } = useAccount();

  const [{ data: companyDetails, loading: detailsLoading }] = useAxios<IGetCompanyDetails>(
    `${HttpService.env.adminAPI}/companies/${id}`,
    { useCache: false }
  );

  const deleteHandler = useCallback(
    () =>
      deleteByIds(
        [id],
        CompaniesService.deleteCompany,
        setNotification,
        `${companyDetails?.Name} company deleted`,
        confirm,
        'You are deleting a company.'
      )
        .then(history.goBack)
        .catch(() => {}),
    [id, CompaniesService.deleteCompany, companyDetails?.Name, setNotification, confirm]
  );

  const editHandler = useCallback(() => {
    history.push(`${companyDetails?.Id}/edit`);
  }, [companyDetails]);

  if (!detailsLoading && !companyDetails) {
    return (
      <MainLayout>
        <NotFound item={'Company'} />
      </MainLayout>
    );
  }

  return (
    <MainLayout loading={detailsLoading} hasBackPath={true}>
      <>
        {companyDetails && (
          <>
            <MainHeader
              name={companyDetails.Name}
              purchases={companyDetails.Purchases}
              location={companyDetails.Location}
              logo={companyDetails.Logo}
            />
            <Info
              industry={companyDetails.Industry}
              cvr={companyDetails.Cvr}
              vacancies={companyDetails.JobCount}
              employeeCount={companyDetails.EmployeeCount}
              withWorkeeSince={companyDetails.CreatedOn}
              lastSeen={companyDetails.LastUsedOn}
            />
            <DetailsDescription title="Description">{companyDetails.Description}</DetailsDescription>
            <div className={classNames.FlexWrapper}>
              <div>
                <Logs fetchFunc={CompaniesService.getLogs} id={id} name="Company Logs" />
              </div>
              <div>
                {isAdmin && (
                  <Grid container spacing={2}>
                    <Grid item>
                      <EditButton onClick={editHandler} />
                    </Grid>
                    <Grid item>
                      <DeleteButton onClick={deleteHandler} />
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </>
        )}
        <CompanyVacancies />
      </>
    </MainLayout>
  );
};
