import React from 'react';
import { Typography } from '@material-ui/core';
import { useFiltersStyles } from './FilterLayoutStyles';
import { FiltersHideButton } from './FiltersHideButton';
import { SelectedFilters } from './SelectedFilters';

interface IFilterProps {
  children: JSX.Element | JSX.Element[];
  isShow: boolean | null;
  hideFilters: () => void;
}

export const FiltersLayout = ({ children, isShow, hideFilters }: IFilterProps) => {
  const classNames = useFiltersStyles();

  const animationClass = isShow === null ? '' : `${isShow ? classNames._filtersShow : classNames._filtersHide}`;

  return (
    <>
      <section className={`${classNames.FiltersLayout} ${animationClass}`}>
        {isShow && <FiltersHideButton hide={hideFilters} />}

        <Typography classes={{ root: classNames.Title }} variant="h4">
          Filters
        </Typography>
        <SelectedFilters />

        {children}
      </section>
    </>
  );
};
