import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

export const StyledCancelButton = withStyles((theme) => ({
  root: {
    width: 105,
    height: 48,
    background: '#FFF',
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 24,
    color: theme.palette.text.secondary,
  },
}))(Button);
