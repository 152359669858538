import React from 'react';
import { useTabLabelWithCountStyles } from './TabLabelWithCountStyles';

interface ITableWithCountProps {
  title: string;
  count: number | null | undefined;
  isSelected: boolean;
}

export const TabLabelWithCount = ({ title, count, isSelected }: ITableWithCountProps) => {
  const classNames = useTabLabelWithCountStyles();

  return (
    <div>
      {title}
      {!!count && <span className={`${classNames.Count} ${isSelected ? classNames.Count_selected : ''}`}>{count}</span>}
    </div>
  );
};
