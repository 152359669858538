import { makeStyles } from '@material-ui/core';

export const usePageTitleWithCountStyles = makeStyles((theme) => ({
  Count: {
    fontSize: 24,
    fontWeight: 400,
    fontFamily: 'GT Walsheim Pro Regular',
    color: theme.palette.text.secondary,
  },
}));
