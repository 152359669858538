import React from 'react';
import { Grid, TableCell, Typography } from '@material-ui/core';
import { Avatar } from 'components/Avatar';

interface ICustomTableWithAvatarProps {
  className?: string;
  text: string;
  photo?: string;
}

export const TableWithAvatarCell = ({ className, text, photo }: ICustomTableWithAvatarProps) => {
  return (
    <TableCell className={className}>
      <Grid container spacing={1} wrap="nowrap" alignItems="center">
        <Grid item>
          <Avatar alt={text} logo={photo} size="_sm" />
        </Grid>
        <Grid item style={{ overflow: 'hidden' }}>
          <Typography noWrap>{text}</Typography>
        </Grid>
      </Grid>
    </TableCell>
  );
};
