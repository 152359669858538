import { makeStyles } from '@material-ui/core/styles';

export const useTabLabelWithCountStyles = makeStyles((theme) => ({
  Count: {
    marginLeft: 8,
    padding: '3px 7px',
    borderRadius: 12,
    backgroundColor: theme.palette.secondary.dark,
    transition: '0.5',
  },
  Count_selected: {
    color: '#FFF',
    backgroundColor: theme.palette.primary.main,
  },
}));
