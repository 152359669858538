import React from 'react';
import { TableRow } from '@material-ui/core';
import { useCustomTableRowStyles } from './TableRowStyles';

interface ICustomTableBodyProps {
  children: JSX.Element | JSX.Element[];
  clickHandler?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  lastElement?: (node: any) => void;
}

export const CustomTableRow = ({ children, clickHandler, lastElement }: ICustomTableBodyProps) => {
  const classNames = useCustomTableRowStyles();

  return (
    <TableRow
      className={`${classNames.TableRow} ${clickHandler ? classNames._clickable : ''}`}
      onClick={clickHandler ? (event) => clickHandler(event) : undefined}
      ref={lastElement ? lastElement : null}
    >
      {children}
    </TableRow>
  );
};
