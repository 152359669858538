import React from 'react';
import { HomeLayout } from '../../layouts/Home';
import { JobEditMain } from './Main';

export const JobEdit = () => {
  return (
    <HomeLayout>
      <JobEditMain />
    </HomeLayout>
  );
};
