import React, { useEffect, useState } from 'react';
import { Loading } from '../../Loading';

interface IPreviewProps {
  file: File | null;
  className: string;
}

export const Preview = ({ file, className }: IPreviewProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string>('');

  useEffect(() => {
    if (!file) {
      return;
    }

    setLoading(true);
    const reader = new FileReader();

    reader.onloadend = () => {
      setLoading(false);

      if (!reader.result || typeof reader.result !== 'string') {
        return;
      }

      setPhoto(reader.result);
    };

    reader.readAsDataURL(file);
  }, [file]);

  if (!file) {
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  return <img className={className} src={photo} alt={file.name} height={80} width={120} />;
};
