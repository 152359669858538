import { HttpService } from '../axios';
import { ReportStatus } from '../../../enums';

export class ReportsService {
  public static deleteReports(ids: string[]) {
    return HttpService.delete(`${HttpService.env.reportsAPI}/reports`, { params: { ids } });
  }

  public static resolveReport(id: string, reportStatus: ReportStatus) {
    return HttpService.patch(`${HttpService.env.reportsAPI}/reports/${id}`, { Status: reportStatus });
  }
}
