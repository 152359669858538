import { makeStyles } from '@material-ui/core/styles';

export const useDetailsDescriptionStyles = makeStyles((theme) => ({
  Description: {
    padding: '20px 0',
    borderTop: `1px solid ${theme.palette.secondary.dark}`,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
  DescriptionTitle: {
    marginBottom: 6,
  },
}));
