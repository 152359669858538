import React, { useCallback } from 'react';
import { Formik, Form, FormikHelpers, Field } from 'formik';
import { ILoginReduxProps } from '../../containers/Login';
import Input from '@material-ui/core/Input';
import FormGroup from '@material-ui/core/FormGroup';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router';
import Container from '@material-ui/core/Container';
import { useLoginStyles } from './LoginStyles';
import StepLabel from '@material-ui/core/StepLabel';
import LogoIcon from '../../assets/logo.svg';
import { validateUserLoginData } from '../../utils/validations/validateLogin';

interface ILoginForm {
  username: string;
  password: string;
}

const initialValues: ILoginForm = {
  username: '',
  password: '',
};

export const LoginPage = ({ loginUser }: ILoginReduxProps) => {
  const history = useHistory();
  const submitHandler = useCallback(
    async (values: ILoginForm, formikHelpers: FormikHelpers<ILoginForm>) => {
      await loginUser(values);
      formikHelpers.setSubmitting(false);
      history.push('/companies');
    },
    [history, loginUser]
  );
  const handleValidation = useCallback((values: ILoginForm) => validateUserLoginData(values), []);

  return (
    <Formik initialValues={initialValues} validate={handleValidation} onSubmit={submitHandler}>
      {InnerForm}
    </Formik>
  );
};

const InnerForm = () => {
  const loginClasses = useLoginStyles();

  return (
    <Container component={Form} maxWidth="xl" className={loginClasses.Login}>
      <img className={loginClasses.LogoIcon} src={LogoIcon} />
      <FormGroup className={loginClasses.FormGroup}>
        <StepLabel>Username</StepLabel>
        <Field as={Input} name="username" />
        <StepLabel>Password</StepLabel>
        <Field as={Input} name="password" type="password" />
        <Button className={loginClasses.LoginButton} type="submit">
          Login
        </Button>
      </FormGroup>
    </Container>
  );
};
