import React from 'react';
import { TableCell } from '@material-ui/core';
import { useTableUserTypeCellStyles } from './TableUserTypeStyles';
import candidateIcon from '../../../../assets/userType_candidate.svg';
import companyIcon from '../../../../assets/userType_company.svg';
import { AccountRole } from 'enums';

const getUserTypeData = (
  type?: AccountRole
): {
  text: string;
  icon: string;
} => {
  switch (type) {
    case AccountRole.CANDIDATE:
      return {
        text: 'Candidate',
        icon: candidateIcon,
      };
    case AccountRole.COMPANY:
      return {
        text: 'Company',
        icon: companyIcon,
      };
    case AccountRole.ADMIN:
      return {
        text: 'Admin',
        icon: companyIcon,
      };
    case AccountRole.OPERATOR:
      return {
        text: 'Operator',
        icon: companyIcon,
      };
    default:
      return {
        text: 'No Data',
        icon: '',
      };
  }
};

interface ICustomTableUserTypeProps {
  className?: string;
  type?: AccountRole;
}

export const TableUserTypeCell = ({ className, type }: ICustomTableUserTypeProps) => {
  const typeData = getUserTypeData(type);
  const classNames = useTableUserTypeCellStyles(typeData.icon)();

  return (
    <TableCell className={className}>
      <span className={classNames.Type}> {typeData.text} </span>
    </TableCell>
  );
};
