import { withStyles } from '@material-ui/styles';
import { InputBase } from '@material-ui/core';

export const StyledInput = withStyles((theme) => ({
  root: {
    width: '100%',
    height: 48,
    borderRadius: 6,
    border: `1px solid ${theme.palette.secondary.dark}`,
    boxSizing: 'border-box',
    padding: '16px 0 14px 16px',
  },
  disabled: {
    color: '#2F3A4E',
  },
}))(InputBase);
