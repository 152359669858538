import { IAccount } from 'services/http/auth/interfaces';
import { IError } from '../../../intefaces';

export interface IUserState {
  isAuthorized: boolean;
  error?: IError;
  user: IAccount | null;
}

export enum UserActionTypes {
  USER_LOADED = 'USER_LOADED',
  AUTH_ERROR = 'AUTH_ERROR',
  LOGGED_OUT = 'LOGGED_OUT',
  SET_USER = 'SET_USER',
}
