import React from 'react';
import { ILocation, IPurchase } from '../../../../services/http/company/interfaces';
import { Typography } from '@material-ui/core';
import { Badge, BadgeTypes } from '../../../../components/Badge';
import { Avatar } from '../../../../components/Avatar';
import { useCommonMainHeaderStyles } from '../../../../styles/MainHeaderStyles';
import { LocationLink } from '../../../../components/LocationLink';

interface IMainHeaderProps {
  name: string;
  purchases: IPurchase[];
  location: ILocation;
  logo?: string;
}

export const MainHeader = ({ name, purchases, location, logo }: IMainHeaderProps) => {
  const commonHeaderClassNames = useCommonMainHeaderStyles();

  return (
    <header className={commonHeaderClassNames.Header}>
      <section className={commonHeaderClassNames.Profile}>
        <Avatar logo={logo} alt={name} />
        <section className={commonHeaderClassNames.ProfileInfoSection}>
          <Typography classes={{ root: commonHeaderClassNames.Title }} variant="h1">
            {name}
          </Typography>
          <LocationLink location={location} />
        </section>
      </section>
      {purchases.length === 0 ? (
        <Badge text="No subscriptions" type={BadgeTypes.CLOSED} />
      ) : (
        <section>
          {purchases.map((el) => (
            <Badge
              key={el.Id}
              text={el.UiDescription?.en?.Title || ''}
              type={!el.IsSubscriptionStopped ? BadgeTypes.PREMIUM : BadgeTypes.UNRESOLVED}
            />
          ))}
        </section>
      )}
    </header>
  );
};
