import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  Box,
  CircularProgress,
  debounce,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { HttpService } from 'services/http/axios';
import { IGetPaginationResponse } from 'services/http/interfaces';
import { IAccount } from 'services/http/auth/interfaces';
import { Avatar } from 'components/Avatar';
import { StyledCancelButton } from 'components/ui/CancelButton';
import { StyledSaveButton } from 'components/ui/SaveButton';
import { useNotificationContext } from 'utils/notificationContext';
import { AccountRole } from 'enums';
import { AuthService } from 'services/http/auth';

interface IAddTeamMemberProps {
  onCancel: () => void;
  onAdd: () => void;
}

export const AddTeamMember = ({ onCancel, onAdd }: IAddTeamMemberProps) => {
  const [loadingAccounts, setLoadingAccounts] = useState<boolean>(false);
  const [options, setOptions] = useState<IAccount[]>([]);
  const [accountSelected, setAccountSelected] = useState<IAccount | null>(null);
  const [roleValue, setRoleValue] = useState<string>('8');
  const { setNotification } = useNotificationContext();

  const fetchAccounts = useCallback(async (email: string) => {
    if (!email) {
      return;
    }

    setLoadingAccounts(true);

    try {
      const { Page: accounts } = await HttpService.get<IGetPaginationResponse<IAccount>>(
        `${HttpService.env.adminAPI}/users`,
        {
          params: {
            roles: 0,
            email,
            count: 5,
          },
        }
      );
      setOptions(accounts);
    } catch (error) {
      setOptions([]);
    }

    setLoadingAccounts(false);
  }, []);

  const handleInputChange = useCallback(
    debounce((event: ChangeEvent<HTMLInputElement>) => {
      fetchAccounts(event.target.value);
    }, 500),
    []
  );

  const autoCompleteOnChange = useCallback((event, value: IAccount | null) => {
    setAccountSelected(value);
  }, []);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoleValue((event.target as HTMLInputElement).value);
  };

  const addHandler = useCallback(() => {
    if (!accountSelected) {
      return;
    }

    AuthService.patchUser(accountSelected.Id, {
      Role: +roleValue,
    })
      .then(() => {
        setNotification({
          text: `${accountSelected.FullName} was added like ${getRoleLabel(+roleValue)}`,
          severity: 'success',
        });
      })
      .catch(() => {})
      .finally(() => {
        onAdd();
      });
  }, [accountSelected, roleValue]);

  return (
    <Drawer anchor="right" open={true} ModalProps={{ disableBackdropClick: false }}>
      <Box m={3}>
        <Typography variant="h1" style={{ textTransform: 'inherit' }}>
          Add a team member
        </Typography>
        <Box mt={3}>
          <Grid container spacing={3} style={{ width: 500 }}>
            <Grid item xs={12}>
              <Autocomplete
                getOptionSelected={(option) => !!accountSelected && option.Id === accountSelected.Id}
                onChange={autoCompleteOnChange}
                getOptionLabel={(option) => `${option.FullName} (${option.Email})`}
                options={options}
                loading={loadingAccounts}
                renderOption={(option) => {
                  return (
                    <Box style={{ overflow: 'hidden' }}>
                      <Grid container spacing={1} alignItems="center" wrap="nowrap" style={{ overflow: 'hidden' }}>
                        <Grid item>
                          <Avatar alt={option.FullName} logo={option.Thumbnail} size="_sm" />
                        </Grid>
                        <Grid item xs style={{ overflow: 'hidden' }}>
                          <div>
                            <Typography noWrap>{`${option.FullName}`}</Typography>
                          </div>
                          <div>
                            <Typography noWrap>{option.Email}</Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="User"
                    variant="outlined"
                    onChange={handleInputChange}
                    placeholder={'find user by email'}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingAccounts && <CircularProgress color="inherit" size={20} />}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Company role</FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="8"
                  value={roleValue}
                  onChange={handleRadioChange}
                >
                  <FormControlLabel
                    value={`${AccountRole.OPERATOR}`}
                    control={<Radio color="primary" />}
                    label={getRoleLabel(AccountRole.OPERATOR)}
                  />
                  <FormControlLabel
                    value={`${AccountRole.ADMIN}`}
                    control={<Radio color="primary" />}
                    label={getRoleLabel(AccountRole.ADMIN)}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} justify="flex-end">
                <Grid item>
                  <StyledCancelButton onClick={() => onCancel()}>Cancel</StyledCancelButton>
                </Grid>
                <Grid item>
                  <StyledSaveButton onClick={addHandler} disabled={!accountSelected}>
                    Add
                  </StyledSaveButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
};

export const getRoleLabel = (v: number) => {
  switch (v) {
    case AccountRole.OPERATOR:
      return 'Operator';
    case AccountRole.ADMIN:
      return 'Admin';
    default:
      return '';
  }
};
