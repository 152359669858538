import { IJobHistoryItem } from '../services/http/candidates/interfaces';
import { DateTime, Interval } from 'luxon';
import { createDateTime } from './createDateTime';

const getMultiple = (itemsLength: number) => {
  return itemsLength > 1 || itemsLength === 0 ? 's' : '';
};

export const getMonthExperience = (jobHistory: IJobHistoryItem[]): number => {
  let jobs = jobHistory;

  if (!jobs.length) {
    return 0;
  }

  let months = 1;
  const min = DateTime.min(...jobs.map((j) => createDateTime(j.From).startOf('month')));
  const max = DateTime.max(...jobs.map((j) => createDateTime(j.To).endOf('month')));
  let current = min;

  while (current <= max) {
    current = current.plus({ months: 1 });

    if (
      jobs.some((j) =>
        Interval.fromDateTimes(createDateTime(j.From).startOf('month'), createDateTime(j.To).endOf('month')).contains(
          current
        )
      )
    ) {
      months += 1;
    }
  }

  return months;
};

export const getWorkingExperienceByMonths = (monthsCount: number) => {
  if (!monthsCount) {
    return 'No experience';
  }

  const years = Math.floor(monthsCount / 12);

  if (years > 0) {
    const months = monthsCount % 12;

    if (months > 0) {
      return `${years} year${getMultiple(years)}, ${months} month${getMultiple(months)}`;
    }

    return `${years} year${getMultiple(years)}`;
  }

  return `${monthsCount} month${getMultiple(monthsCount)}`;
};
