import React, { useCallback } from 'react';
import { QueryParams } from 'enums';
import { useQuery } from 'utils/useQuery';
import { ReactComponent as TrashIcon } from '../../../assets/arrow-right.svg';
import { Button, withStyles } from '@material-ui/core';

interface INextButtonProps {
  numberOfPages: number;
}

export const NextButton = ({ numberOfPages }: INextButtonProps) => {
  const { currQueryParams, setParam } = useQuery();
  const currentPage = parseInt(currQueryParams.get(QueryParams.PAGE) || '1');

  const goToNextPage = useCallback(() => {
    setParam(QueryParams.PAGE, `${currentPage < numberOfPages ? currentPage + 1 : currentPage}`);
  }, [currentPage, currQueryParams, numberOfPages]);

  return (
    <FooterButton
      color="primary"
      variant="contained"
      onClick={goToNextPage}
      endIcon={<TrashIcon />}
      disabled={currentPage === numberOfPages}
    >
      Next
    </FooterButton>
  );
};

export const PrevButton = () => {
  const { currQueryParams, setParam } = useQuery();
  const currentPage = parseInt(currQueryParams.get(QueryParams.PAGE) || '1');

  const goToPrevPage = useCallback(() => {
    setParam(QueryParams.PAGE, `${currentPage > 1 ? currentPage - 1 : currentPage}`);
  }, [currentPage, currQueryParams]);

  return (
    <FooterButton
      color="primary"
      variant="contained"
      onClick={goToPrevPage}
      startIcon={<TrashIcon />}
      disabled={currentPage === 1}
    >
      Prev
    </FooterButton>
  );
};

const FooterButton = withStyles({
  root: {
    paddingTop: 4,
    paddingBottom: 4,
    '&.$disabled': {
      background: 'red',
    },
  },
  startIcon: {
    transform: 'rotate(180deg)',
  },
  disabled: {},
  contained: {
    '&$disabled': {
      background: '#F2F5F9',
      borderColor: '#F2F5F9',
      color: '#78849B',
      '& path': {
        fill: '#78849B',
      },
    },
  },
})(Button);
