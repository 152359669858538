import { makeStyles } from '@material-ui/core/styles';

export const useTableLayoutStyles = makeStyles({
  TableContainer: {
    boxShadow: '0px 20px 38px #F2F5F9',
    padding: 1,
  },
  NoItemsFound: {
    textAlign: 'center',
    padding: 10,
  },
  TableLayout: {
    borderSpacing: '0 8px',
    borderCollapse: 'separate',
    margin: 0,
  },
  LoadingContainer: {
    position: 'relative',
    borderRadius: 5,
  },
  Loading: {
    position: 'absolute',
    zIndex: 1,
  },
});
