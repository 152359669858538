import { makeStyles } from '@material-ui/core/styles';

export const useMainLayoutStyles = makeStyles({
  Main: {
    flexGrow: 1,
    position: 'relative',
    minWidth: '15%',
    padding: '10px 52px',
  },
});
