import React from 'react';
import { HomeLayout } from '../../layouts/Home';
import { useToggle } from '../../utils/useToggle';
import { JobsMain } from './Main';
import { Filters } from './Filters';

export const Jobs = () => {
  const { show, hide, isToggled } = useToggle(false);

  return (
    <HomeLayout filters={<Filters isShow={isToggled} hideFilters={hide} />} isFiltersShown={isToggled}>
      <JobsMain showFilters={show} isFiltersShow={isToggled} />
    </HomeLayout>
  );
};
