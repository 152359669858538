import React from 'react';
import { IJobHistoryItem } from '../../../../services/http/candidates/interfaces';
import { createDateTime, DateTimeFormat } from '../../../../utils/createDateTime';
import { useJobHistoriesStyles, useJobStyles } from './JobHistoriesStyles';
import { Typography } from '@material-ui/core';
import { getMonthExperience, getWorkingExperienceByMonths } from '../../../../utils/getMonthExperience';

interface IJobProps {
  jobHistory: IJobHistoryItem;
}

const Job = ({ jobHistory }: IJobProps) => {
  const classNames = useJobStyles();
  const workingExperienceByMonths = getMonthExperience([jobHistory]);

  return (
    <div className={classNames.Job}>
      <Typography className={classNames.SpaceBetween} variant="subtitle2">
        {`${createDateTime(jobHistory.From).toFormat(DateTimeFormat.SHORT)} - ${createDateTime(jobHistory.To).toFormat(
          DateTimeFormat.SHORT
        )}`}
        &nbsp;&#183;&nbsp; {getWorkingExperienceByMonths(workingExperienceByMonths)}
      </Typography>
      <Typography className={classNames.SpaceBetween} variant="h3">
        {jobHistory.Title}
      </Typography>

      <Typography className={classNames.SpaceBetween} variant="subtitle1">
        {jobHistory.CompanyName}
      </Typography>
    </div>
  );
};

interface IPrevJobsProps {
  jobHistory: IJobHistoryItem[];
}

export const PrevJobs = ({ jobHistory }: IPrevJobsProps) => {
  const classNames = useJobHistoriesStyles();

  return (
    <section className={classNames.JobHistories}>
      <Typography variant="h5">Previous jobs</Typography>
      <div className={classNames.JobsSection}>
        {[...jobHistory].map((el, index, arr) => (
          <div key={index + el.CompanyName}>
            <Job jobHistory={el} />
            {index < arr.length && <div className={classNames.Separator} />}
          </div>
        ))}
      </div>
    </section>
  );
};
