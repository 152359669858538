import { HttpService } from '../axios';
import { IGetJobsItem } from './interfaces';

export class JobService {
  public static getById(id: string) {
    return HttpService.get<IGetJobsItem>(`${HttpService.env.adminAPI}/jobs/${id}`);
  }

  public static saveExternalJob(id: string, payload: any) {
    if ('' + id === '0') {
      return this.createExternalJob(payload);
    }

    return this.updateExternalJob(id, payload);
  }

  static createExternalJob(payload: any) {
    return HttpService.post<void>(`${HttpService.env.adminAPI}/jobs/external`, payload);
  }

  static updateExternalJob(id: string, payload: any) {
    return HttpService.patch<void>(`${HttpService.env.adminAPI}/jobs/${id}`, payload);
  }

  public static deleteExternalJob(id: string) {
    return HttpService.delete(`${HttpService.env.adminAPI}/jobs/${id}`);
  }
}
