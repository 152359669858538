import { Dispatch } from 'redux';
import { UserActionTypes } from '../../reducers/user/intefaces';
import { IError } from '../../../intefaces';
import { IAuthErrorAction, ILoggedOutAction, IUserAuthData, IUserLoadedAction, ISetUserAction } from './interfaces';
import { AuthService } from '../../../services/http/auth';
import { StorageService } from '../../../services/storage';
import { IAccount } from 'services/http/auth/interfaces';

export const loginUser = (userData: IUserAuthData) => async (dispatch: Dispatch<any>): Promise<void> => {
  try {
    const { refresh_token, access_token } = await AuthService.login(userData);

    StorageService.setAccessToken(access_token);
    StorageService.setRefreshToken(refresh_token);
    dispatch(finishUserLoading());
  } catch (err) {
    if (!err.response.data) {
      dispatch(authError(err));
    }

    dispatch(authError(err.response.data));
    throw err;
  }
};

export const logOut = () => async (dispatch: Dispatch): Promise<void> => {
  StorageService.removeAccessToken();
  StorageService.removeRefreshToken();
  dispatch(loggedOut());
};

export const finishUserLoading = (): IUserLoadedAction => {
  return {
    type: UserActionTypes.USER_LOADED,
  };
};

export const setUser = (user: IAccount): ISetUserAction => {
  return {
    type: UserActionTypes.SET_USER,
    payload: user,
  };
};

export const authError = (err: IError): IAuthErrorAction => {
  return {
    type: UserActionTypes.AUTH_ERROR,
    payload: err,
  };
};

export const loggedOut = (): ILoggedOutAction => {
  return {
    type: UserActionTypes.LOGGED_OUT,
  };
};
