import React, { useEffect, useState } from 'react';
import { HomeLayout } from '../../layouts/Home';
import { ReportsMain } from './Main';
import { useQuery } from 'utils/useQuery';
import { QueryParams } from 'enums';

export const Reports = () => {
  const [initialized, setInitialized] = useState(false);
  const { currQueryParams, setParam } = useQuery();

  useEffect(() => {
    !currQueryParams.get(QueryParams.SORT_BY) &&
      !currQueryParams.get(QueryParams.SORT_BY_DESC) &&
      setParam(QueryParams.SORT_BY_DESC, 'CreatedDateTime');
    setInitialized(true);
  }, []);

  return <HomeLayout>{initialized ? <ReportsMain /> : <></>}</HomeLayout>;
};
