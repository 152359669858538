import { makeStyles } from '@material-ui/core/styles';

export const useLogsStyles = makeStyles({
  Container: {
    position: 'relative',
  },
  List_open: {
    minWidth: 550,
    top: 39,
    position: 'absolute',
    zIndex: 20,
  },
});

export const useLogsListStyles = makeStyles((theme) => ({
  List: {
    background: '#FFFFFF',
    boxShadow: '0px 4px 12px rgba(120, 132, 155, 0.1)',
    borderRadius: 10,
    maxHeight: 370,
    marginBottom: 25,
    '&::-webkit-scrollbar': {
      width: 6,
      height: 6,
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: 20,
    },
    overflow: 'auto',
  },
}));

export const useLogItemStyles = makeStyles((theme) => ({
  LogItem: {
    padding: 4,
  },
  LogItemText: {
    fontSize: 14,
    maxWidth: 220,
    wordBreak: 'keep-all',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  Time: {
    width: 125,
    color: theme.palette.text.secondary,
  },
  ArgsWrapper: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  Dot: {
    width: 7,
    padding: 2,
    margin: 0,
  },
}));
