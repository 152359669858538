import { makeStyles } from '@material-ui/core';
import locationImg from '../../assets/location.svg';

export const useLocationLinkStyles = makeStyles((theme) => ({
  Location: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
    color: theme.palette.text.secondary,
    '&::before': {
      width: 12,
      height: 15,
      alignSelf: 'center',
      content: `url(${locationImg})`,
      marginRight: 8,
    },
  },
  Link: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    fontFamily: 'GT Walsheim Pro Regular',
    fontSize: 14,
    marginLeft: 14,
    '&:hover': {
      textDecoration: 'none',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 5,
      right: -8,
      display: 'block',
      width: 4,
      height: 4,
      borderTop: `1px solid ${theme.palette.primary.main}`,
      borderLeft: `1px solid ${theme.palette.primary.main}`,
      transform: 'rotate(135deg)',
    },
  },
}));
