import React, { useCallback, useMemo } from 'react';
import { TableBody, TableCell } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { useTableStyles } from '../../../styles';
import { TableLayout } from '../../../layouts/Table';
import { CustomTableRow } from '../../../components/TableComponents/TableRow';
import { CustomTableBodyCheckboxCell } from '../../../components/TableComponents/TableCheckboxCell';
import { createDateTime, DateTimeFormat } from '../../../utils/createDateTime';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { useQuery } from '../../../utils/useQuery';
import { ErrorMessage, QueryParams, ReportsParams, ReportStatus } from '../../../enums';
import { CustomTableHead, ITableHeadCellData } from '../../../components/TableComponents/TableHead';
import { useCheckboxState } from '../../../utils/useCheckboxState';
import { TableMessageCell } from '../../../components/TableComponents/TableRow/TableMessageCell';
import { Badge, BadgeTypes } from '../../../components/Badge';
import { TableUserTypeCell } from '../../../components/TableComponents/TableRow/TableUserTypeCell';
import { useMainStyles } from './MainStyles';
import useAxios from 'axios-hooks';
import { IGetPaginationResponse } from '../../../services/http/interfaces';
import { HttpService } from '../../../services/http/axios';
import { IReport } from '../../../services/http/reports/interfaces';
import { useNotificationContext } from '../../../utils/notificationContext';
import { ReportsService } from '../../../services/http/reports';
import { PageTitleWithCount } from '../../../components/PageTitleWithCount';
import { StyledTab, StyledTabs } from '../../../components/ui/StyledTabs';
import { TabLabelWithCount } from '../../../components/TabLabelWithCount';
import { typedReduce } from '../../../utils/typedReduce';
import { GoToProfileLink } from '../../../components/GoToProfileLink';
import Button from '@material-ui/core/Button';
import { useAccount } from 'utils/useAccount';

const count = '9';

enum SortNames {
  REPORTER = 'Author.FullName',
  USER_TYPE = 'Author.Role',
  REPORT_ON = 'PestFullName',
  STATUS = 'IsRead',
  DATE = 'CreatedDateTime',
}

interface IReportsCountItem {
  Type: 0 | 1 | 2 | 3 | 4;
  Count: number;
}

export const reportTypeMapper = {
  1: 'Spam',
  2: 'Fraud',
  3: 'Irrelevant content',
  4: 'Other',
};

function isNumberKeyOfReportTypeMapper(key: number): key is keyof typeof reportTypeMapper {
  return key in reportTypeMapper;
}

const getBadgeData = (reportStatus: ReportStatus) => {
  if (reportStatus === ReportStatus.NEW) {
    return {
      type: BadgeTypes.UNRESOLVED,
      text: 'UNRESOLVED',
    };
  }

  if (reportStatus === ReportStatus.READ) {
    return {
      type: BadgeTypes.CLOSED,
      text: 'READ',
    };
  }

  if (reportStatus === ReportStatus.RESOLVED) {
    return {
      type: BadgeTypes.ACTIVE,
      text: 'RESOLVED',
    };
  }
};

const getProfileId = (obj: IReport['Author'] | IReport['Pest']) => {
  if (!obj) {
    return null;
  }

  if (obj.Company) {
    return obj.Company.Id;
  }

  return obj.Id;
};

const gerReportsOfTypeCount = (reportsCount: IReportsCountItem[] | undefined, type: 0 | 1 | 2 | 3 | 4) => {
  if (!reportsCount) {
    return null;
  }

  return reportsCount.find((el) => el.Type === type)?.Count;
};

const ReportModalParams = [ReportsParams.REPORT_ID];

export const ReportsMain = () => {
  const { currQueryParams, setParam, getFilteredParams, removeParams } = useQuery();
  const confirm = useConfirm();
  const classNames = useMainStyles();
  const reportParams = useMemo(() => getFilteredParams(ReportModalParams).toString(), [getFilteredParams]);
  const { setNotification } = useNotificationContext();
  const [{ data: reportsResponse, loading }, fetchReports] = useAxios<IGetPaginationResponse<IReport>>(
    {
      url: `${HttpService.env.reportsAPI}/reports?${reportParams}`,
      params: {
        count,
      },
    },
    { useCache: false }
  );

  const { isAdmin } = useAccount();
  const [{ data: reportsCounts = [], loading: reportsCountLoading }, fetchReportsCounts] = useAxios<
    IReportsCountItem[]
  >(`${HttpService.env.reportsAPI}/reports/count`, { useCache: false });

  const {
    checkedItemsState,
    resetCheckboxes,
    selectPage,
    unselectPage,
    checkboxOnChange,
    getCheckedItemsIds,
  } = useCheckboxState(reportsResponse?.Page || []);

  const deleteHandler = useCallback(async () => {
    try {
      await confirm({ description: 'This action is permanent!' });
      const checkedItemsIds = getCheckedItemsIds();
      await ReportsService.deleteReports(checkedItemsIds);
      resetCheckboxes();
      fetchReportsCounts();
      setNotification({ text: `${checkedItemsIds.length} reports successfully deleted`, severity: 'success' });
      fetchReports();
    } catch (error: any) {
      const status = error?.response?.status;

      if (error?.response === undefined) {
        setNotification({ text: ErrorMessage.NO_RESPONSE, severity: 'error' });
      } else if (status >= 400 && status < 500) {
        setNotification({
          text: ErrorMessage.UNABLE_TO_PROCESS_REQUEST,
          severity: 'error',
        });
      } else {
        setNotification({ text: ErrorMessage.SERVER_ERROR, severity: 'error' });
      }
    }
  }, [confirm, getCheckedItemsIds, setNotification]);

  const tableHeaders: ITableHeadCellData[] = [
    { text: '#' },
    {
      text: 'REPORTER',
      sortName: SortNames.REPORTER,
    },
    {
      text: 'USER TYPE',
      sortName: SortNames.USER_TYPE,
    },
    { text: 'REPORT ON', sortName: SortNames.REPORT_ON },
    {
      text: 'MESSAGE',
    },
    { text: 'STATUS', sortName: SortNames.STATUS },
    {
      text: 'DATE',
      sortName: SortNames.DATE,
    },
  ];

  const reportTypeOnChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      if (newValue === '0') {
        removeParams([ReportsParams.REPORT_TYPE]);
      } else {
        setParam(ReportsParams.REPORT_TYPE, newValue);
      }

      setParam(QueryParams.PAGE, '1');
      resetCheckboxes();
    },
    [setParam, removeParams]
  );

  const resolveReport = useCallback(
    async (id: string, status: ReportStatus) => {
      try {
        await ReportsService.resolveReport(id, status);
        await fetchReports();
      } catch (error: any) {
        const status = error?.response?.status;

        if (error?.response === undefined) {
          setNotification({ text: ErrorMessage.NO_RESPONSE, severity: 'error' });
        } else if (status >= 400 && status < 500) {
          setNotification({
            text: ErrorMessage.UNABLE_TO_PROCESS_REQUEST,
            severity: 'error',
          });
        } else {
          setNotification({ text: ErrorMessage.SERVER_ERROR, severity: 'error' });
        }
      }
    },
    [ReportsService.resolveReport]
  );

  const tableClassNames = useTableStyles();
  const page = currQueryParams.get(QueryParams.PAGE) || '1';
  const currStatusFilter = currQueryParams.get(ReportsParams.REPORT_TYPE) || '0';
  const totalCount = typedReduce(reportsCounts, (acc, curr) => acc + curr.Count, 0);
  const currStatusFilterNumber = parseInt(currStatusFilter);

  return (
    <MainLayout loading={reportsCountLoading}>
      <section className={tableClassNames.TableContainer}>
        <PageTitleWithCount title="Reports" />
        {!reportsCountLoading && (
          <StyledTabs
            className={classNames.StatusTabs}
            value={currStatusFilter}
            indicatorColor="primary"
            onChange={reportTypeOnChange}
          >
            <StyledTab
              value={'0'}
              label={<TabLabelWithCount title={'All'} count={totalCount} isSelected={currStatusFilter === '0'} />}
            />
            <StyledTab
              value={'1'}
              label={
                <TabLabelWithCount
                  title={reportTypeMapper[1]}
                  count={gerReportsOfTypeCount(reportsCounts, 1)}
                  isSelected={currStatusFilter === '1'}
                />
              }
            />
            <StyledTab
              value={'2'}
              label={
                <TabLabelWithCount
                  title={reportTypeMapper[2]}
                  count={gerReportsOfTypeCount(reportsCounts, 2)}
                  isSelected={currStatusFilter === '2'}
                />
              }
            />
            <StyledTab
              value={'3'}
              label={
                <TabLabelWithCount
                  title={reportTypeMapper[3]}
                  count={gerReportsOfTypeCount(reportsCounts, 3)}
                  isSelected={currStatusFilter === '3'}
                />
              }
            />
            <StyledTab
              value={'4'}
              label={
                <TabLabelWithCount
                  title={reportTypeMapper[4]}
                  count={gerReportsOfTypeCount(reportsCounts, 3)}
                  isSelected={currStatusFilter === '4'}
                />
              }
            />
          </StyledTabs>
        )}

        {reportsResponse && (
          <TableLayout
            loading={loading}
            numberOfPages={Math.ceil(reportsResponse.TotalItemCount / parseInt(count))}
            currentPage={parseInt(page)}
            isEmpty={reportsResponse.Page.length === 0}
            noItemsMessage={`No ${
              isNumberKeyOfReportTypeMapper(currStatusFilterNumber) ? reportTypeMapper[currStatusFilterNumber] : ''
            } Reports`}
          >
            <CustomTableHead
              tableHeaders={tableHeaders}
              selectPage={selectPage}
              unselectPage={unselectPage}
              checkedItemsState={checkedItemsState}
              deleteHandler={deleteHandler}
              editable={isAdmin}
            />
            <TableBody>
              {reportsResponse.Page.map((el, index) => {
                const authorProfileId = getProfileId(el.Author);
                const pestProfileId = getProfileId(el.Pest);
                const badgeData = getBadgeData(el.Status);
                const roleReportOn = el.Pest?.Company ? 1 : 0;
                const roleReported = el.Author?.Company ? 1 : 0;

                return (
                  <CustomTableRow key={el.Id}>
                    <CustomTableBodyCheckboxCell
                      className={classNames.TableCell}
                      isChecked={Boolean(checkedItemsState[el.Id])}
                      onChange={checkboxOnChange}
                      id={el.Id}
                      editable={isAdmin}
                    />
                    <TableCell className={classNames.TableCell}>
                      {(reportsResponse.PageIndex - 1) * parseInt(count) + index + 1}
                    </TableCell>
                    <TableCell className={classNames.TableCell}>
                      {authorProfileId && el.Author && (
                        <GoToProfileLink id={authorProfileId} role={roleReported}>
                          {getAuthorText(el.Author)}
                        </GoToProfileLink>
                      )}
                    </TableCell>
                    <TableUserTypeCell className={classNames.TableCell} type={el.Author?.Role} />
                    <TableCell className={classNames.TableCell}>
                      {pestProfileId && el.Pest && (
                        <GoToProfileLink id={pestProfileId} role={roleReportOn}>
                          {getPestText(el)}
                        </GoToProfileLink>
                      )}
                    </TableCell>
                    <TableMessageCell className={classNames.TableCell} text={el.Message} />
                    <TableCell className={classNames.TableCell}>
                      {badgeData && (
                        <Button
                          onClick={() => resolveReport(el.Id, ReportStatus.RESOLVED)}
                          disabled={el.Status === ReportStatus.RESOLVED}
                        >
                          <Badge type={badgeData.type} text={badgeData.text} />
                        </Button>
                      )}
                    </TableCell>
                    <TableCell className={classNames.TableCell}>
                      {createDateTime(el.CreatedDateTime).toFormat(DateTimeFormat.FULL)}
                    </TableCell>
                  </CustomTableRow>
                );
              })}
            </TableBody>
          </TableLayout>
        )}
      </section>
    </MainLayout>
  );
};

const getPestText = (report: IReport) => {
  let text = report.Pest?.FullName;

  if (report.Pest?.Company) {
    text = `${report.Pest.Company.Name}`;

    if (report.PestJob) {
      text += ` (job: ${report.PestJob.Name})`;
    }
  }

  return text;
};

const getAuthorText = (author: IReport['Author']) => {
  let text = author?.FullName;

  if (author?.Company) {
    text = `${author.Company.Name} (rep: ${text})`;
  }

  return text;
};
