import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { HttpService } from 'services/http/axios';
import { store } from './reduxStore';
import { setupInterceptors } from './utils/setupInterceptors';
import './index.css';
import { App } from 'App';

setupInterceptors(store);

const renderApp = () =>
  render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
    document.getElementById('root')
  );

fetch(`${process.env.PUBLIC_URL}/env.json`)
  .then((r) => r.json())
  .then((environment) => {
    HttpService.setEnvironment(environment);

    renderApp();
  });
