import React from 'react';
import { HomeLayout } from '../../layouts/Home';
import { CompanyEditMain } from './Main';

export const CompanyEdit = () => {
  return (
    <HomeLayout>
      <CompanyEditMain />
    </HomeLayout>
  );
};
