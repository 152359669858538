/* eslint-disable react/jsx-no-undef */
import React, { useCallback, useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getFormInput } from 'components/FormComponents/getFormInput';
import { IGeoCity } from 'services/http/geo/interfaces';
import { StyledCancelButton } from 'components/ui/CancelButton';
import { StyledSaveButton } from 'components/ui/SaveButton';
import { Loading } from 'components/Loading';
import { validateCity } from 'utils/validations/validateCity';

export interface ICityEditFormData {
  name: string;
  // countryCode: string;
  // countryName: string;
  // countryEn: string;
  // countryDa: string;
  // nameEn: string;
  nameDa: string;
}

const getInitialValues = (city?: IGeoCity): ICityEditFormData => {
  // const country = city?.Countries[0];
  const nameTranslations = city?.NameTranslations || [];
  // const enCountryTranslation = (country?.NameTranslations || []).find((t) => t.Culture === 'en-US');
  // const daCountryTranslation = (country?.NameTranslations || []).find((t) => t.Culture === 'da-DK');
  // const enTranslation = nameTranslations.find((t) => t.Culture === 'en-US');
  const daTranslation = nameTranslations.find((t) => t.Culture === 'da-DK');

  return {
    name: city?.Name || '',
    // countryCode: country?.Code || 'DK',
    // countryName: country?.Name || 'Denmark',
    // countryEn: enCountryTranslation?.Value || 'Denmark',
    // countryDa: daCountryTranslation?.Value || 'Danmark',
    // nameEn: enTranslation?.Value || '',
    nameDa: daTranslation?.Value || '',
  };
};

interface CityEditFormProps {
  city?: IGeoCity;
  cancelEdit: () => void;
  saveHandler: (values: ICityEditFormData, formikHelpers: FormikHelpers<ICityEditFormData>) => Promise<void>;
}

export const CityEditForm = ({ city, cancelEdit, saveHandler }: CityEditFormProps) => {
  const initialValues = useMemo(() => getInitialValues(city), [city]);

  const submitHandler = useCallback(saveHandler, [saveHandler]);

  const cancelHandler = useCallback(() => {
    cancelEdit();
  }, []);

  return (
    <>
      <Typography variant="h1">Edit City</Typography>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validateCity}>
        {(props) => <InnerForm {...props} cancelHandler={cancelHandler} />}
      </Formik>
    </>
  );
};

interface IInnerFormProps {
  cancelHandler: () => void;
}

const FormInput = getFormInput<ICityEditFormData>();

const InnerForm = ({ cancelHandler, isSubmitting }: FormikProps<ICityEditFormData> & IInnerFormProps) => {
  return (
    <Box mt={2}>
      <Form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <FormInput label="City Name (en-US)" name="name" />
              </Grid>
              <Grid item>
                <FormInput label="City Name (da-DK)" name="nameDa" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item>
                <StyledCancelButton onClick={cancelHandler}>Cancel</StyledCancelButton>
              </Grid>
              <Grid item>
                <StyledSaveButton type="submit">{isSubmitting ? <Loading /> : 'Save'}</StyledSaveButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};
