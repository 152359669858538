import { ICityEditFormData } from 'pages/Sities/Main/CityEditForm';
import ValidatorJs from 'validatorjs';

const authRules = {
  name: 'required',
  nameDa: 'required',
  // countryCode: 'required',
  // countryName: 'required',
  // nameEn: 'required',
  // countryEn: 'required',
  // countryDa: 'required',
};

export function validateCity(userData: ICityEditFormData) {
  const validation = new ValidatorJs(userData, authRules);

  validation.check();

  return validation.errors.errors;
}
