import { makeStyles } from '@material-ui/core/styles';
import trashIcon from '../../../../assets/trashIcon.svg';

export const useEditPhotoGroupStyles = makeStyles({
  GroupContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  UploadButtonPositioning: {
    marginLeft: 14,
  },
  Photo: {
    display: 'flex',
    position: 'relative',
    width: 94,
  },
  PhotoImg: {
    borderRadius: '50%',
    width: 94,
    height: 94,
  },
  TrashIcon: {
    padding: 6,
    background: '#FFEFCC',
    borderRadius: '50%',
    border: '2px solid #FFFF',
    position: 'absolute',
    top: 10,
    right: 0,
    cursor: 'pointer',
    '&::after': {
      display: 'block',
      content: `url(${trashIcon})`,
      textAlign: 'center',
      width: 17,
      height: 17,
      borderRadius: '50%',
    },
  },
});
