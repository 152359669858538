import { makeStyles } from '@material-ui/core/styles';

export const useConfirmedStyles = makeStyles({
  Container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 14,
  },
  _verified: {
    color: '#6EB258',
  },
});

export const useConfirmedImgStyles = makeStyles((theme) => ({
  Img: {
    width: 7,
    height: 7,
    padding: 4,
    borderRadius: '50%',
    verticalAlign: 'bottom',
  },
  CvrImg_Confirmed: {
    background: '#F2FDEE',
  },
  CvrImg_NotConfirmed: {
    background: theme.palette.background.paper,
  },
}));
