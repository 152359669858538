import React, { useCallback, useRef, useState } from 'react';
import { ClickAwayListener, Collapse, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { IEvent } from '../../services/http/interfaces';
import { useLogsStyles, useLogsListStyles, useLogItemStyles } from './LogsStyles';
import { Loading } from '../Loading';
import { IPaginationFetchFunc, useInfiniteScrolling } from '../../utils/useInfiniteScrolling';
import { createDateTime, DateTimeFormat } from '../../utils/createDateTime';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

interface ILogItemProps {
  lastElementRef?: (node: any) => void;
  item: IEvent;
}

const LogItem = ({ item, lastElementRef }: ILogItemProps) => {
  const classNames = useLogItemStyles();

  return (
    <ListItem ref={lastElementRef}>
      <Typography classes={{ root: classNames.Time }} variant="subtitle2">
        {createDateTime(item.CreatedDateTime).toFormat(DateTimeFormat.ISO)}
      </Typography>
      <List className={classNames.ArgsWrapper}>
        {item.Args.filter((el) => el.Key !== 'jobId').map((el, index) => (
          <ListItem classes={{ root: classNames.LogItem }} key={item.UserId + el.Value + index}>
            <FiberManualRecordIcon className={classNames.Dot} htmlColor={'#FFB002'} />
            {el.Value.length > 28 ? (
              <Typography variant="subtitle1" classes={{ root: classNames.LogItemText }}>
                {el.Value}
              </Typography>
            ) : (
              <Tooltip title={el.Value}>
                <Typography variant="subtitle1" classes={{ root: classNames.LogItemText }}>
                  {el.Value}
                </Typography>
              </Tooltip>
            )}
          </ListItem>
        ))}
      </List>
    </ListItem>
  );
};

const LogsList = ({ id, fetchFunc }: Omit<ILogsProps, 'name'>) => {
  const classNames = useLogsListStyles();
  const root = useRef(null);
  const { lastElement, page, isLoading } = useInfiniteScrolling<IEvent>(fetchFunc, id, root.current);

  return (
    <List className={classNames.List} ref={root}>
      {page.map((el, index, arr) => {
        if (index === arr.length - 1) {
          return <LogItem lastElementRef={lastElement} key={el.UserId + index} item={el} />;
        }

        return <LogItem key={el.UserId + index} item={el} />;
      })}
      {isLoading && (
        <ListItem>
          <Loading />
        </ListItem>
      )}
      {!isLoading && page.length === 0 && <ListItem>No recent events</ListItem>}
    </List>
  );
};

interface ILogsProps {
  fetchFunc: IPaginationFetchFunc<IEvent>;
  id: string;
  name: string;
}

export const Logs = ({ fetchFunc, id, name }: ILogsProps) => {
  const classNames = useLogsStyles();
  const anchorRef = useRef<HTMLButtonElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const clickAwayHandler = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  const toggleLogs = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  return (
    <ClickAwayListener onClickAway={clickAwayHandler}>
      <div className={classNames.Container}>
        <Button buttonRef={anchorRef} variant="outlined" onClick={toggleLogs}>
          {name}
        </Button>
        {isMenuOpen && (
          <Collapse in={true} className={classNames.List_open}>
            <LogsList fetchFunc={fetchFunc} id={id} />
          </Collapse>
        )}
      </div>
    </ClickAwayListener>
  );
};
