import { makeStyles } from '@material-ui/core/styles';

export const useJobHistoriesStyles = makeStyles((theme) => ({
  JobHistories: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  JobsSection: {
    marginTop: 10,
  },
  Separator: {
    margin: 10,
    height: 1,
    background: theme.palette.secondary.main,
  },
}));

export const useJobStyles = makeStyles({
  Job: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 10,
  },
  CompanyName: {
    display: 'inline',
    fontSize: 14,
  },
  SpaceBetween: {
    margin: 4,
  },
});
