import React, { useCallback, useEffect, useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { StyledCheckbox } from '../../../../components/ui/StyledCheckbox';
import { FilterItemLayout } from '../../../../layouts/Home/Filters/FilterItemLayout';
import { ICheckboxState } from '../../../../intefaces';
import { useQuery } from '../../../../utils/useQuery';
import { useSelectedFiltersContext } from '../../../../utils/selectedFiltersContext';
import { CompaniesParams, QueryParams } from '../../../../enums';
import { getFilteredCheckboxStateIds } from '../../../../utils/filterCheckboxState';
import { Typography } from '@material-ui/core';

export const numberOfEmployeesRanges = ['0-10', '11-20', '21-50', '51-100', '100+'];
const title = 'Number of employees';
const queryParamsNames = [CompaniesParams.EMPLOYEE_COUNT];

export const NumberOfEmployees = () => {
  const [checkedRangesState, setCheckedRangesState] = useState<ICheckboxState>({});
  const { currQueryParams, removeParams, setParamArr, setParam } = useQuery();
  const selectedFiltersContext = useSelectedFiltersContext();

  useEffect(() => {
    const ranges = currQueryParams.getAll(CompaniesParams.EMPLOYEE_COUNT);

    if (ranges.length === 0) {
      setCheckedRangesState({});

      return;
    }

    const newRangesState: ICheckboxState = {};
    ranges.forEach((el) => {
      newRangesState[el] = true;
    });
    setCheckedRangesState(newRangesState);
    selectedFiltersContext.set({
      label: title,
      params: queryParamsNames,
    });
  }, [currQueryParams]);

  const removeNumberOfEmployeesFilter = useCallback(() => {
    selectedFiltersContext.remove(title);
    removeParams([CompaniesParams.EMPLOYEE_COUNT]);
  }, [removeParams, selectedFiltersContext]);

  const applyNumberOfEmployeesFilter = useCallback(() => {
    const checkedRangesIds = getFilteredCheckboxStateIds(checkedRangesState);

    if (checkedRangesIds.length === 0) {
      removeNumberOfEmployeesFilter();

      return;
    }

    setParamArr(CompaniesParams.EMPLOYEE_COUNT, checkedRangesIds);
    selectedFiltersContext.set({
      label: title,
      params: queryParamsNames,
    });
    setParam(QueryParams.PAGE, '1');
  }, [currQueryParams, setParamArr, checkedRangesState]);

  const checkboxOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
      setCheckedRangesState((prevState) => ({ ...prevState, [name]: event.target.checked }));
    },
    [setCheckedRangesState]
  );

  return (
    <FilterItemLayout title={title} applyHandler={applyNumberOfEmployeesFilter}>
      <FormGroup>
        {numberOfEmployeesRanges.map((range) => (
          <FormControlLabel
            key={range}
            control={<StyledCheckbox />}
            checked={Boolean(checkedRangesState[range])}
            label={<Typography variant="subtitle1">{range}</Typography>}
            onChange={(event) => checkboxOnChange(event as React.ChangeEvent<HTMLInputElement>, range)}
          />
        ))}
      </FormGroup>
    </FilterItemLayout>
  );
};
