import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, IconButton, TableBody, TableCell } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { useTableStyles } from '../../../styles';
import { TableLayout } from '../../../layouts/Table';
import { CustomTableRow } from '../../../components/TableComponents/TableRow';
import { CustomTableBodyCheckboxCell } from '../../../components/TableComponents/TableCheckboxCell';
import { Search } from '../../../components/Search';
import { createDateTime, DateTimeFormat } from '../../../utils/createDateTime';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { FilterButton } from '../../../components/FilterButton';
import { useQuery } from '../../../utils/useQuery';
import { CandidatesParams, ErrorMessage, QueryParams } from '../../../enums';
import { CustomTableHead, ITableHeadCellData } from '../../../components/TableComponents/TableHead';
import { useCheckboxState } from '../../../utils/useCheckboxState';
import { ICandidate } from '../../../services/http/candidates/interfaces';
import { CandidatesService, IBirthdayRange } from '../../../services/http/candidates';
import { TableWithAvatarCell } from '../../../components/TableComponents/TableRow/TableWithAvatarCell';
import { useRowClickHandler } from '../../../utils/useRowClickHandler';
import { DateTime } from 'luxon';
import { PageTitleWithCount } from '../../../components/PageTitleWithCount';
import { TableNumberOfCell } from '../../../components/TableComponents/TableRow/TableNumberOfCell';
import useAxios from 'axios-hooks';
import { IGetPaginationResponse } from '../../../services/http/interfaces';
import { HttpService } from '../../../services/http/axios';
import { useNotificationContext } from '../../../utils/notificationContext';
import { useAccount } from 'utils/useAccount';
import { DataSortButton } from 'components/DataSort';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { saveAs } from 'file-saver';
const count = '9';

interface ICompaniesMainProps {
  showFilters: () => void;
  isFiltersShow: boolean;
}
enum SortNames {
  FULL_NAME = 'FullName',
  APPLIES = 'Statistics.ApplicationsCount',
  SHORTLISTED = 'Statistics.ShortlistsCount',
  REJECTED = 'Statistics.RejectsCount',
  LOCATION = 'LocationInfo',
  DATE = 'CreatedOn',
}

const getBirthdayRange = (from?: string | null, to?: string | null): IBirthdayRange | undefined => {
  if (!from || !to) {
    return;
  }

  const fromNumber = parseInt(from);
  const toNumber = parseInt(to);

  const now = DateTime.now();

  return {
    birthdayFrom: now.minus({ years: toNumber }).toString(),
    birthdayTo: now.minus({ years: fromNumber }).toString(),
  };
};

const employeeAgeParams = [CandidatesParams.EMPLOYEE_AGE_FROM, CandidatesParams.EMPLOYEE_AGE_TO];

const tableHeaders: ITableHeadCellData[] = [
  { text: '#' },
  {
    text: 'NAME',
    sortName: SortNames.FULL_NAME,
  },
  {
    text: 'APPLIES',
    sortName: SortNames.APPLIES,
  },
  { text: 'SHORTLISTED', sortName: SortNames.SHORTLISTED },
  {
    text: 'REJECTED',
    sortName: SortNames.REJECTED,
  },
  { text: 'LANGUAGES' },
  {
    text: 'LOCATION',
    sortName: SortNames.LOCATION,
  },
  {
    text: 'DATE',
    sortName: SortNames.DATE,
  },
];

export const CandidatesMain = ({ showFilters, isFiltersShow }: ICompaniesMainProps) => {
  const tableClassNames = useTableStyles();
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const { currQueryParams, getFilteredParams } = useQuery();
  const confirm = useConfirm();
  const { setNotification } = useNotificationContext();
  const rowClickHandler = useRowClickHandler();

  const candidatesParams = useMemo(() => getFilteredParams(employeeAgeParams).toString(), [currQueryParams]);
  const employeeAgeFrom = currQueryParams.get(CandidatesParams.EMPLOYEE_AGE_FROM);
  const employeeAgeTo = currQueryParams.get(CandidatesParams.EMPLOYEE_AGE_TO);
  const birthdayRange = useMemo(() => getBirthdayRange(employeeAgeFrom, employeeAgeTo), [
    employeeAgeFrom,
    employeeAgeTo,
  ]);
  const { isAdmin } = useAccount();
  const [{ data: candidatesResponse, loading }, fetchCandidates] = useAxios<IGetPaginationResponse<ICandidate>>(
    {
      url: `${HttpService.env.adminAPI}/candidates?${candidatesParams}`,
      params: birthdayRange
        ? {
            count,
            birthdayFrom: birthdayRange.birthdayFrom,
            birthdayTo: birthdayRange.birthdayTo,
          }
        : { count },
    },
    { useCache: false }
  );
  const {
    checkedItemsState,
    getCheckedItemsIds,
    resetCheckboxes,
    selectPage,
    unselectPage,
    checkboxOnChange,
  } = useCheckboxState(candidatesResponse?.Page || []);

  const exportCSV = useCallback(() => {
    const paramsStr = getFilteredParams([...employeeAgeParams, QueryParams.PAGE]);
    const params = birthdayRange
      ? {
          birthdayFrom: birthdayRange.birthdayFrom,
          birthdayTo: birthdayRange.birthdayTo,
        }
      : {};

    HttpService.get<BlobPart>(`${HttpService.env.adminAPI}/candidates?${paramsStr}`, {
      headers: { Accept: 'text/csv' },
      params,
    })
      .then((res) => {
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `Candidates_${createDateTime().toFormat('yyyy_LLL_dd_HH_mm')}`);
      })
      .finally(() => {
        setExportLoading(false);
      });
  }, [birthdayRange, getFilteredParams]);

  const deleteHandler = useCallback(async () => {
    try {
      await confirm({ description: 'This action is permanent!' });
      const checkedItemsIds = getCheckedItemsIds();
      await CandidatesService.deleteCandidates(checkedItemsIds);
      resetCheckboxes();
      setNotification({ text: `${checkedItemsIds.length} candidates successfully deleted`, severity: 'success' });
      fetchCandidates();
    } catch (error) {
      const status = error?.response?.status;

      if (error?.response === undefined) {
        setNotification({ text: ErrorMessage.NO_RESPONSE, severity: 'error' });
      } else if (status >= 400 && status < 500) {
        setNotification({
          text: ErrorMessage.UNABLE_TO_PROCESS_REQUEST,
          severity: 'error',
        });
      } else {
        setNotification({ text: ErrorMessage.SERVER_ERROR, severity: 'error' });
      }
    }
  }, [confirm, getCheckedItemsIds, setNotification]);

  const page = currQueryParams.get(QueryParams.PAGE) || '1';

  return (
    <MainLayout>
      <section className={tableClassNames.TableContainer}>
        <PageTitleWithCount title="Candidates" itemsCount={candidatesResponse?.TotalItemCount} />
        <Box mt={3} mb={2}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Search />
                </Grid>
                <Grid item>
                  <FilterButton onClick={showFilters} isFiltersShow={isFiltersShow} />
                </Grid>
                <Grid item>
                  <DataSortButton
                    fromKey={CandidatesParams.CREATED_DATE_FROM}
                    toKey={CandidatesParams.CREATED_DATE_TO}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton onClick={exportCSV}>
                <CloudDownloadIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>

        {candidatesResponse && (
          <TableLayout
            loading={loading || exportLoading}
            numberOfPages={Math.ceil(candidatesResponse.TotalItemCount / parseInt(count))}
            currentPage={parseInt(page)}
            isEmpty={candidatesResponse.Page.length === 0}
            noItemsMessage={'No candidates found'}
          >
            <CustomTableHead
              tableHeaders={tableHeaders}
              selectPage={selectPage}
              unselectPage={unselectPage}
              checkedItemsState={checkedItemsState}
              deleteHandler={deleteHandler}
              editable={isAdmin}
            />
            <TableBody>
              {candidatesResponse.Page.map((el, index) => {
                return (
                  <CustomTableRow
                    key={el.Id}
                    clickHandler={(event: React.MouseEvent<HTMLTableRowElement>) =>
                      rowClickHandler(event, `/candidate/${el.Id}`)
                    }
                  >
                    <CustomTableBodyCheckboxCell
                      isChecked={Boolean(checkedItemsState[el.Id])}
                      onChange={checkboxOnChange}
                      id={el.Id}
                      editable={isAdmin}
                    />
                    <TableCell>{(candidatesResponse?.PageIndex - 1) * parseInt(count) + index + 1}</TableCell>
                    <TableWithAvatarCell photo={el.Thumbnail} text={el.FullName} />
                    <TableNumberOfCell count={el.Statistics.ApplicationsCount} itemName="Applies" />
                    <TableNumberOfCell count={el.Statistics.ShortlistsCount} itemName="Shortlisted" />
                    <TableNumberOfCell count={el.Statistics.RejectsCount} itemName="Rejected" />
                    <TableCell>{el.Languages.map(({ Name }) => Name).join(', ')}</TableCell>
                    <TableCell>{`${el.LocationInfo.Country} ${el.LocationInfo.City} `}</TableCell>
                    <TableCell>{createDateTime(el.CreatedOn).toFormat(DateTimeFormat.FULL)}</TableCell>
                  </CustomTableRow>
                );
              })}
            </TableBody>
          </TableLayout>
        )}
      </section>
    </MainLayout>
  );
};
