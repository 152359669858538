import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import Button from '@material-ui/core/Button';
import { useDataSortStyles } from './DataSortStyles';
import { ReactComponent as DataSortIcon } from '../../assets/dataSort.svg';
import { ReactComponent as DropdownIcon } from '../../assets/dropdown.svg';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Popper, ClickAwayListener } from '@material-ui/core';
import { StyledSaveButton } from '../ui/SaveButton';
import { useQuery } from '../../utils/useQuery';
import { createDateTime } from '../../utils/createDateTime';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { QueryParams } from 'enums';

interface DataSortButtonProps {
  fromKey: string;
  toKey: string;
}
const currenDay = DateTime.now();
const presets = [
  {
    key: '3m',
    label: 'Last 3 Months',
    from: currenDay.startOf('day').minus({ months: 3 }).toString(),
    to: currenDay.endOf('day').toString(),
  },
  {
    key: '6m',
    label: 'Last 6 Months',
    from: currenDay.startOf('day').minus({ months: 6 }).toString(),
    to: currenDay.endOf('day').toString(),
  },
  {
    key: '12m',
    label: 'Last 12 Months',
    from: currenDay.startOf('day').minus({ months: 12 }).toString(),
    to: currenDay.endOf('day').toString(),
  },
];

export const DataSortButton = ({ fromKey, toKey }: DataSortButtonProps) => {
  const classNames = useDataSortStyles();
  const anchorRef = useRef<HTMLButtonElement>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const { currQueryParams, setParam, removeParams } = useQuery();

  const startDate = currQueryParams.get(fromKey);
  const endDate = currQueryParams.get(toKey);

  const [ranges, setRanges] = useState([
    {
      startDate: startDate ? DateTime.fromISO(startDate).toJSDate() : DateTime.now().minus({ days: 7 }).toJSDate(),
      endDate: endDate ? DateTime.fromISO(endDate).toJSDate() : DateTime.now().toJSDate(),
      key: 'selection',
    },
  ]);

  const selectedPreset = useMemo(() => {
    return presets.find((p) => p.from === startDate && p.to === endDate);
  }, [startDate, endDate]);

  const handleDateSortClick = useCallback(() => {
    !isDateRangeOpen && setIsMenuOpen((prev) => !prev);
  }, [setIsMenuOpen]);

  const handleCustomSortClick = () => {
    setIsMenuOpen(false);
    setIsDateRangeOpen(true);
  };

  const clickAwayHandler = useCallback(() => {
    !isDateRangeOpen && setIsMenuOpen(false);
    !isMenuOpen && setIsDateRangeOpen(false);
  }, [setIsDateRangeOpen, isDateRangeOpen, isMenuOpen]);

  const isActive = useCallback(
    (preset: any) => {
      return preset.from === startDate && preset.to === endDate;
    },
    [startDate, endDate]
  );

  const onSelectPreset = useCallback(
    (preset) => {
      setParam(fromKey, preset.from);
      setParam(toKey, preset.to);
      setParam(QueryParams.PAGE, '1');
      setIsMenuOpen(false);
    },
    [setParam, setIsMenuOpen]
  );

  const handleChange = useCallback(
    (item) => {
      setRanges([item.selection]);
    },
    [setRanges]
  );

  const applyDateRange = useCallback(() => {
    setParam(fromKey, createDateTime(ranges[0].startDate).toString());
    setParam(toKey, createDateTime(ranges[0].endDate).toString());
    setIsDateRangeOpen(false);
  }, [setParam, ranges]);

  const clearDataSort = useCallback(() => {
    removeParams([fromKey, toKey]);
  }, [removeParams]);

  const btnText = useMemo(() => {
    if (selectedPreset) {
      return selectedPreset.label;
    }

    if (startDate && endDate) {
      return `${DateTime.fromISO(startDate).toFormat('DD')} - ${DateTime.fromISO(endDate).toFormat('DD')}`;
    }

    return 'Filter by Date';
  }, [selectedPreset, startDate, endDate]);

  return (
    <>
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <div className={classNames.Container}>
          <Button
            disabled={isDateRangeOpen}
            buttonRef={anchorRef}
            className={classNames.Button}
            onClick={handleDateSortClick}
            endIcon={<DropdownIcon />}
            startIcon={<DataSortIcon />}
          >
            {btnText}
          </Button>
          {currQueryParams.get(fromKey) && (
            <span className={classNames.ClearDataSort} onClick={clearDataSort}>
              Clear Data Sort
            </span>
          )}
          <Popper open={isMenuOpen} anchorEl={anchorRef.current}>
            <MenuList className={classNames.MenuList} autoFocusItem={isMenuOpen} id="menu-list-grow">
              {presets.map((preset) => (
                <MenuItem
                  key={preset.key}
                  className={clsx(classNames.MenuItem, { [classNames.Active]: isActive(preset) })}
                  onClick={() => onSelectPreset(preset)}
                >
                  {preset.label}
                </MenuItem>
              ))}
              <MenuItem className={classNames.MenuItem} onClick={handleCustomSortClick}>
                Custom Sort
              </MenuItem>
            </MenuList>
          </Popper>

          <Popper
            className={classNames.DateRangeWrapper}
            open={isDateRangeOpen}
            anchorEl={anchorRef.current}
            transition
          >
            <DateRange
              editableDateInputs={true}
              ranges={ranges}
              onChange={handleChange}
              moveRangeOnFirstSelection={false}
            />
            <StyledSaveButton onClick={applyDateRange}>Apply</StyledSaveButton>
          </Popper>
        </div>
      </ClickAwayListener>
    </>
  );
};
