import { IRepForm } from 'pages/CompanyEdit/Main/Representatives/Representative';
import ValidatorJs from 'validatorjs';

const authRep = {
  name: 'between:2,50|required',
};

export function validateRep(userData: IRepForm) {
  const validation = new ValidatorJs(userData, authRep);

  validation.check();

  return validation.errors.errors;
}
