import React, { useCallback, useEffect, useState } from 'react';
import { useLoadScript, GoogleMap, Marker } from '@react-google-maps/api';
import { ILocation } from '../../../../services/http/company/interfaces';
import { HttpService } from '../../../../services/http/axios';
import { Loading } from '../../../Loading';
import { IGeoLocation } from '../../../../services/http/geo/interfaces';

const defaultCoordinates = {
  lat: 55.6760968,
  lng: 12.5683371,
};

const mapOptions: google.maps.MapOptions = {
  controlSize: 20,
  maxZoom: 13,
  fullscreenControl: false,
  gestureHandling: 'greedy',
};

interface IGoogleMap {
  location: ILocation | undefined;
  containerClassName: string;
  onMapClick: (coordinates: IGeoLocation) => void;
}

export const GoogleMapWrapper = ({ location, containerClassName, onMapClick }: IGoogleMap) => {
  const { isLoaded: googleMapLoaded } = useLoadScript({
    googleMapsApiKey: HttpService.env.gmapsAPI,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  useEffect(() => {
    if (!map || !location) {
      return;
    }

    fitBounds(map, { lng: location.Longitude, lat: location.Latitude });
  }, [map, location]);

  const onLoad = useCallback(
    (map) => {
      map.fitBounds(new window.google.maps.LatLngBounds(defaultCoordinates));
      setMap(map);
    },
    [window.google, location]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [setMap]);

  const handleClick = useCallback(
    (event: google.maps.MapMouseEvent) => {
      if (onMapClick && !!event.latLng) {
        onMapClick({ Latitude: event.latLng.lat(), Longitude: event.latLng.lng() });
      }
    },
    [onMapClick]
  );

  // if (!location) {
  //   return <div>lolkek</div>;
  // }

  return googleMapLoaded ? (
    <>
      <GoogleMap
        onClick={handleClick}
        mapContainerClassName={containerClassName}
        center={defaultCoordinates}
        options={mapOptions}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {location && <Marker position={{ lat: location.Latitude, lng: location.Longitude }} />}
      </GoogleMap>
    </>
  ) : (
    <Loading />
  );
};

const fitBounds = (map: google.maps.Map, coordinates: { lat: number; lng: number }) => {
  const bounds = new window.google.maps.LatLngBounds(coordinates);
  map.panTo(bounds.getCenter());
};
