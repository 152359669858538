import { makeStyles } from '@material-ui/core/styles';

export const useFilterButtonStyles = makeStyles((theme) => ({
  Button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 92,
    height: 40,
    border: `1px solid ${theme.palette.secondary.dark}`,
    color: '#78849B',
    borderRadius: 28,
    padding: '2px 20px 2px 16px',
    marginLeft: 12,
    textAlign: 'center',
    transition: '.5s ease',
    '&:hover': {
      backgroundColor: theme.palette.text.primary,
      color: '#FFF',
    },
  },
  Button_active: {
    backgroundColor: theme.palette.text.primary,
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#FFF',
      color: theme.palette.text.primary,
    },
  },
  Icon: {
    width: 14,
    height: 14,
    paddingRight: 10,
  },
}));
