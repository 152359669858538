import React, { useCallback, useEffect, useState } from 'react';
import { CandidatesParams, QueryParams } from '../../../../enums';
import { FilterItemLayout } from '../../../../layouts/Home/Filters/FilterItemLayout';
import { useQuery } from '../../../../utils/useQuery';
import { useSelectedFiltersContext } from '../../../../utils/selectedFiltersContext';
import { CustomSlider } from '../../../Companies/Filters/NumberOfVaccinesSlider/NumberOfVaccinesSlider';

const title = 'Employees Age';
const queryParamNames = [CandidatesParams.EMPLOYEE_AGE_FROM, CandidatesParams.EMPLOYEE_AGE_TO];

const marks = [
  { value: 0, label: 0 },
  { value: 100, label: 100 },
];

export const EmployeesAge = () => {
  const [sliderValues, setSliderValues] = useState<number | number[]>([0, 100]);
  const { currQueryParams, setParam, removeParams } = useQuery();
  const selectedFiltersContext = useSelectedFiltersContext();

  const removeEmployeeAgeFilter = useCallback(() => {
    selectedFiltersContext.remove(title);
    removeParams(queryParamNames);
  }, [removeParams]);

  useEffect(() => {
    const startDefaultValue = currQueryParams.get(CandidatesParams.EMPLOYEE_AGE_FROM);
    const endDefaultValue = currQueryParams.get(CandidatesParams.EMPLOYEE_AGE_TO);

    if (!startDefaultValue || !endDefaultValue) {
      setSliderValues([0, 100]);

      return;
    }

    setSliderValues([parseInt(startDefaultValue), parseInt(endDefaultValue)]);
    selectedFiltersContext.set({
      label: title,
      params: queryParamNames,
    });
  }, [currQueryParams, selectedFiltersContext, removeEmployeeAgeFilter]);

  const handleChange = useCallback((event: React.ChangeEvent<{}>, values: number | number[]) => {
    setSliderValues(values);
  }, []);

  const applyVacanciesFilter = useCallback(() => {
    if (!(sliderValues instanceof Array)) {
      return;
    }

    if (sliderValues[0] === 0 && sliderValues[1] === 100) {
      return removeEmployeeAgeFilter();
    }

    selectedFiltersContext.set({
      label: title,
      params: queryParamNames,
    });

    setParam(CandidatesParams.EMPLOYEE_AGE_FROM, `${sliderValues[0]}`);
    setParam(CandidatesParams.EMPLOYEE_AGE_TO, `${sliderValues[1]}`);
    setParam(QueryParams.PAGE, '1');
  }, [currQueryParams, sliderValues, removeEmployeeAgeFilter]);

  return (
    <FilterItemLayout title={title} applyHandler={applyVacanciesFilter}>
      <CustomSlider
        onChange={handleChange}
        marks={marks}
        min={0}
        max={100}
        valueLabelDisplay="on"
        value={sliderValues}
        getAriaLabel={(index) => (index === 0 ? 'Minimum age' : 'Maximum age')}
      />
    </FilterItemLayout>
  );
};
