import { makeStyles } from '@material-ui/core/styles';

export const useBadgeStyles = makeStyles({
  Badge: {
    fontSize: 14,
    borderRadius: 28,
    textAlign: 'center',
  },
  _shortText: {
    width: 83,
    height: 20,
    padding: '6px 0',
  },
  _longText: {
    minWidth: 90,
    maxWidth: 140,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '6px 6px',
  },
  Badge_premium: {
    background: '#FFF9EB',
    color: '#FFB002',
  },
  Badge_active: {
    background: '#F0FCEC',
    color: '#6EB258',
  },
  Badge_closed: {
    background: '#F2F5F9',
    color: '#78849B',
  },
  Badge_unresolved: {
    background: '#FBEBE7',
    color: '#BE2D0D',
  },
});
