import React from 'react';
import { Avatar as AvatarUI } from '@material-ui/core';
import { useAvatarStyles } from './AvatarStyles';
import clsx from 'clsx';

interface ICompanyAvatarProps {
  logo: string | undefined;
  className?: string;
  size?: '_md' | '_lg' | '_sm';
  alt: string;
}

export const Avatar = ({ logo, className, size = '_lg', alt }: ICompanyAvatarProps) => {
  const classNames = useAvatarStyles();

  return (
    <AvatarUI className={clsx(classNames[size], className)} src={logo}>
      {defaultAvatar(alt)}
    </AvatarUI>
  );
};

const defaultAvatar = (str: string) => {
  if (!str) {
    return '';
  }

  return str[0].toUpperCase();
};
