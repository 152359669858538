import React from 'react';
import { FiltersLayout } from '../../../layouts/Home/Filters';
import { SelectedFiltersProvider } from '../../../utils/selectedFiltersContext';
import { CategoriesFilter } from '../../../components/CategoriesFilter';
import { JobsParams } from '../../../enums';
import { HttpService } from '../../../services/http/axios';

interface IFilterProps {
  isShow: boolean | null;
  hideFilters: () => void;
}

export const Filters = ({ isShow, hideFilters }: IFilterProps) => {
  return (
    <SelectedFiltersProvider>
      <FiltersLayout isShow={isShow} hideFilters={hideFilters}>
        <CategoriesFilter
          title="Categories"
          queryParam={JobsParams.CATEGORY_ID}
          fetchCategoriesUrl={`${HttpService.env.adminAPI}/jobs/categories`}
        />
      </FiltersLayout>
    </SelectedFiltersProvider>
  );
};
