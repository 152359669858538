import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'qs';

interface IEnvironment {
  adminAPI: string;
  idsAPI: string;
  geoAPI: string;
  fsAPI: string;
  searchAPI: string;
  reportsAPI: string;
  gmapsAPI: string;
}

export class HttpService {
  public static get<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
    return this.makeRequest<T>({
      url,
      method: 'get',
      ...options,
    });
  }

  public static post<T>(url: string, data?: object | string, options?: AxiosRequestConfig): Promise<T> {
    return this.makeRequest<T>({
      url,
      method: 'post',
      data,
      ...options,
    });
  }

  public static put<T>(url: string, data?: object, options?: AxiosRequestConfig): Promise<T> {
    return this.makeRequest<T>({
      url,
      method: 'put',
      data,
      ...options,
    });
  }

  public static patch<T>(url: string, data: object, options?: AxiosRequestConfig): Promise<T> {
    return this.makeRequest<T>({ url, method: 'patch', data, ...options });
  }

  public static delete<T>(url: string, options?: AxiosRequestConfig): Promise<T> {
    return this.makeRequest<T>({
      url,
      method: 'delete',
      ...options,
    });
  }

  public static env: IEnvironment;

  public static setEnvironment(data: any) {
    const { mainAPI, ...env } = data;
    this.env = {
      ...env,
      idsAPI: `${mainAPI}/ids`,
      geoAPI: `${mainAPI}/geo`,
      fsAPI: `${mainAPI}/files`,
      searchAPI: `${mainAPI}/search`,
      reportsAPI: `${mainAPI}/reporting`,
    };
  }

  private static async makeRequest<T = any>(config: AxiosRequestConfig): Promise<T> {
    config.paramsSerializer = (params) => {
      return stringify(params);
    };

    const source = axios.CancelToken.source();
    const cancelToken = source.token;

    const { data } = await axios.request({ ...config, cancelToken });

    return data;
  }
}
