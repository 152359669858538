import React, { useCallback, useEffect, useState } from 'react';
import { CustomSlider } from './NumberOfVaccinesSlider';
import { useQuery } from '../../../../utils/useQuery';
import { CompaniesParams, QueryParams } from '../../../../enums';
import { FilterItemLayout } from '../../../../layouts/Home/Filters/FilterItemLayout';
import { useSelectedFiltersContext } from '../../../../utils/selectedFiltersContext';

const title = 'Number of Vacancies';
const marks = [
  { value: 0, label: 0 },
  { value: 100, label: 100 },
];

const queryParamNames = [CompaniesParams.FROM_JOB_COUNT, CompaniesParams.TO_JOB_COUNT];

export const NumberOfVacanciesSelectSlider = () => {
  const [sliderValues, setSliderValues] = useState<number | number[]>([0, 100]);
  const { currQueryParams, setParam, removeParams } = useQuery();
  const selectedFiltersContext = useSelectedFiltersContext();

  const removeVacanciesFilter = useCallback(() => {
    selectedFiltersContext.remove(title);
    removeParams([CompaniesParams.FROM_JOB_COUNT, CompaniesParams.TO_JOB_COUNT]);
  }, [removeParams]);

  useEffect(() => {
    const startDefaultValue = currQueryParams.get(CompaniesParams.FROM_JOB_COUNT);
    const endDefaultValue = currQueryParams.get(CompaniesParams.TO_JOB_COUNT);

    if (!startDefaultValue || !endDefaultValue) {
      setSliderValues([0, 100]);

      return;
    }

    setSliderValues([parseInt(startDefaultValue), parseInt(endDefaultValue)]);
    selectedFiltersContext.set({
      label: title,
      params: queryParamNames,
    });
  }, [currQueryParams, selectedFiltersContext, removeVacanciesFilter]);

  const handleChange = useCallback((event: React.ChangeEvent<{}>, values: number | number[]) => {
    setSliderValues(values);
  }, []);

  const applyVacanciesFilter = useCallback(() => {
    if (!(sliderValues instanceof Array)) {
      return;
    }

    if (sliderValues[0] === 0 && sliderValues[1] === 100) {
      return removeVacanciesFilter();
    }

    selectedFiltersContext.set({
      label: title,
      params: queryParamNames,
    });

    setParam(CompaniesParams.FROM_JOB_COUNT, `${sliderValues[0]}`);
    setParam(CompaniesParams.TO_JOB_COUNT, `${sliderValues[1]}`);
    setParam(QueryParams.PAGE, '1');
  }, [currQueryParams, sliderValues, removeVacanciesFilter]);

  return (
    <FilterItemLayout title={title} applyHandler={applyVacanciesFilter}>
      <CustomSlider
        onChange={handleChange}
        marks={marks}
        min={0}
        max={100}
        valueLabelDisplay="on"
        value={sliderValues}
        getAriaLabel={(index) => (index === 0 ? 'Minimum price' : 'Maximum price')}
      />
    </FilterItemLayout>
  );
};
