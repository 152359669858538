import React from 'react';
import { HomeLayout } from '../../layouts/Home';
import { CompanyDetailsMain } from './Main';

export const CompanyDetails = () => {
  return (
    <HomeLayout>
      <CompanyDetailsMain />
    </HomeLayout>
  );
};
