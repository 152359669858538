import React from 'react';
import { Typography } from '@material-ui/core';
import useAxios from 'axios-hooks';
import { useParams } from 'react-router-dom';
import { HttpService } from '../../../../services/http/axios';
import { IRepresentative } from '../../../../services/http/company/interfaces';
import { Representative } from './Representative';
import { Loading } from '../../../../components/Loading';
import { useRepresentativesStyles } from './RepresentativesFormStyles';

export const Representatives = () => {
  const { companyId: id } = useParams<{ companyId: string }>();
  const [{ data: representatives = [], loading }, reload] = useAxios<IRepresentative[]>(
    `${HttpService.env.adminAPI}/companies/${id}/representatives`,
    { useCache: false }
  );
  const classNames = useRepresentativesStyles();

  return (
    <section className={classNames.Representatives}>
      <Typography className={classNames.Heading} variant="h3">
        Representatives of the company
      </Typography>
      {loading ? (
        <Loading />
      ) : (
        representatives.map((el, index) => (
          <Representative key={el.Id} representative={el} index={index + 1} reload={reload} />
        ))
      )}
    </section>
  );
};
