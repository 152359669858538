import { makeStyles } from '@material-ui/core/styles';

export const useMainHeaderStyles = makeStyles((theme) => ({
  Header: {
    marginTop: 34,
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
    paddingBottom: 29,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  ProfileInfoSection: {
    minHeight: 70,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  Wage: {
    marginTop: 41,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  WageImg: {
    marginRight: 8,
    width: 16,
    height: 17,
  },
}));
