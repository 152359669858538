import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useMainStyles } from './MainStyles';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { MainHeader } from './Header';
import { Info } from './Info';
import { IGetCandidateDetails } from '../../../services/http/candidates/interfaces';
import useAxios from 'axios-hooks';
import { HttpService } from '../../../services/http/axios';
import { getMonthExperience } from '../../../utils/getMonthExperience';
import { PrevJobs } from './PrevJobs';
import { DeleteButton } from '../../../components/DeleteEditButtons';
import { DetailsDescription } from '../../../components/DetailsDescription';
import { Logs } from '../../../components/Logs';
import { CandidatesService } from '../../../services/http/candidates';
import { NotFound } from '../../../components/NotFound';
import { ErrorMessage } from '../../../enums';
import { useNotificationContext } from '../../../utils/notificationContext';
import { useHistory } from 'react-router';
import { useConfirm } from 'material-ui-confirm';
import { useAccount } from 'utils/useAccount';

export const CandidateDetailsMain = () => {
  const classNames = useMainStyles();
  const { setNotification } = useNotificationContext();
  const confirm = useConfirm();
  const history = useHistory();
  const { candidateId: id } = useParams<{ candidateId: string }>();
  const { isAdmin } = useAccount();

  const [{ data: candidateDetails, loading: detailsLoading }] = useAxios<IGetCandidateDetails>(
    `${HttpService.env.adminAPI}/candidates/${id}`,
    { useCache: false }
  );

  const deleteCandidate = useCallback(async () => {
    try {
      await confirm({ description: 'This action is permanent!' });
      await CandidatesService.deleteCandidates([id]);
      setNotification({
        text: `${candidateDetails?.FullName} candidate successfully deleted`,
        severity: 'success',
      });
      history.goBack();
    } catch (error) {
      if (error === undefined) {
        return;
      }

      const status = error?.response?.status;

      if (error?.response === undefined) {
        setNotification({ text: ErrorMessage.NO_RESPONSE, severity: 'error' });
      } else if (status >= 400 && status < 500) {
        setNotification({
          text: ErrorMessage.UNABLE_TO_PROCESS_REQUEST,
          severity: 'error',
        });
      } else {
        setNotification({ text: ErrorMessage.SERVER_ERROR, severity: 'error' });
      }
    }
  }, [candidateDetails?.FullName, setNotification]);

  if (!detailsLoading && !candidateDetails) {
    return (
      <MainLayout>
        <NotFound item={'Candidate'} />
      </MainLayout>
    );
  }

  return (
    <MainLayout loading={detailsLoading} hasBackPath={true}>
      <>
        {candidateDetails && (
          <>
            <MainHeader
              name={candidateDetails.FullName}
              lastSeen={candidateDetails.LastUsedOn}
              location={candidateDetails.Location}
              logo={candidateDetails.Thumbnail}
              email={candidateDetails.Email}
              workHours={candidateDetails.WorkHours}
              phone={candidateDetails.Phone}
            />
            <Info
              dateOfAccountCreation={candidateDetails.ReadEventsOn}
              birthday={candidateDetails.Birthday}
              educations={candidateDetails.Educations}
              monthExperience={getMonthExperience(candidateDetails?.JobHistory)}
              languages={candidateDetails.Languages}
              permission={candidateDetails.WorkPermission}
              driverLicenseCategory={candidateDetails.DriverLicenseCategory}
            />
            <DetailsDescription title="About">{candidateDetails.AboutMe}</DetailsDescription>
            <div className={classNames.FlexWrapper}>
              <Logs id={id} fetchFunc={CandidatesService.getLogs} name="Candidate Logs" />
              {isAdmin && <DeleteButton onClick={deleteCandidate} />}
            </div>
            <div className={classNames.Separator} />
            {!!candidateDetails.JobHistory?.length && <PrevJobs jobHistory={candidateDetails.JobHistory} />}
          </>
        )}
      </>
    </MainLayout>
  );
};
