import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

export const useNotificationStyles = (index: number) => {
  return makeStyles(() => ({
    Alert: {
      position: 'fixed',
      bottom: 55 * index,
      left: 0,
      transform: 'translateX(0%)',
      boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
      transition: '1s ease',
    },
  }))();
};

export const StyledAlert = withStyles({
  root: { maxHeight: '40px', maxWidth: '50vw', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
})(Alert);
