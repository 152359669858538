import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

import { AuthService } from '../services/http/auth';
import { StorageService } from '../services/storage';
import { logOut } from '../reduxStore/actions/user';

let refreshTokenPromise: Promise<any> | null = null;

const createAuthRefreshInterceptor = (instance: AxiosInstance, refreshAuthCall: (error: any) => Promise<any>): void => {
  if (typeof refreshAuthCall !== 'function') {
    throw new Error('axios-auth-refresh requires `refreshAuthCall` to be a function that returns a promise.');
  }

  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: any) => {
      if (!error.response || error.response.status !== 401 || error.response.config.___retry) {
        return Promise.reject(error);
      }

      if (!refreshTokenPromise) {
        refreshTokenPromise = refreshAuthCall(error);
      }

      return refreshTokenPromise
        .then(() => {
          return instance({ ...error.response.config, ___retry: true });
        })
        .finally(() => {
          refreshTokenPromise = null;
        });
    }
  );
};

const authRefresh = (store: any): void => {
  const refreshCallback = (failedRequest: any): Promise<any> => {
    return AuthService.refreshUser()
      .then(({ refresh_token, access_token }) => {
        StorageService.setRefreshToken(refresh_token);
        StorageService.setAccessToken(access_token);
        failedRequest.response.config.headers['Authorization'] = `Bearer ${StorageService.getAccessToken()}`;

        return Promise.resolve();
      })
      .catch((error) => {
        store.dispatch(logOut());
        throw error;
      });
  };

  createAuthRefreshInterceptor(axios, refreshCallback);
};

const unauthorized = (store: any): void => {
  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response?.status === 401) {
      return store.dispatch(logOut());
    }

    throw error;
  });
};

const addToken = (): void => {
  axios.interceptors.request.use((req) => {
    const token = StorageService.getAccessToken();

    if (token) {
      req.headers['Authorization'] = `Bearer ${token}`;
    }

    return req;
  });
};

const permissions = (store: any): void => {
  axios.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response?.status === 400 && error.response.data.ErrorCode === 101) {
      return store.dispatch(logOut());
    }

    throw error;
  });
};

export const setupInterceptors = (store: any): void => {
  addToken();
  authRefresh(store);
  unauthorized(store);
  permissions(store);
};
