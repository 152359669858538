import ValidatorJs from 'validatorjs';
import { IUserAuthData } from '../../reduxStore/actions/user/interfaces';

const authRules = {
  username: 'required',
  password: 'between:2,30|required',
};

export function validateUserLoginData(userData: IUserAuthData) {
  const validation = new ValidatorJs(userData, authRules);

  validation.check();

  return validation.errors.errors;
}
