import { createMuiTheme } from '@material-ui/core';

export const tableHeadRowHeight = 36;

export const baseTheme = createMuiTheme({
  props: {
    MuiTypography: {
      variantMapping: {
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'span',
        subtitle2: 'span',
        body1: 'span',
        body2: 'span',
      },
    },
  },
  overrides: {
    MuiDialogContentText: {
      root: {
        fontSize: 18,
      },
    },
    MuiTypography: {
      noWrap: {
        display: 'block',
      },
    },
    MuiTableRow: {
      root: {
        boxShadow: '0px 4px 38px #f2f5f9',
        '& th:first-child, td:first-child': {
          paddingLeft: 8,
          borderTopLeftRadius: 8,
          borderBottomLeftRadius: 8,
        },
        '& th:last-child, td:last-child': {
          borderTopRightRadius: 8,
          borderBottomRightRadius: 8,
        },
        '& td, th': {
          border: 'none',
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: '#F2F5F9',
        },
      },
      gutters: {
        paddingLeft: 14,
        paddingRight: 14,
        borderRadius: 24,
      },
    },
    MuiListItemIcon: {
      root: { minWidth: 'none' },
    },
    MuiTableBody: {
      root: {
        '& tr': {
          minHeight: 60,
        },
      },
    },
    MuiTableHead: {
      root: {
        '& tr': {
          height: tableHeadRowHeight,
        },
      },
    },
    MuiTableCell: {
      root: {
        padding: '0 8px 0 8px',
        margin: 0,
        maxWidth: 140,
      },
      body: {
        minWidth: 15,
        height: 'auto',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '0 8px 0 8px',
        margin: 0,
        whiteSpace: 'nowrap',
      },
      stickyHeader: {
        backgroundColor: 'transparent',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 28,
        textTransform: 'none',
        minWidth: 83,
      },
      outlined: {
        padding: '6px 16px',
        border: '1px solid #F2F5F9',
      },
      outlinedSecondary: {
        border: '1px solid #BE2D0D',
        color: '#BE2D0D',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: '#be2d0d33',
          borderColor: '#be2d0d33',
        },
      },
      contained: {
        boxShadow: 'none',
        border: '1px solid',
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: 'inherit',
        },
      },
      containedPrimary: {
        color: '#ffffff',
        backgroundColor: '#FFB002',
        borderColor: '#FFB002',
        '&:hover': {
          backgroundColor: '#ffffff',
          color: '#FFB002',
          '& path': {
            fill: '#ffb002',
          },
        },
      },
      containedSecondary: {
        color: '#ffffff',
        backgroundColor: '#6EB258',
        borderColor: '#6EB258',
        '&:hover': {
          backgroundColor: '#6EB258',
          color: '#ffffff',
          '& path': {
            fill: '#6EB258',
          },
        },
      },
    },
    MuiAvatar: {
      root: {
        height: 'auto',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 12,
        color: '#78849B',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
  },
  palette: {
    primary: { main: '#FFB002' },
    secondary: { main: '#E5E5E5', dark: '#E8EFFA' },
    background: {
      default: '#E5E5E5',
      paper: '#F2F5F9',
    },
    text: {
      primary: '#2F3A4E',
      secondary: '#78849B',
    },
  },
  typography: {
    fontFamily: 'GT Walsheim Pro Regular',
    h1: {
      fontFamily: 'GT Walsheim Pro Bold',
      fontWeight: 'bold',
      fontStyle: 'normal',
      textTransform: 'capitalize',
      fontSize: 24,
      color: '#2F3A4E',
    },
    h2: {
      fontFamily: 'GT Walsheim Pro Bold',
      color: '#2F3A4E',
      fontWeight: 'bold',
      fontStyle: 'normal',
      fontSize: 16,
    },
    h3: {
      fontSize: 14,
      fontStyle: 'normal',
      fontWeight: 'bold',
      color: '#78849B',
    },
    h4: {
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: 'normal',
      color: '#2F3A4E',
    },

    h5: {
      fontFamily: 'GT Walsheim Pro Bold',
      fontWeight: 'bold',
      color: '#78849B',
      fontSize: 12,
    },
    body1: {
      color: '#2F3A4E',
      fontSize: 14,
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: 1,
    },
    subtitle2: {
      color: '#2F3A4E',
      fontSize: 12,
    },
  },
});
