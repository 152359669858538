import React from 'react';
import { HomeLayout } from 'layouts/Home';
import { ExternalJobsEditMain } from './Main';

export const ExternalJobsEdit = () => {
  return (
    <HomeLayout>
      <ExternalJobsEditMain />
    </HomeLayout>
  );
};
