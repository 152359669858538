import React from 'react';
import { Typography } from '@material-ui/core';
import { useNotFoundStyles } from './NotFoundStyles';

interface INotFoundProps {
  item: string;
}

export const NotFound = ({ item }: INotFoundProps) => {
  const classNames = useNotFoundStyles();

  return (
    <div className={classNames.NotFound}>
      <Typography variant="h1" style={{ marginBottom: 13 }}>
        404 {item} not found
      </Typography>
      <Typography variant="h2">We are sorry but the {item} you are looking for does not exist.</Typography>
    </div>
  );
};
