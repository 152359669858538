import React from 'react';
import { TableCell } from '@material-ui/core';
import { useCustomTableCheckboxCellStyles } from './TableCheckboxCellStyles';
import { StyledCheckbox } from '../../ui/StyledCheckbox';

interface ICustomTableCheckboxCellProps {
  className?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  id: string;
  isChecked: boolean;
  editable?: boolean;
}

export const CustomTableBodyCheckboxCell = ({
  className,
  onChange,
  isChecked,
  id,
  editable = true,
}: ICustomTableCheckboxCellProps) => {
  const classNames = useCustomTableCheckboxCellStyles();

  return (
    <TableCell className={`${className} ${classNames.TableCell}`}>
      {editable && (
        <StyledCheckbox
          className={classNames._TableCheckboxCellBodyBg}
          checked={isChecked}
          onChange={(event) => onChange(event, id)}
        />
      )}
    </TableCell>
  );
};
