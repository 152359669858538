import React, { useCallback, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { debounce, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useLocationAutoCompleteStyles, useLocationIconStyles } from './AutoCompleteStyles';
import { GoogleMapWrapper } from '../MapWrapper';
import { IGeoLocation, IGeoPlace } from '../../../../services/http/geo/interfaces';
import { GeoService } from '../../../../services/http/geo';
import { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete/Autocomplete';
import { ILocation } from '../../../../services/http/company/interfaces';
import { ErrorOutput } from '../../getFormInput/ErrorOutput';

const LocationAdornment = () => {
  const classNames = useLocationIconStyles();

  return <i className={classNames.LocationIcon} />;
};

interface ILocationInputProps {
  inputClassName: string;
  mapClassName: string;
}

interface IForm {
  location: IGeoLocation;
}

function getInputValue(location: ILocation) {
  return `${location.City ? location.City : ''}, ${location.Country ? location.Country : ''}`;
}

export const LocationAutoComplete = <FormType extends IForm>({ inputClassName, mapClassName }: ILocationInputProps) => {
  const {
    values: { location },
    initialValues,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<FormType>();
  const classNames = useLocationAutoCompleteStyles();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IGeoPlace[]>([]);

  useEffect(() => {
    initialValues.location && setInputValue(getInputValue(initialValues.location));
  }, [initialValues]);

  const getPlaces = useCallback(
    debounce((val: string) => {
      if (val.length === 0) {
        setOptions([]);

        return;
      }

      setLoading(true);

      GeoService.getPlaces(val)
        .then((result) => setOptions(result))
        .finally(() => setLoading(false));
    }, 300),
    [GeoService.getPlaces]
  );

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setInputValue(value);
      getPlaces(value);
    },
    [getPlaces]
  );

  const autoCompleteOnChange = useCallback(async (event: React.ChangeEvent<{}>, value: IGeoPlace | null) => {
    if (!value) {
      return null;
    }

    const location = await GeoService.getPlaceLocation(value);
    setInputValue(getInputValue(location));
    setFieldValue('location', location);
  }, []);

  const onMapClick = useCallback(
    async (coordinates: IGeoLocation) => {
      const location = await GeoService.getLocationByCoordinates(coordinates);
      setFieldValue('location', location);
      setInputValue(getInputValue(location));
      setFieldTouched('location.IsAvailable', true);
    },
    [setFieldValue]
  );

  return (
    <>
      <Autocomplete
        className={inputClassName}
        loading={loading}
        forcePopupIcon={false}
        onChange={autoCompleteOnChange}
        options={options}
        noOptionsText="No results"
        getOptionLabel={(value: IGeoPlace) => {
          return value.Name;
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            inputProps={{ ...params.inputProps, value: inputValue }}
            classes={{ root: classNames.EndAdornment }}
            onChange={handleInputChange}
            aria-haspopup="false"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: <LocationAdornment />,
              classes: {
                adornedEnd: classNames.EndAdornment,
              },
            }}
          />
        )}
        renderOption={(options: IGeoPlace) => {
          return <div>{options.Name}</div>;
        }}
      />
      <ErrorOutput name="location.IsAvailable" />
      <ErrorOutput name="location" />
      <GoogleMapWrapper location={location} containerClassName={mapClassName} onMapClick={onMapClick} />
    </>
  );
};
