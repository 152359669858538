import React, { useCallback } from 'react';
import { ProfileContainer } from '../../../../containers/Profile';
import { useHeaderStyles } from './HeaderStyles';
import { useHistory } from 'react-router';

interface IMainHeaderProps {
  hasBackPath?: boolean;
}

export const MainHeader = ({ hasBackPath }: IMainHeaderProps) => {
  const classNames = useHeaderStyles();
  const history = useHistory();
  const backHandler = useCallback(() => {
    history.goBack();
  }, [history]);

  return (
    <header className={classNames.Header}>
      {hasBackPath && (
        <button className={classNames.BackLink} onClick={backHandler}>
          Back
        </button>
      )}
      <ProfileContainer />
    </header>
  );
};
