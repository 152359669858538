import React from 'react';
import { ICvr } from '../../../../services/http/company/interfaces';
import { useInfoStyles } from './InfoStyles';
import { InfoItem } from '../../../../components/Info';
import confirmed from '../../../../assets/companyDetails/confirmed.svg';
import companyMember from '../../../../assets/member.svg';
import cvrImage from '../../../../assets/companyDetails/cvr.svg';
import employees from '../../../../assets/companyDetails/employees.svg';
import industry from '../../../../assets/companyDetails/industry.svg';
import vacancies from '../../../../assets/bag.svg';
import lastSeen from '../../../../assets/companyDetails/lastSeen.svg';
import { CompanyMembers } from './CompanyMembers';
import { Grid, Typography } from '@material-ui/core';
import { Confirmed } from '../../../../components/Confirmed';
import { DateTime } from 'luxon';
import { ICategory } from '../../../../services/http/interfaces';
import { createDateTime, DateTimeFormat } from '../../../../utils/createDateTime';

interface IInfoProps {
  industry?: ICategory;
  cvr?: ICvr;
  vacancies: number;
  employeeCount: string;
  withWorkeeSince: string;
  lastSeen?: string;
}

export const Info = (props: IInfoProps) => {
  const classNames = useInfoStyles();

  const lastSeenDate = createDateTime(props.lastSeen);

  return (
    <Grid container spacing={4} className={classNames.InfoContainer}>
      <InfoItem image={companyMember} title="Company members">
        <CompanyMembers />
      </InfoItem>
      <InfoItem image={industry} title="Industry">
        <Typography color="textPrimary" variant="subtitle1">
          {props.industry?.Name}
        </Typography>
      </InfoItem>
      <InfoItem image={cvrImage} title="CVR">
        <>
          {props.cvr && (
            <div>
              <Typography color="textPrimary" variant="subtitle1">
                {props.cvr.Code}
              </Typography>
              <Confirmed type="Confirmed" isConfirmed={props.cvr.IsConfirmed} imgClassName={classNames.ConfirmedImg} />
            </div>
          )}
        </>
      </InfoItem>

      <InfoItem image={vacancies} title="Vacancies">
        <Typography color="textPrimary" variant="subtitle1">
          {props.vacancies}
        </Typography>
      </InfoItem>
      <InfoItem image={employees} title="Employees">
        <Typography color="textPrimary" variant="subtitle1">
          {props.employeeCount}
        </Typography>
      </InfoItem>
      <InfoItem image={confirmed} title="Hiring with Workee since">
        <Typography color="textPrimary" variant="subtitle1">
          {DateTime.fromISO(props.withWorkeeSince).toFormat(DateTimeFormat.FULL)}
        </Typography>
      </InfoItem>
      {props.lastSeen && (
        <InfoItem image={lastSeen} title="Last seen">
          <div className={classNames.LastSeenWrapper}>
            <Typography color="textPrimary" variant="subtitle1">
              {lastSeenDate.toFormat(DateTimeFormat.FULL)}
            </Typography>
            <Typography color="textSecondary" variant="subtitle1">
              {lastSeenDate.toFormat(DateTimeFormat.TIME)}
            </Typography>
          </div>
        </InfoItem>
      )}
    </Grid>
  );
};
