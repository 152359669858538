import React from 'react';
import { TableCell, TableCellProps } from '@material-ui/core';
import { useTableNumberOfCellStyles } from './TableNumberOfCellStyles';

interface ICustomTableTextCellProps {
  count?: number;
  itemName: string;
}

export const TableNumberOfCell = ({
  count,
  itemName,
  ...tableCellProps
}: ICustomTableTextCellProps & TableCellProps) => {
  const classNames = useTableNumberOfCellStyles();

  return (
    <TableCell
      {...tableCellProps}
      className={`${classNames.TableNumberOfCell} ${tableCellProps.className ? tableCellProps.className : ''}`}
    >
      {count ? (
        <>
          <span className={classNames.Count}>{count}</span> {itemName}{' '}
        </>
      ) : (
        'No data'
      )}
    </TableCell>
  );
};
