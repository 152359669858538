import { Slider, withStyles } from '@material-ui/core';

export const CustomSlider = withStyles((theme) => ({
  root: {
    marginTop: 30,
    color: theme.palette.primary.main,
    height: 4,
  },
  thumb: {
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    background: 'transparent',
  },
  track: {},
  rail: {
    color: theme.palette.background.paper,
  },
}))(Slider);
