import { ActionType } from 'typesafe-actions';
import { IUserState, UserActionTypes } from './intefaces';
import * as actions from '../../actions/user';
import { StorageService } from '../../../services/storage';

type IUserActions = ActionType<typeof actions>;

const initialState: IUserState = {
  isAuthorized: !!StorageService.getAccessToken(),
  user: null,
};

export default function UserReducer(state = initialState, action: IUserActions): IUserState {
  switch (action.type) {
    case UserActionTypes.USER_LOADED: {
      return { ...state, isAuthorized: true };
    }

    case UserActionTypes.AUTH_ERROR: {
      return { ...state, isAuthorized: false, error: action.payload };
    }

    case UserActionTypes.LOGGED_OUT: {
      return { ...state, isAuthorized: false, user: null };
    }

    case UserActionTypes.SET_USER: {
      return { ...state, user: action.payload };
    }

    default: {
      return state;
    }
  }
}
