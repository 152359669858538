import React from 'react';
import { useToggleHeaderStyles } from './ToggleFilterHeaderStyles';

interface IToggleFilterHeader {
  title: string;
  isToggled: Boolean;
  toggle: () => void;
}

export const ToggleFilterHeader = ({ title, toggle, isToggled }: IToggleFilterHeader) => {
  const classNames = useToggleHeaderStyles();

  return (
    <div className={classNames.ToggleHeader} onClick={toggle}>
      <span className={classNames.Title}>{title}</span>
      <span className={`${classNames._open} ${isToggled && classNames._close}`}></span>
    </div>
  );
};
