import { makeStyles } from '@material-ui/core/styles';

export const useMainStyles = makeStyles({
  InfoContainer: {
    paddingBottom: 20,
    paddingTop: 20,
  },
  DeleteButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  DeleteButton: {
    marginLeft: 'auto',
  },
});
