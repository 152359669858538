import { makeStyles } from '@material-ui/core/styles';

export const useQuestionsListStyles = makeStyles((theme) => ({
  QuestionsList: {
    padding: '20px 0',
    borderTop: `1px solid ${theme.palette.secondary.dark}`,
  },

  QuestionText: {
    marginLeft: 5,
  },
}));
