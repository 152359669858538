export class StorageService {
  public static setAccessToken(token: string) {
    window.localStorage.setItem('accessToken', token);
  }

  public static setRefreshToken(token: string) {
    window.localStorage.setItem('refreshToken', token);
  }

  public static getAccessToken() {
    return window.localStorage.getItem('accessToken');
  }

  public static getRefreshToken() {
    return window.localStorage.getItem('refreshToken');
  }

  public static removeAccessToken() {
    window.localStorage.removeItem('accessToken');
  }

  public static removeRefreshToken() {
    window.localStorage.removeItem('refreshToken');
  }
}
