import React from 'react';
import { TableCell, TableCellProps } from '@material-ui/core';
import { useTableCvrCellStyles } from './TableCvrCellStyles';
import { ICompany } from '../../../../services/http/company/interfaces';
import { Confirmed } from '../../../../components/Confirmed';

interface ITableCvrCellProps {
  cvrData: ICompany['Cvr'] | undefined;
}

export const TableCvrCell = ({ cvrData, ...tableCellProps }: ITableCvrCellProps & TableCellProps) => {
  const classNames = useTableCvrCellStyles();

  if (!cvrData) {
    return <TableCell>No cvr</TableCell>;
  }

  return (
    <TableCell {...tableCellProps}>
      <div className={classNames.ConfirmedWrapper}>
        <div>{cvrData.Code}</div>
        <Confirmed type="Confirmed" isConfirmed={cvrData.IsConfirmed} imgClassName={classNames.ConfirmedImg} />
      </div>
    </TableCell>
  );
};
