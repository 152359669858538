import React from 'react';
import { EditPhotoGroup } from './Edit';
import { AddPhotoGroup } from './Add';
import { useFormikContext } from 'formik';

interface IPhotoGroup<Form> {
  fileFieldName: keyof Form;
  name: keyof Form;
  containerClassName: string;
}

export function PhotoGroup<Form>({ name, containerClassName, fileFieldName }: IPhotoGroup<Form>) {
  const { values } = useFormikContext<Form>();

  return values[name] || values[fileFieldName] ? (
    <EditPhotoGroup<Form> name={name} containerClassName={containerClassName} fileFieldName={fileFieldName} />
  ) : (
    <AddPhotoGroup<Form> fileFieldName={fileFieldName} containerClassName={containerClassName} />
  );
}
