import { makeStyles } from '@material-ui/core/styles';
import { Button, Checkbox, withStyles } from '@material-ui/core';

export const useCustomTheadStyles = makeStyles((theme) => ({
  TableRow: {
    background: theme.palette.background.paper,
  },
  TableCheckboxSell: {
    minWidth: 36,
    maxWidth: 36,
    width: 36,
    textOverflow: 'unset',
  },
  TableCell_selected: {
    width: '100%',
    height: '100%',
  },
  SelectedContainer: {
    marginTop: -11,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  TableRow_selected: {
    width: '100%',
    position: 'absolute',
    opacity: 0,
    zIndex: -1,
    top: 17,
    borderRadius: 8,
    background: theme.palette.primary.main,
  },
  SelectedNumber: {
    fontWeight: 'bold',
    fontSize: 14,
    display: 'inline',
    color: '#FFF',
  },
  DeleteButton: {
    marginRight: 40,
  },
  _show: {
    opacity: 1,
    zIndex: 3,
  },
  _hide: {
    opacity: 0,
    zIndex: -1,
  },
}));

export const CustomHeaderCheckbox = withStyles({
  root: {
    color: '#FFB002',
    '&$checked': {
      color: '#FFFFFF',
    },
  },
  checked: {},
})(Checkbox);

export const DeleteButton = withStyles((theme) => ({
  root: {
    height: 24,
    width: 70,
    borderRadius: 24,
    background: '#FFFF',
    fontWeight: 'bold',
    fontSize: 12,
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
}))(Button);
