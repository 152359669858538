import { makeStyles } from '@material-ui/core/styles';

export const useRepresentativesStyles = makeStyles({
  Representatives: {
    marginTop: 30,
    width: 750,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15,
  },
  Heading: {
    gridColumn: '1/3',
    display: 'flex',
    justifyContent: 'flex-start',
  },
});
