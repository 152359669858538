export enum GrantType {
  PASSWORD = 'password',
  REFRESH_TOKEN = 'refresh_token',
}

export enum AuthData {
  ClIENT_ID = 'admin',
  CLIENT_SECRET = '560f1f6ff7a7417ba2defdf98f2e70e6',
  SCOPE = 'adm public-api offline_access',
}

export enum AccountRole {
  CANDIDATE = 0,
  COMPANY = 1,
  OPERATOR = 8,
  ADMIN = 16,
  SYSTEM = 64,
}

export enum ErrorMessage {
  NO_RESPONSE = "Couldn't contact the server, please check your connection",
  EDIT_CONFLICT = "Sorry - Another user has edited this template while you've had it open. Please refresh or press back. Please note, all your changes will be lost.",
  UNABLE_TO_PROCESS_REQUEST = 'Unable to process request, please refresh your page and try again',
  SERVER_ERROR = 'There was a server error, please try again',
}

export enum QueryParams {
  PAGE = 'page',
  SORT_BY = 'sortBy',
  QUERY = 'query',
  SORT_BY_DESC = 'sortByDesc',
}

export enum CompaniesParams {
  EMPLOYEE_COUNT = 'employeeCount',
  INDUSTRY_ID = 'industryId',
  FROM_JOB_COUNT = 'fromJobCount',
  TO_JOB_COUNT = 'toJobCount',
  MODAL_VACANCY_MODE = 'mode',
  MODAL_VACANCY_ID = 'modalVacancyId',
  APPLICANTS_STATUS = 'applicantsStatus',
}

export enum CandidatesParams {
  AGE = 'age',
  STATUS = 'status',
  EMPLOYEE_AGE_FROM = 'employeeAgeFrom',
  EMPLOYEE_AGE_TO = 'employeeAgeTo',
  CREATED_DATE_FROM = 'createdFrom',
  CREATED_DATE_TO = 'createdTo',
  LATITUDE = 'lat',
  LONGITUDE = 'lon',
  IS_CITY = 'isCity',
  EXPERIENCE_CATEGORY = 'experienceCategory',
  // COUNTRY = 'country',
  // CITY = 'city',
}

export enum JobsParams {
  CATEGORY_ID = 'categoryId',
  STATUS = 'status',
  PUBLISH_DATE_FROM = 'publishDateFrom',
  PUBLISH_DATE_TO = 'publishDateTo',
}

export enum ReportsParams {
  REPORT_TYPE = 'reportType',
  REPORT_ID = 'reportId',
}

export enum ReportStatus {
  NEW,
  READ,
  RESOLVED,
}

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}
