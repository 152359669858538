import React, { useCallback, useState } from 'react';
import { useLoadScript, GoogleMap } from '@react-google-maps/api';
import { HttpService } from 'services/http/axios';
import { Loading } from 'components/Loading';
import { IGeoCity } from 'services/http/geo/interfaces';
import { PolygonWrapper } from './PolygonWrapper';
import PolygonNewWrapper from './PolygonNewWrapper';

const defaultCoordinates = {
  lat: 55.41161608440075,
  lng: 10.38474775668325,
};
const containerStyle = {
  width: '700px',
  height: '500px',
};

const mapOptions: google.maps.MapOptions = {
  controlSize: 20,
  maxZoom: 13,
  fullscreenControl: false,
  gestureHandling: 'greedy',
};

interface ICitiesMapWrapperProps {
  cities: IGeoCity[];
  setHovered: (poligonId?: string) => void;
  hoveredId?: string;
  setEditable: (poligonId?: string) => void;
  editableId?: string;
  addPolygon: (cityId: string, polygon: google.maps.Polygon) => void;
  isNew: boolean;
  removePolygon: (cityId: string) => void;
}

export const CitiesMapWrapper = ({
  cities,
  hoveredId,
  setHovered,
  editableId,
  setEditable,
  addPolygon,
  isNew,
  removePolygon,
}: ICitiesMapWrapperProps) => {
  const { isLoaded: googleMapLoaded } = useLoadScript({
    googleMapsApiKey: HttpService.env.gmapsAPI,
  });

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const onLoad = useCallback(
    (map: google.maps.Map) => {
      setMap(map);
    },
    [window.google, location]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [setMap]);

  if (!googleMapLoaded) {
    return <Loading />;
  }

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      options={mapOptions}
      zoom={7}
      onLoad={onLoad}
      onUnmount={onUnmount}
      center={defaultCoordinates}
    >
      {cities.map((city) => (
        <PolygonWrapper
          key={city.Id}
          city={city}
          hoveredId={hoveredId}
          setHovered={setHovered}
          editableId={editableId}
          setEditable={setEditable}
          addPolygon={addPolygon}
          removePolygon={removePolygon}
        />
      ))}
      {isNew && map && <PolygonNewWrapper map={map} addPolygon={addPolygon} removePolygon={removePolygon} />}
    </GoogleMap>
  );
};
