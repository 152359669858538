import React, { useCallback, useEffect, useState } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';
import { ICheckboxState } from '../../intefaces';
import useAxios from 'axios-hooks';
import { ICategory } from '../../services/http/interfaces';
import { useSelectedFiltersContext } from '../../utils/selectedFiltersContext';
import { useQuery } from '../../utils/useQuery';
import { getFilteredCheckboxStateIds } from '../../utils/filterCheckboxState';
import { QueryParams } from '../../enums';
import { FilterItemLayout } from '../../layouts/Home/Filters/FilterItemLayout';
import { StyledCheckbox } from '../ui/StyledCheckbox';
import { Loading } from '../Loading';

interface ICategoriesFilter {
  title: string;
  queryParam: string;
  fetchCategoriesUrl: string;
}

// Component is specifically for table layout pages
export const CategoriesFilter = ({ title, queryParam, fetchCategoriesUrl }: ICategoriesFilter) => {
  const [checkedCategoriesState, setCheckedCategoriesState] = useState<ICheckboxState>({});
  const { currQueryParams, removeParams, setParamArr, setParam } = useQuery();

  const [{ data: categories, loading }] = useAxios<ICategory[]>(fetchCategoriesUrl);

  const selectedFiltersContext = useSelectedFiltersContext();

  const removeIndustriesFilter = useCallback(() => {
    selectedFiltersContext.remove(title);
    removeParams([queryParam]);
  }, [removeParams, selectedFiltersContext]);

  const applyIndustriesFilter = useCallback(() => {
    const checkedIndustriesIds = getFilteredCheckboxStateIds(checkedCategoriesState);

    if (checkedIndustriesIds.length === 0) {
      removeIndustriesFilter();

      return;
    }

    setParamArr(queryParam, checkedIndustriesIds);
    selectedFiltersContext.set({
      label: title,
      params: [queryParam],
    });
    setParam(QueryParams.PAGE, '1');
  }, [currQueryParams, setParamArr, checkedCategoriesState]);

  const checkboxOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      setCheckedCategoriesState((prevState) => ({ ...prevState, [id]: event.target.checked }));
    },
    [setCheckedCategoriesState]
  );
  useEffect(() => {
    const industryIds = currQueryParams.getAll(queryParam);

    if (industryIds.length === 0) {
      return setCheckedCategoriesState({});
    }

    const newCheckedIndustriesState: ICheckboxState = {};
    industryIds.forEach((el) => {
      newCheckedIndustriesState[el] = true;
    });
    setCheckedCategoriesState(newCheckedIndustriesState);
    selectedFiltersContext.set({
      label: title,
      params: [queryParam],
    });
  }, [currQueryParams, setCheckedCategoriesState, selectedFiltersContext]);

  return (
    <FilterItemLayout title={title} applyHandler={applyIndustriesFilter}>
      {!loading ? (
        <FormGroup>
          {categories?.map(({ Id, Name }) => (
            <FormControlLabel
              key={Id}
              checked={Boolean(checkedCategoriesState[Id])}
              control={<StyledCheckbox />}
              label={
                <Typography variant="subtitle1" component="div" style={{ maxWidth: 146, overflowWrap: 'break-word' }}>
                  {Name}
                </Typography>
              }
              onChange={(event) => checkboxOnChange(event as React.ChangeEvent<HTMLInputElement>, Id)}
            />
          ))}
        </FormGroup>
      ) : (
        <Loading size="2em" />
      )}
    </FilterItemLayout>
  );
};
