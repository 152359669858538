import { makeStyles } from '@material-ui/core/styles';

export const useInfoStyles = makeStyles({
  InfoContainer: {
    paddingBottom: 20,
    paddingTop: 20,
  },
  ConfirmedImg: {
    marginRight: 4,
  },
  LastSeenWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
});
