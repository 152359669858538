import React, { useCallback } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { useNotificationStyles, StyledAlert } from './NotificationStyles';

type INotificationSeverity = 'error' | 'warning' | 'info' | 'success';

interface INotificationState {
  text: string;
  severity: INotificationSeverity;
  index: number;
  onAlertClose: () => void;
}

export const Notification = ({ text, severity, index, onAlertClose }: INotificationState) => {
  const classNames = useNotificationStyles(index);

  const handleClose = useCallback(
    (event: React.SyntheticEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }

      onAlertClose();
    },
    [onAlertClose]
  );

  return (
    <Snackbar open={true} onClose={handleClose} classes={{ root: classNames.Alert }} autoHideDuration={3000}>
      <StyledAlert variant="filled" onClose={handleClose} severity={severity}>
        {text}
      </StyledAlert>
    </Snackbar>
  );
};
