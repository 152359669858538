import React, { useCallback, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { StyledSaveButton } from '../../../../components/ui/SaveButton';
import { useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { IEditCompanyDetails, IGetCompanyDetails } from '../../../../services/http/company/interfaces';
import { useDetailsFormStyles, useInnerFormStyles } from './DetailsFormStyles';
import { HttpService } from '../../../../services/http/axios';
import { numberOfEmployeesRanges } from '../../../Companies/Filters/NumberOfEmployees';
import { FormCVR } from './FormCvr';
import { Loading } from '../../../../components/Loading';
import { getFormInput, getFormSelect, getFormTextArea } from '../../../../components/FormComponents/getFormInput';
import { LocationAutoComplete } from '../../../../components/FormComponents/GoogleMapComponents/AutoComplete';
import { IGeoLocation } from '../../../../services/http/geo/interfaces';
import { PhotoGroup } from '../../../../components/FormComponents/PhotoGroup';
import { CompaniesService } from '../../../../services/http/company';
import { formSubmitCatchAsync } from '../../../../utils/submitFormCatchAsync';
import { StyledCancelButton } from '../../../../components/ui/CancelButton';
import { useHistory } from 'react-router';
import { useConfirm } from 'material-ui-confirm';
import { validateEditCompanyDetailsData } from '../../../../utils/validations/validateEditCompanyDetails';
import { FsService } from '../../../../services/http/fsService';
import { useNotificationContext } from '../../../../utils/notificationContext';
import { ICategory } from '../../../../services/http/interfaces';

const FormInput = getFormInput<ICompanyDetailsForm>();
const FormSelect = getFormSelect<ICompanyDetailsForm>();
const FormTextArea = getFormTextArea<ICompanyDetailsForm>();

export interface ICompanyDetailsForm {
  newLogoFile?: File | null;
  logo?: string;
  companyName: string;
  industry?: string;
  numberOfEmployees?: string;
  cvrCode: string;
  cvrConfirmed?: boolean;
  location?: IGeoLocation;
  description: string;
}

const initialValues: ICompanyDetailsForm = {
  logo: '',
  numberOfEmployees: '',
  companyName: '',
  cvrCode: '',
  industry: '',
  description: '',
};

const getRequestBody = async (formData: ICompanyDetailsForm | undefined): Promise<IEditCompanyDetails | undefined> => {
  if (!formData) {
    return;
  }

  const Cvr =
    formData.cvrCode !== '' && formData.cvrConfirmed !== undefined
      ? {
          Code: formData.cvrCode,
          IsConfirmed: formData.cvrConfirmed,
        }
      : undefined;

  let Logo = formData.logo;

  if (formData.newLogoFile) {
    const { Uri } = await FsService.uploadFile(formData.newLogoFile);
    Logo = Uri;
  }

  return {
    Logo,
    Name: formData.companyName,
    Industry: !formData.industry ? undefined : { Id: formData.industry },
    EmployeeCount: formData.numberOfEmployees === '' ? undefined : formData.numberOfEmployees,
    Cvr,
    Location: formData.location && {
      City: formData.location.City,
      Country: formData.location.Country,
      Latitude: formData.location.Latitude,
      Longitude: formData.location.Longitude,
    },
    Description: formData.description,
  };
};

const getInitialValues = (companyDetails: IGetCompanyDetails | undefined): ICompanyDetailsForm => {
  if (!companyDetails) {
    return initialValues;
  }

  return {
    logo: companyDetails.Logo,
    companyName: companyDetails.Name,
    industry: companyDetails?.Industry?.Id || initialValues.industry,
    numberOfEmployees: companyDetails?.EmployeeCount || initialValues.numberOfEmployees,
    cvrCode: companyDetails?.Cvr?.Code || initialValues.cvrCode,
    cvrConfirmed: companyDetails?.Cvr?.IsConfirmed || initialValues.cvrConfirmed,
    location: companyDetails.Location,
    description: companyDetails.Description,
  };
};

export const DetailsForm = () => {
  const { companyId: id } = useParams<{ companyId: string }>();
  const history = useHistory();
  const confirm = useConfirm();
  const classNames = useDetailsFormStyles();
  const [{ data: companyDetails, loading: detailsLoading }] = useAxios<IGetCompanyDetails>(
    `${HttpService.env.adminAPI}/companies/${id}`,
    { useCache: false }
  );
  const { setNotification } = useNotificationContext();

  const initialValues = useMemo(() => getInitialValues(companyDetails), [companyDetails]);

  const submitHandler = useCallback(
    formSubmitCatchAsync<ICompanyDetailsForm>(
      async (formData: ICompanyDetailsForm | undefined, formikHelpers: FormikHelpers<ICompanyDetailsForm>) => {
        const requestBody = await getRequestBody(formData);

        if (!requestBody) {
          formikHelpers.setStatus('No data was provided');
          formikHelpers.setSubmitting(false);

          return;
        }

        await CompaniesService.editCompanyDetails(id, requestBody);

        formikHelpers.setSubmitting(false);
        setNotification({ text: `${requestBody.Name} company was successfully updated`, severity: 'success' });
        history.push(`/company/${id}`);
      }
    ),
    [id]
  );

  const cancelHandler = useCallback(() => {
    confirm({ description: 'Data will be lost, are you sure you want to cancel current changes?' })
      .then(() => history.push(`/company/${id}`))
      .catch(() => console.log('error'));
  }, [history]);

  if (detailsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h1" className={classNames.Title}>
        Edit Company Profile
      </Typography>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validateEditCompanyDetailsData}>
        {(props) => <InnerForm {...props} cancelHandler={cancelHandler} />}
      </Formik>
    </>
  );
};

interface IInnerFormProps {
  cancelHandler: () => void;
}

const InnerForm = ({ status, isSubmitting, cancelHandler }: FormikProps<ICompanyDetailsForm> & IInnerFormProps) => {
  const classNames = useInnerFormStyles();
  const [{ data: industries }] = useAxios<ICategory[]>(`${HttpService.env.adminAPI}/companies/categories`);

  const industryOptions = industries?.map((el) => ({ value: el.Id, label: el.Name })) || [];
  const numberOfEmployeesOptions = numberOfEmployeesRanges.map((el) => ({ value: el, label: el }));

  return (
    <Form className={classNames.InnerForm}>
      <PhotoGroup<ICompanyDetailsForm>
        name="logo"
        fileFieldName="newLogoFile"
        containerClassName={classNames.AddEditContainer}
      />
      <FormInput label={'Company Name'} name={'companyName'} />
      <FormSelect label={'Industry'} name={'industry'} options={industryOptions} placeholder="Select industries" />
      <FormSelect
        label={'Number of employees'}
        name={'numberOfEmployees'}
        options={numberOfEmployeesOptions}
        placeholder="Select number of employees"
      />
      <FormCVR />

      <LocationAutoComplete inputClassName={classNames._full} mapClassName={classNames.MapContainerClassName} />

      <FormTextArea name="description" label="Description" placeholder="Description" />

      {status && (
        <Alert className={classNames._full} severity="error">
          {status}
        </Alert>
      )}

      <div className={classNames.CancelSubmitContainer}>
        <StyledCancelButton className={classNames.CancelButton} onClick={cancelHandler}>
          Cancel
        </StyledCancelButton>
        <StyledSaveButton type="submit">{isSubmitting ? <Loading /> : 'Save'}</StyledSaveButton>
      </div>
    </Form>
  );
};
