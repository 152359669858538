import { makeStyles } from '@material-ui/core/styles';
import filterArrows from '../../../../assets/filterArrows.svg';
import downArrow from '../../../../assets/downArrow.svg';

export const useTextHeadCellSortStatusStyles = makeStyles({
  _sortable: {
    display: 'flex',
    flexWrap: 'nowrap',
    cursor: 'pointer',
    '&:after': {
      display: 'inline-block',
      marginLeft: 7,
      transformOrigin: '50% 50%',
      transform: 'scale(1.2)',
    },
  },
  _default: {
    cursor: 'pointer',
    '&:after': {
      content: `url(${filterArrows})`,
    },
  },
  _asc: {
    '&:after': {
      content: `url(${downArrow})`,
      transition: '0.5s ease',
      transform: 'rotate(0) scale(1.5)',
    },
  },
  _desc: {
    cursor: 'pointer',
    '&:after': {
      content: `url(${downArrow})`,
      transition: '0.5s ease',
      transform: 'rotate(180deg) scale(1.5)',
    },
  },
});
