import { makeStyles } from '@material-ui/core/styles';

export const useCompanyVacanciesStyles = makeStyles({
  TableTitle: {
    margin: '20px 0',
  },
  _clickable: {
    cursor: 'pointer',
  },
  EditButton: {
    padding: 2,
    width: 14,
    height: 14,
  },
});
