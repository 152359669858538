import { Checkbox, withStyles } from '@material-ui/core';

export const StyledCheckbox = withStyles({
  root: {
    padding: 6,
    color: '#C7D2E6',
    '&$checked': {
      color: '#FFB002',
    },
  },
  checked: {},
})(Checkbox);
