import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

export function useRowClickHandler() {
  const history = useHistory();

  return useCallback((event: React.MouseEvent<HTMLTableRowElement>, to: string) => {
    event.stopPropagation();

    const target = event.target as HTMLElement;

    if (target.tagName === 'INPUT' || target.tagName === 'A') {
      return;
    }

    history.push(to);
  }, []);
}
