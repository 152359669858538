import { makeStyles } from '@material-ui/core/styles';

export const useHomePageStyles = makeStyles((theme) => ({
  HomeLayout: {
    display: 'flex',
    minHeight: '100vh',
    minWidth: 1350,
  },
  VerticalBorder: {
    display: 'block',
    content: '""',
    width: 1,
    minHeight: '100%',
    background: theme.palette.secondary.dark,
  },
}));
