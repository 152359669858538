import React, { useCallback } from 'react';
import { useEditPhotoGroupStyles } from './EditPhotoGroupStyles';
import { UploadInput } from '../UploadInput';
import { useFormikContext } from 'formik';
import { Preview } from '../Preview';

interface IEditPhotoGroupProps<Form> {
  fileFieldName: keyof Form;
  name: keyof Form;
  containerClassName?: string;
}

export function EditPhotoGroup<Form>({ fileFieldName, name, containerClassName }: IEditPhotoGroupProps<Form>) {
  const { setFieldValue, values } = useFormikContext<Form>();
  const classNames = useEditPhotoGroupStyles();
  const file = values[fileFieldName];

  const handleUploadedFile = useCallback(
    (file: File | null) => {
      setFieldValue(`${fileFieldName}`, file);
    },
    [setFieldValue, name]
  );

  const handleRemovePhoto = useCallback(() => {
    setFieldValue(`${name}`, '');
    setFieldValue(`${fileFieldName}`, undefined);
  }, [setFieldValue, name]);

  return (
    <section className={`${classNames.GroupContainer} ${containerClassName ? containerClassName : ''}`}>
      <div className={classNames.Photo}>
        {isFile(file) ? (
          <Preview className={classNames.PhotoImg} file={file} />
        ) : (
          <img className={classNames.PhotoImg} src={`${values[name]}`} />
        )}
        <i className={classNames.TrashIcon} onClick={handleRemovePhoto} />
      </div>
      <UploadInput className={classNames.UploadButtonPositioning} handleUploadedFile={handleUploadedFile} />
    </section>
  );
}

function isFile(file: any): file is File {
  if (!file) {
    return false;
  }

  return file instanceof File;
}
