import React from 'react';
import { Typography } from '@material-ui/core';
import { usePageTitleWithCountStyles } from './PageTitleWithCountStyles';

interface IPageTitle {
  title: string;
  itemsCount?: number;
}

export const PageTitleWithCount = ({ title, itemsCount }: IPageTitle) => {
  const classNames = usePageTitleWithCountStyles();

  return (
    <Typography variant="h1">
      {title} {!!itemsCount && <span className={classNames.Count}>({itemsCount})</span>}
    </Typography>
  );
};
