import { makeStyles } from '@material-ui/core/styles';

export const useTableCvrCellStyles = makeStyles({
  ConfirmedWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    background: '#FFFFFF',
  },

  ConfirmedContainer: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
  },
  ConfirmedImg: {
    marginRight: 4,
  },
});
