export function generatePages(numberOfPages: number, current: number, maxSize: number): IPageNumber[] {
  const arr = new Array(maxSize);
  const lastIndex = maxSize - 1;

  if (numberOfPages === 1) {
    return getFormattedPagesArr([1]);
  }

  if (numberOfPages <= maxSize) {
    const lastIndexOfSmallArr = numberOfPages - 1;
    const smallArr = new Array(numberOfPages);

    for (let i = 0; i <= lastIndexOfSmallArr; i++) {
      smallArr[i] = i + 1;
    }

    return getFormattedPagesArr(smallArr);
  }

  if (current + maxSize / 2 >= numberOfPages) {
    for (let i = 0; i < lastIndex; i++) {
      arr[lastIndex - i] = numberOfPages - i;
    }

    arr[0] = 1;
    arr[1] = '...';
    arr[lastIndex] = numberOfPages;

    return getFormattedPagesArr(arr);
  }

  if (current <= maxSize - 3) {
    for (let i = 0; i <= maxSize - 3; i++) {
      arr[i] = i + 1;
    }

    arr[lastIndex] = numberOfPages;
    arr[lastIndex - 1] = '...';

    return getFormattedPagesArr(arr);
  }

  arr[1] = '...';
  arr[lastIndex - 1] = '...';
  arr[0] = 1;
  arr[lastIndex] = numberOfPages;

  for (let i = 2; i < maxSize - 2; i++) {
    arr[i] = current - Math.floor(maxSize / 2) + i;
  }

  return getFormattedPagesArr(arr);
}

interface IPageNumber {
  text: string;
  disabled: boolean;
}

function getFormattedPagesArr(arr: (string | number)[]): IPageNumber[] {
  return arr.map((el) =>
    typeof el === 'string'
      ? {
          text: el,
          disabled: true,
        }
      : {
          text: `${el}`,
          disabled: false,
        }
  );
}
