import React, { useCallback, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { useProfileStyles } from './ProfileStyles';
import { useHistory } from 'react-router';
import { IProfileReduxProps } from '../../../containers/Profile';
import { Loading } from '../../../components/Loading';
import { useSelector } from 'react-redux';
import { IRootState } from 'intefaces';
// import { Avatar } from 'components/Avatar';

export const Profile = ({ logOut }: IProfileReduxProps) => {
  const classNames = useProfileStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>();
  const history = useHistory();
  const profileData = useSelector((state: IRootState) => state.user.user);

  const handleMenuButtonClick = useCallback(() => {
    setIsMenuOpen((prevIsMenuOpen) => !prevIsMenuOpen);
  }, []);

  const handleClose = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const signOut = useCallback(() => {
    logOut();
    history.push('/signin');
  }, []);

  return (
    <div className={classNames.ProfileWrapper}>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Button
            buttonRef={anchorRef}
            // TODO profile URL
            // startIcon={<Avatar src={testAvatar} alt={'example@gmail.com'} size="_sm" />}
            onClick={handleMenuButtonClick}
            className={classNames.MenuButton}
            disabled={!profileData}
          >
            {!profileData ? <Loading size={22} /> : profileData?.Email}
          </Button>
          <Popper open={isMenuOpen} transition anchorEl={anchorRef.current}>
            <MenuList className={classNames.MenuList} autoFocusItem={isMenuOpen} id="menu-list-grow">
              <MenuItem className={classNames.MenuItem} onClick={signOut}>
                Sign Out
              </MenuItem>
            </MenuList>
          </Popper>
        </div>
      </ClickAwayListener>
    </div>
  );
};
