import { makeStyles } from '@material-ui/core/styles';

export const useFiltersHideButtonStyles = makeStyles((theme) => ({
  BackArrowButton: {
    position: 'absolute',
    cursor: 'pointer',
    left: '100%',
    top: 30,
    transform: 'translateX(-50%)',
    background: theme.palette.background.default,
    borderRadius: '50% 50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    zIndex: 2,
  },
  BackArrow: {
    height: '13px',
    width: '16px',
  },
}));
