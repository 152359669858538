import React from 'react';
import confirmedImg from '../../assets/verified.svg';
import { useConfirmedStyles, useConfirmedImgStyles } from './ConfirmedStyles';
import notVerifiedImg from '../../assets/notVerified.svg';
import { Typography } from '@material-ui/core';

interface IConfirmedProps {
  type: 'Confirmed' | 'Verified';
  isConfirmed: boolean;
  className?: string;
  imgClassName?: string;
}

export const Confirmed = ({ isConfirmed, type, className, imgClassName }: IConfirmedProps) => {
  const classNames = useConfirmedStyles();

  return (
    <span
      className={`${classNames.Container} ${type === 'Verified' ? classNames._verified : ''} ${
        className ? className : ''
      }`}
    >
      <ConfirmedImg isConfirmed={isConfirmed} className={imgClassName} />
      <Typography color="textSecondary" variant="subtitle2">
        {isConfirmed ? type : `Not ${type}`}
      </Typography>
    </span>
  );
};

interface IConfirmedImg {
  className?: string;
  isConfirmed: boolean;
}

export const ConfirmedImg = ({ isConfirmed, className }: IConfirmedImg) => {
  const classNames = useConfirmedImgStyles();

  return (
    <img
      className={`${className ? className : ''} ${classNames.Img} ${
        isConfirmed ? classNames.CvrImg_Confirmed : classNames.CvrImg_NotConfirmed
      }`}
      src={isConfirmed ? confirmedImg : notVerifiedImg}
    />
  );
};
