import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useBackdropContainerStyles } from './BackdropContainerStyles';

interface IBackdropContainerProps {
  containerClassName?: string;
  loading: boolean;
  children: React.ReactNode;
}

export const BackdropContainer = ({ containerClassName, loading, children }: IBackdropContainerProps) => {
  const classNames = useBackdropContainerStyles();

  return (
    <div className={`${classNames.LoadingContainer} ${containerClassName ? containerClassName : ''}`}>
      <Backdrop className={classNames.Loading} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </div>
  );
};
