import React, { useCallback } from 'react';
import { useSelectedFiltersStyles, CustomCloseIcon } from './SelectedFiltersStyles';
import { useSelectedFiltersContext } from '../../../../utils/selectedFiltersContext';
import { useQuery } from '../../../../utils/useQuery';

export const SelectedFilters = () => {
  const classNames = useSelectedFiltersStyles();
  const selectedFiltersContext = useSelectedFiltersContext();
  const { removeParams } = useQuery();
  const allFilters = selectedFiltersContext.getFilters();

  const clearAllHandler = useCallback(() => {
    removeParams(allFilters.map((el) => el.params).flat());
    allFilters.forEach((el) => selectedFiltersContext.remove(el.label));
  }, [selectedFiltersContext, allFilters]);

  return (
    <section className={classNames.SelectedFilters}>
      <span className={classNames.Title}>Selected filters:</span>
      <div className={classNames.FilterItemsContainer}>
        {allFilters.map((el, index) => (
          <div className={classNames.FilterItem} key={index + el.label}>
            {el.label}
            <CustomCloseIcon
              onClick={() => {
                removeParams(el.params);
                selectedFiltersContext.remove(el.label);
              }}
            />
          </div>
        ))}
      </div>
      <span className={classNames.ClearAll} onClick={clearAllHandler}>
        Clear filters
      </span>
    </section>
  );
};
