import { IGeoPlace, IGeoLocation, IGeoCity } from './interfaces';
import { HttpService } from '../axios';
import { ILocation } from '../company/interfaces';

export class GeoService {
  public static async getPlaces(place: string): Promise<IGeoPlace[]> {
    try {
      return HttpService.get<IGeoPlace[]>(`${HttpService.env.geoAPI}/place`, {
        params: { query: place },
      });
    } catch (error) {
      return [];
    }
  }

  public static getPlaceLocation(place: IGeoPlace) {
    return HttpService.get<IGeoLocation>(`${HttpService.env.geoAPI}/place/${place.Id}`);
  }

  public static async getLocationByCoordinates(location: ILocation) {
    const res = await HttpService.get<IGeoLocation>(`${HttpService.env.geoAPI}/location`, {
      params: {
        lat: location.Latitude,
        lon: location.Longitude,
      },
    });

    return { ...res, ...location };
  }

  public static async updateGeoCity(city: IGeoCity) {
    return HttpService.put<IGeoCity>(`${HttpService.env.searchAPI}/geo/cities/${city.Id}`, city);
  }

  public static async createGeoCity(city: IGeoCity) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { Id, ...data } = city;

    return HttpService.post<IGeoCity>(`${HttpService.env.searchAPI}/geo/cities`, data);
  }

  public static async deleteGeoCity(cityId: string) {
    return HttpService.delete<void>(`${HttpService.env.searchAPI}/geo/cities/${cityId}`);
  }
}
