import { makeStyles } from '@material-ui/core/styles';
import dropdown from '../../../../assets/dropdown.svg';

export const useTableMessageOfCellStyles = makeStyles({
  TableCell: {
    maxHeight: 500,
    whiteSpace: 'normal',
    wordBreak: 'break-all',
  },
  Collapse: {
    maxWidth: 270,
    padding: '0 19px 19px 0',
    marginRight: 25,
    position: 'relative',
    cursor: 'pointer',
    '&::before': {
      display: 'block',
      content: `url(${dropdown})`,
      position: 'absolute',
      right: 0,
      width: 10,
      height: 10,
      transition: 'all .5s ease',
    },
  },
  _expanded: {
    '&::before': {
      transform: 'rotate(180deg) translateY(-100%)',
    },
  },
});
