import React from 'react';
import { Table, TableContainer, Typography } from '@material-ui/core';
import { useTableLayoutStyles } from './TableStyles';
import { CustomTableFooter } from './TableFooter';
import { BackdropContainer } from '../../components/BackdropContainer';

interface ITableLayoutProps {
  children: JSX.Element[];
  numberOfPages?: number;
  currentPage?: number;
  loading: boolean;
  isEmpty: boolean;
  noItemsMessage: string;
}

export const TableLayout = ({
  children,
  numberOfPages,
  currentPage,
  loading,
  isEmpty,
  noItemsMessage,
}: ITableLayoutProps) => {
  const classNames = useTableLayoutStyles();

  return (
    <BackdropContainer loading={loading}>
      <TableContainer className={classNames.TableContainer}>
        <Table className={classNames.TableLayout}>{children}</Table>
        {!!numberOfPages && currentPage && (
          <CustomTableFooter numberOfPages={numberOfPages} currentPage={currentPage} />
        )}

        {isEmpty && !loading && (
          <Typography className={classNames.NoItemsFound} variant="h2">
            {noItemsMessage}
          </Typography>
        )}
      </TableContainer>
    </BackdropContainer>
  );
};
