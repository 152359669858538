import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import uploadIcon from '../../../assets/upload.svg';

interface IUploadInputProps {
  handleUploadedFile: (file: File | null) => void;
  className?: string;
}

export const UploadInput = ({ handleUploadedFile, className }: IUploadInputProps) => {
  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = (event.target.files || [])[0];

    if (!file || file.size > 1e8) {
      return;
    }

    handleUploadedFile(file);
  }, []);

  return (
    <Button className={className} variant="outlined" component="label" startIcon={<img src={uploadIcon} />}>
      Upload
      <input type="file" hidden onChange={handleChange} />
    </Button>
  );
};
