import { makeStyles } from '@material-ui/core/styles';

export const useCustomModalListStyles = makeStyles({
  Title: {
    height: 50,
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  CloseButton: {
    position: 'absolute',
    top: 0,
    right: 4,
    cursor: 'pointer',
    width: 24,
    height: 24,
  },
  ModalBody: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '40%',
    background: '#FFF',
    outline: 'none',
    padding: 10,
  },
});
