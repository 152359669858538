import React, { useCallback } from 'react';
import { Field, useField } from 'formik';
import { useFormInputStyles } from './FormInputStyles';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputBaseProps,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
} from '@material-ui/core';
import { ErrorOutput } from './ErrorOutput';
import { StyledInput } from '../../ui/StyledInput';

interface IInputProps<FormType> {
  label?: string;
  name: (keyof FormType & string) | string;
  placeholder?: string;
  inputClassName?: string;
  wrapperClassName?: string;
}

// --------------------- CustomFormInput
export function getFormInput<FormType>() {
  const CustomFormInput = (props: IInputProps<FormType> & InputBaseProps) => <FormInput<FormType> {...props} />;

  return CustomFormInput;
}

function FormInput<FormType>({
  label,
  name,
  inputClassName,
  wrapperClassName,
  fullWidth,
  ...inputProps
}: IInputProps<FormType> & InputBaseProps) {
  return (
    <FormControl classes={{ root: wrapperClassName }} fullWidth={fullWidth}>
      <FormLabel>{label}</FormLabel>
      <Field {...inputProps} className={inputClassName ?? ''} as={StyledInput} name={name} />
      <ErrorOutput<FormType> name={name} />
    </FormControl>
  );
}

// --------------------- CustomFormSelect
interface IFormSelectProps {
  options: { value: string; label: string }[];
}

export function getFormSelect<FormType>() {
  const CustomFormSelect = (props: IInputProps<FormType> & IFormSelectProps & InputBaseProps) => (
    <FormSelect<FormType> {...props} />
  );

  return CustomFormSelect;
}

function FormSelect<FormType>({
  label,
  name,
  options,
  wrapperClassName,
  fullWidth,
  placeholder,
}: IInputProps<FormType> & IFormSelectProps & InputBaseProps) {
  return (
    <FormControl classes={{ root: wrapperClassName }} fullWidth={fullWidth}>
      <FormLabel>{label}</FormLabel>
      <Field as={Select} name={name} input={<StyledInput />} placeholder={placeholder}>
        {options.map((el) => (
          <MenuItem key={el.value} value={el.value}>
            {el.label}
          </MenuItem>
        ))}
      </Field>
      <ErrorOutput<FormType> name={name} />
    </FormControl>
  );
}

// --------------------- CustomFormTextArea
export function getFormTextArea<FormType>() {
  const CustomFormTextArea = (props: IInputProps<FormType> & InputBaseProps) => <FormTextArea<FormType> {...props} />;

  return CustomFormTextArea;
}

function FormTextArea<FormType>({
  label,
  name,
  placeholder,
  inputClassName,
  wrapperClassName,
  fullWidth,
  ...inputProps
}: IInputProps<FormType> & InputBaseProps) {
  const classNames = useFormInputStyles();

  return (
    <FormControl classes={{ root: wrapperClassName }} fullWidth={fullWidth}>
      <FormLabel>{label}</FormLabel>
      <Field
        as={TextareaAutosize}
        rowsMin={3}
        className={`${classNames.TextArea} ${inputClassName ?? ''}`}
        name={name}
        placeholder={placeholder}
        {...inputProps}
      />
      <ErrorOutput<FormType> name={name} />
    </FormControl>
  );
}

// --------------------- CustomFormRadioGroup
interface IFormRadioGroup {
  options: { value: string; label: string }[];
}

export function getFormRadioGroup<FormType>() {
  const CustomFormRadioGroup = (props: IInputProps<FormType> & InputBaseProps & IFormRadioGroup) => (
    <FormRadioGroup<FormType> {...props} />
  );

  return CustomFormRadioGroup;
}

function FormRadioGroup<FormType>({
  options,
  label,
  name,
  disabled,
}: IInputProps<FormType> & InputBaseProps & IFormRadioGroup) {
  const [field] = useField(name);

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <RadioGroup row {...field}>
        {options.map((o) => (
          <FormControlLabel
            key={o.value}
            value={o.value}
            control={<Radio color="primary" />}
            label={o.label}
            disabled={disabled}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

// --------------------- CustomJobTypeField
export function getJobTypeField<FormType>() {
  const CustomJobTypeField = (props: IInputProps<FormType> & InputBaseProps) => <JobTypeField<FormType> {...props} />;

  return CustomJobTypeField;
}

function JobTypeField<FormType>({ name, label }: IInputProps<FormType> & InputBaseProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField<string | null>(name);
  const { value } = field;
  const handleChange = useCallback(
    (key: string) => {
      return (_event: any, val: boolean) => {
        const parsedValue = value !== null ? parseInt(value) : 0;
        const newValue = val ? (parsedValue || 0) + parseInt(key) : parsedValue - parseInt(key);

        if (!newValue) {
          setValue(null, true);

          return;
        }

        setValue(newValue.toString(), true);
      };
    },
    [value, setValue]
  );

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup row>
        {jobTypeOptions.map((o) => {
          return (
            <FormControlLabel
              key={o.value}
              value={o.value}
              label={o.label}
              onChange={handleChange(o.value)}
              control={
                <Checkbox color="primary" checked={[o.value, '3'].includes((value && value.toString()) || '')} />
              }
            />
          );
        })}
      </FormGroup>
      <ErrorOutput<FormType> name={name} />
    </FormControl>
  );
}

// --------------------- CustomLngCheckboxField
export function getLngCheckboxField<FormType>() {
  const CustomLngCheckboxField = (props: IInputProps<FormType> & InputBaseProps) => (
    <LngCheckboxField<FormType> {...props} />
  );

  return CustomLngCheckboxField;
}

function LngCheckboxField<FormType>({ name, label }: IInputProps<FormType> & InputBaseProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, { setValue }] = useField<string[]>(name);
  const { value } = field;

  const handleCheck = useCallback(
    (language?: string) => {
      if (!language) {
        setValue([]);

        return;
      }

      const newLanguages = value.includes(language) ? value.filter((lng) => lng !== language) : [...value, language];
      setValue(newLanguages);
    },
    [value]
  );

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup row>
        {lngOptions.map((o) => {
          return (
            <FormControlLabel
              key={o.value}
              value={o.value}
              label={o.label}
              onChange={() => {
                handleCheck(o.value);
              }}
              control={<Checkbox color="primary" checked={value.includes(o.value)} />}
            />
          );
        })}
        <FormControlLabel
          label="Doesn't matter"
          value={''}
          onChange={() => handleCheck()}
          control={<Checkbox color="primary" checked={!value.length} />}
        />
      </FormGroup>
    </FormControl>
  );
}

export const lngOptions = [
  {
    value: 'english',
    label: 'English',
  },
  {
    value: 'danish',
    label: 'Danish',
  },
  {
    value: 'german',
    label: 'German',
  },
];

export const jobTypeOptions = [
  {
    value: '1',
    label: 'Full-time',
  },
  {
    value: '2',
    label: 'Part-time',
  },
];
