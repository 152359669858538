import { makeStyles } from '@material-ui/core/styles';

export const useSideBarStyles = makeStyles((theme) => ({
  SideBar: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: 210,
  },
  ListItem: {
    width: 186,
    height: 30,
    paddingTop: 9,
    paddingBottom: 8,
    marginBottom: 25,
    borderRadius: 24,
    margin: '0 12px',
  },
  ListItemIcon: {
    minWidth: 30,
    color: '#78849b',
  },
  ListItemText: {
    fontSize: 16,
    fontFamily: 'GT Walsheim Pro',
  },
  LogoIcon: {
    margin: '34px 0 70px 30px',
    width: 107,
  },
  NavLink: {
    display: 'flex',
  },
  version: {
    color: '#9ea7b5',
    fontSize: '10px',
    textAlign: 'center',
  },
}));
