import { AccountRole } from 'enums';
import { IRootState } from 'intefaces';
import { useSelector } from 'react-redux';
import { IAccount } from 'services/http/auth/interfaces';

export function useAccount() {
  const myAccount = useSelector((state: IRootState) => state.user.user as IAccount);

  return { myAccount, isAdmin: myAccount && myAccount.Role === AccountRole.ADMIN };
}
