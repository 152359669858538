import React, { useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { IGeoCity } from 'services/http/geo/interfaces';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

interface CitiesListWrapperProps {
  cities: IGeoCity[];
  setHovered: (poligonId?: string) => void;
  hoveredId?: string;
  setEditable: (poligonId?: string) => void;
  editableId?: string;
  deleteCity: (cityId: string) => void;
}

const useStyle = makeStyles({
  hover: {
    background: '#ffb00261',
  },
  root: {
    maxHeight: 500,
    padding: '16px',
    overflow: 'auto',
  },
});

export const CitiesListWrapper = ({
  cities,
  hoveredId,
  setHovered,
  setEditable,
  editableId,
  deleteCity,
}: CitiesListWrapperProps) => {
  const classes = useStyle();

  const onMouseOver = useCallback(
    (id: string) => {
      if (editableId) {
        return;
      }

      setHovered(id);
    },
    [editableId]
  );

  const onMouseOut = useCallback(() => {
    if (editableId) {
      return;
    }

    setHovered();
  }, [editableId]);

  const onEditClickHandler = useCallback(
    (id: string) => {
      if (editableId) {
        return;
      }

      setEditable(id);
    },
    [editableId]
  );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {cities.map((s) => (
          <Grid
            key={s.Id}
            item
            xs={12}
            className={clsx({
              [classes.hover]: s.Id === hoveredId,
            })}
            onMouseOver={() => onMouseOver(s.Id)}
            onMouseOut={onMouseOut}
          >
            <Grid container wrap="nowrap" alignItems="center">
              <Grid item xs>
                {s.Name}
              </Grid>
              <Grid item>
                <IconButton
                  onClick={() => onEditClickHandler(s.Id)}
                  color="default"
                  size="small"
                  disabled={!!editableId}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton onClick={() => deleteCity(s.Id)} color="default" size="small" disabled={!!editableId}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
