import { IApplication, ICompany, IEditCompanyDetails } from './interfaces';
import { HttpService } from '../axios';
import { CancelToken } from 'axios';
import { IGetVacancy } from '../jobs/interfaces';
import { IEvent, IGetPaginationResponse } from '../interfaces';

export class CompaniesService {
  public static getAll(queryParams: string, count: string, cancelToken: CancelToken) {
    return HttpService.get<IGetPaginationResponse<ICompany>>(
      `${HttpService.env.adminAPI}/companies?${queryParams}&count=${count}`,
      { cancelToken }
    );
  }

  public static deleteCompany(id: string) {
    return HttpService.delete(`${HttpService.env.adminAPI}/companies/${id}`);
  }

  public static deleteCompanyJob(id: string, jobId: string) {
    return HttpService.delete(`${HttpService.env.adminAPI}/companies/${id}/jobs/${jobId}`);
  }

  public static editCompanyDetails(id: string, body: IEditCompanyDetails) {
    const { Location, ...data } = body;

    return HttpService.patch<void>(`${HttpService.env.adminAPI}/companies/${id}`, {
      ...data,
      Location: {
        Latitude: Location?.Latitude,
        Longitude: Location?.Longitude,
      },
    });
  }

  public static editCompanyVacancy(id: string, vacancyId: string, body: Partial<IGetVacancy>) {
    return HttpService.patch<void>(`${HttpService.env.adminAPI}/companies/${id}/jobs/${vacancyId}`, body);
  }

  public static getLogs(id: string, pageNumber: number, count: string) {
    return HttpService.get<IGetPaginationResponse<IEvent>>(`${HttpService.env.adminAPI}/companies/${id}/events`, {
      params: {
        Page: pageNumber,
        Count: count,
      },
    });
  }

  public static getCompanyVacancyApplications(
    companyId: string,
    vacancyId: string,
    status: string | null,
    pageNumber: number,
    count: string
  ) {
    return HttpService.get<IGetPaginationResponse<IApplication>>(
      `${HttpService.env.adminAPI}/companies/${companyId}/jobs/${vacancyId}/applications`,
      {
        params: {
          Page: pageNumber,
          Count: count,
          Status: status,
        },
      }
    );
  }

  public static updateRepresentative(id: string, data: Record<any, any>) {
    return HttpService.patch<void>(`${HttpService.env.adminAPI}/users/${id}`, data);
  }
}
