import { makeStyles } from '@material-ui/core/styles';

export const useMainStyles = makeStyles((theme) => ({
  FlexWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '16px 12px 12px 0',
  },
  CompaniesBody: {
    position: 'relative',
    flexGrow: 1,
  },
  CompaniesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  CompaniesTitle: {
    height: 40,
  },
  CompaniesTable: {
    padding: 50,
  },
  CompaniesTableHeader: {
    background: theme.palette.background.paper,
    borderRadius: 40,
  },
  CompaniesTableCell: {
    fontWeight: 700,
    color: theme.palette.text.secondary,
  },
}));
