import React from 'react';
import { HomeLayout } from 'layouts/Home';
import { TeamMain } from './Main';

export const Team = () => {
  return (
    <HomeLayout>
      <TeamMain />
    </HomeLayout>
  );
};
