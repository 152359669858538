import React, { useCallback, useMemo } from 'react';
import { useRepresentativeStyles } from './RepresentativeStyles';
import { IRepresentative } from '../../../../../services/http/company/interfaces';
import { Avatar } from '../../../../../components/Avatar';
import { Grid } from '@material-ui/core';
import { Form, Formik, FormikProps } from 'formik';
import { getFormInput } from 'components/FormComponents/getFormInput';
import { StyledSaveButton } from 'components/ui/SaveButton';
import { Loading } from 'components/Loading';
import { formSubmitCatchAsync } from 'utils/submitFormCatchAsync';
import { CompaniesService } from 'services/http/company';
import { useNotificationContext } from 'utils/notificationContext';
import { validateRep } from 'utils/validations/validateRep';

interface IRepresentativeProps {
  representative: IRepresentative;
  index: number;
  reload: () => void;
}
export interface IRepForm {
  name: string;
}

export const Representative = ({ representative, index, reload }: IRepresentativeProps) => {
  const classNames = useRepresentativeStyles();
  const { setNotification } = useNotificationContext();

  const submitHandler = useCallback(
    formSubmitCatchAsync<IRepForm>(async (formData, formikHelpers) => {
      await CompaniesService.updateRepresentative(representative.Id, { FullName: formData.name });
      formikHelpers.setSubmitting(false);

      setNotification({ text: `${formData.name} was successfully updated`, severity: 'success' });
      reload();
    }),
    [representative.Id, reload]
  );

  const initialValues = useMemo(() => {
    return { name: representative.FullName };
  }, [representative]);

  return (
    <div className={classNames.Representative}>
      <Avatar
        className={classNames.Thumbnail}
        logo={representative.Thumbnail}
        size="_md"
        alt={representative.FullName}
      />
      <div>
        <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validateRep}>
          {(props) => <InnerForm {...props} index={index} />}
        </Formik>
      </div>
      <hr className={classNames.Separator} />
    </div>
  );
};

const FormInput = getFormInput<IRepForm>();

const InnerForm = ({ isSubmitting, index }: FormikProps<IRepForm> & { index: number }) => {
  return (
    <Form>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormInput label={`#${index} Name`} name="name" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <StyledSaveButton type="submit">{isSubmitting ? <Loading /> : 'Save'}</StyledSaveButton>
        </Grid>
      </Grid>
    </Form>
  );
};
