import locationIcon from '../../../../assets/locationIcon.svg';
import { makeStyles } from '@material-ui/core';

export const useLocationAutoCompleteStyles = makeStyles({
  EndAdornment: {
    paddingRight: 0,
  },
});

export const useLocationIconStyles = makeStyles({
  LocationIcon: {
    display: 'block',
    content: `url(${locationIcon})`,
  },
});
