import { IReindexResult } from './interfaces';
import { HttpService } from '../axios';

export class SearchService {
  public static async reindexCandidates(): Promise<IReindexResult> {
    return HttpService.post<IReindexResult>(`${HttpService.env.searchAPI}/candidate/index`);
  }

  public static async reindexJobs(): Promise<IReindexResult> {
    return HttpService.post<IReindexResult>(`${HttpService.env.searchAPI}/job/index`);
  }
}
