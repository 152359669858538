import { makeStyles } from '@material-ui/core/styles';

export const useFormCvrStyles = makeStyles((theme) => ({
  CvrContainer: {
    position: 'relative',
  },
  CvrButton: {
    width: 74,
    height: 30,
    borderRadius: 24,
    border: `1px solid ${theme.palette.secondary.dark}`,
  },
  CvrConfirmPositioning: {
    position: 'absolute',
    top: 24,
    transform: 'translateY(-50%)',
    right: 45,
  },
}));
