import React from 'react';
import Button from '@material-ui/core/Button';
import { useFilterButtonStyles } from './FilterButtonStyles';
import filterIcon from '../../assets/filter.svg';

interface IFilterButtonProps {
  onClick: () => void;
  isFiltersShow: boolean;
}

export const FilterButton = ({ onClick, isFiltersShow }: IFilterButtonProps) => {
  const classNames = useFilterButtonStyles();

  return (
    <Button className={`${classNames.Button} ${isFiltersShow ? classNames.Button_active : ''}`} onClick={onClick}>
      <img className={classNames.Icon} src={filterIcon} />
      Filter
    </Button>
  );
};
