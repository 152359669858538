import React from 'react';
import { NavLink } from 'react-router-dom';
import { useGoToProfileStyles } from './GoToProfileLinkStyles';
import { AccountRole } from '../../enums';

interface IGoToProfileLinkProps {
  id: string;
  role: AccountRole;
  children: React.ReactNode;
  target?: '_blank';
}

export const GoToProfileLink = ({ id, role, children, target }: IGoToProfileLinkProps) => {
  const classNames = useGoToProfileStyles();

  let toLink = '';

  if (role === AccountRole.CANDIDATE) {
    toLink = `/candidate/${id}`;
  }

  if (role === AccountRole.COMPANY) {
    toLink = `/company/${id}`;
  }

  return (
    <NavLink className={classNames.Link} exact={true} to={toLink} role="button" target={target}>
      {children}
    </NavLink>
  );
};

interface IGoToJobDetailsLinkProp {
  companyId: string;
  jobId: string;
  children: React.ReactNode;
}

export const GoToJobDetailsLink = ({ companyId, jobId, children }: IGoToJobDetailsLinkProp) => {
  const classNames = useGoToProfileStyles();

  return (
    <NavLink className={classNames.Link} to={`/company/${companyId}/job/${jobId}`}>
      {children}
    </NavLink>
  );
};
