import { makeStyles } from '@material-ui/core/styles';
import plusIcon from '../../../../assets/plus.svg';

export const useAddPhotoGroupStyles = makeStyles((theme) => ({
  GroupSection: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  Photo: {
    position: 'relative',
    width: 94,
    height: 94,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.paper,
    borderRadius: '50%',
  },
  PlusICon: {
    position: 'absolute',
    top: 30,
    right: 20,
    content: `url(${plusIcon})`,
    display: 'block',
    borderRadius: '50%',
    width: 13,
    height: 13,
  },
  UploadSection: {
    marginLeft: 14,
    display: 'flex',
    flexDirection: 'column',
  },
  UploadButtonPositioning: {
    marginBottom: 10,
  },
  AvailableFormats: {
    color: '#AFBACE',
    fontSize: 12,
  },
}));
