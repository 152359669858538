import React from 'react';
import { useParams } from 'react-router-dom';
import useAxios from 'axios-hooks';
import { IRepresentative } from '../../../../../services/http/company/interfaces';
import { ConfirmedImg } from '../../../../../components/Confirmed';
import { Grid, Typography } from '@material-ui/core';
import { Loading } from '../../../../../components/Loading';
import { HttpService } from '../../../../../services/http/axios';
import { Avatar } from 'components/Avatar';

export const CompanyMembers = () => {
  const { companyId: id } = useParams<{ companyId: string }>();

  const [{ data: companyMembers = [], loading }] = useAxios<IRepresentative[]>(
    `${HttpService.env.adminAPI}/companies/${id}/representatives`,
    { useCache: false }
  );

  if (loading) {
    return <Loading size={'2em'} />;
  }

  return (
    <div>
      {companyMembers?.map((el) => (
        <Member
          key={el.Id}
          fullName={el.FullName}
          isEmailConfirmed={el.IsEmailConfirmed}
          thumbnail={el.Thumbnail}
          email={el.Email}
        />
      ))}
    </div>
  );
};

interface ICompanyMember {
  isEmailConfirmed: boolean;
  fullName: string;
  thumbnail?: string;
  email: string;
}

const Member = ({ fullName, isEmailConfirmed, thumbnail, email }: ICompanyMember) => {
  return (
    <Grid container spacing={1} alignItems="center" wrap="nowrap">
      <Grid item>
        <Avatar logo={thumbnail} alt={fullName} size="_sm" />
      </Grid>
      <Grid item>
        <Typography color="textPrimary" variant="subtitle1">
          {fullName}
        </Typography>
        <Grid container wrap="nowrap" alignItems="center" spacing={1}>
          <Grid item>
            <Typography color="textPrimary" variant="subtitle1">
              {email}
            </Typography>
          </Grid>
          <Grid item>
            <ConfirmedImg isConfirmed={isEmailConfirmed} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
