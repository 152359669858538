import { useState, useMemo, useCallback } from 'react';

export function useToggle(initialValue: boolean) {
  const [isToggled, setToggle] = useState(initialValue);
  const show = useCallback(() => setToggle(true), []);
  const hide = useCallback(() => setToggle(false), []);
  const toggle = useCallback(() => setToggle((lastIsToggled) => !lastIsToggled), []);

  return useMemo(
    () => ({
      isToggled,
      show,
      hide,
      toggle,
    }),
    [isToggled]
  );
}
