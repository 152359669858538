import React from 'react';

import { MainLayout } from '../../../layouts/Home/MainLayout';

import { DetailsForm } from './DetailsForm';
import { Representatives } from './Representatives';

export const CompanyEditMain = () => {
  return (
    <MainLayout hasBackPath={true}>
      <>
        <DetailsForm />

        <Representatives />
      </>
    </MainLayout>
  );
};
