import React, { useCallback, useMemo, useState } from 'react';
import { ICheckboxState } from '../intefaces';

interface IData {
  Id: string;
}

export const useCheckboxState = <T extends IData>(data: T[]) => {
  const [checkedItemsState, setCheckedItemsState] = useState<ICheckboxState>({});

  const resetCheckboxes = useCallback(() => {
    setCheckedItemsState({});
  }, [setCheckedItemsState]);

  const selectPage = useCallback(() => {
    const newCheckedState: ICheckboxState = {};
    data.forEach((el) => {
      newCheckedState[el.Id] = true;
    });

    setCheckedItemsState(newCheckedState);
  }, [setCheckedItemsState, data]);

  const unselectPage = useCallback(() => {
    setCheckedItemsState({});
  }, [setCheckedItemsState]);

  const checkboxOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
      setCheckedItemsState((prevState) => ({ ...prevState, [id]: event.target.checked }));
    },
    [setCheckedItemsState]
  );

  const getCheckedItemsIds = useCallback(
    () =>
      Object.entries(checkedItemsState)
        .filter(([, val]) => val)
        .map(([id]) => id),

    [checkedItemsState]
  );

  return useMemo(
    () => ({
      checkedItemsState,
      resetCheckboxes,
      selectPage,
      unselectPage,
      checkboxOnChange,
      getCheckedItemsIds,
    }),
    [checkedItemsState, resetCheckboxes, selectPage, unselectPage, checkboxOnChange, getCheckedItemsIds]
  );
};
