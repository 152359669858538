import React from 'react';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { DetailsForm } from './DetailsFrom';

export const ExternalJobsEditMain = () => {
  return (
    <MainLayout hasBackPath={true}>
      <DetailsForm />
    </MainLayout>
  );
};
