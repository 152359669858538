import { HttpService } from 'services/http/axios';
import { GrantType, AuthData } from 'enums';
import { IUserAuthData } from '../../../reduxStore/actions/user/interfaces';
import { IAccount, IAuthResponse } from 'services/http/auth/interfaces';
import { StorageService } from '../../storage';
import { stringify } from 'qs';

export class AuthService {
  public static login(authData: IUserAuthData) {
    const data = stringify({
      ...this.getDefaultData(GrantType.PASSWORD),
      username: authData.username,
      password: authData.password,
    });

    return HttpService.post<IAuthResponse>(`${HttpService.env.idsAPI}/connect/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  public static refreshUser() {
    const data = stringify({
      ...this.getDefaultData(GrantType.REFRESH_TOKEN),
      refresh_token: StorageService.getRefreshToken() || '',
    });

    return HttpService.post<IAuthResponse>(`${HttpService.env.idsAPI}/connect/token`, data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
  }

  private static getDefaultData(grant_type: GrantType) {
    return {
      grant_type,
      client_id: AuthData.ClIENT_ID,
      client_secret: AuthData.CLIENT_SECRET,
      scope: AuthData.SCOPE,
    };
  }

  public static getUser(userId?: string): Promise<IAccount> {
    if (!userId) {
      return HttpService.get<IAccount>(`${HttpService.env.adminAPI}/users/me`);
    }

    return HttpService.get<IAccount>(`${HttpService.env.adminAPI}/users/${userId}`);
  }

  public static patchUser(accountId: string, data: Partial<IAccount>): Promise<void> {
    return HttpService.patch<void>(`${HttpService.env.adminAPI}/users/${accountId}`, data);
  }
}
