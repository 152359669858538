import { connect, ConnectedProps } from 'react-redux';
import { logOut } from '../reduxStore/actions/user';
import { Profile } from '../layouts/Home/Profile';

const mapDispatchToProps = {
  logOut,
};

const connector = connect(null, mapDispatchToProps);

export type IProfileReduxProps = ConnectedProps<typeof connector>;

export const ProfileContainer = connector(Profile);
