import { BadgeTypes } from '../components/Badge';

export const getJobStatusBadgeData = (
  status?: number
): {
  text: string;
  type: BadgeTypes;
} => {
  switch (status) {
    case 0:
      return {
        text: 'Active',
        type: BadgeTypes.ACTIVE,
      };
    case 1:
      return {
        text: 'Closed',
        type: BadgeTypes.CLOSED,
      };
    case 2:
      return {
        text: 'Deleted',
        type: BadgeTypes.UNRESOLVED,
      };

    default:
      return {
        text: 'No status',
        type: BadgeTypes.CLOSED,
      };
  }
};
