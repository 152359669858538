import { makeStyles } from '@material-ui/core/styles';

export const useCustomTableRowStyles = makeStyles({
  TableRow: {
    height: 60,
    boxShadow: '0px 4px 38px #F2F5F9',
    '&:hover': {
      boxShadow: '0px 7px 15px #DEE5EE',
      transition: 'all .5s',
    },
  },
  _clickable: {
    cursor: 'pointer',
  },
});
