import React, { useCallback, useState } from 'react';
import { PageTitleWithCount } from 'components/PageTitleWithCount';
import { MainLayout } from 'layouts/Home/MainLayout';
import { SearchService } from 'services/http/search';
import { Box, Button, Grid } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { useNotificationContext } from 'utils/notificationContext';

export const SystemMain = () => {
  const [isCandidateIndexingInProgress, setIsCandidateIndexingInProgress] = useState<boolean>(false);
  const [isJobIndexingInProgress, setIsJobIndexingInProgress] = useState<boolean>(false);

  const confirm = useConfirm();
  const { setNotification } = useNotificationContext();

  const reIndexCandidates = useCallback(async () => {
    setIsCandidateIndexingInProgress(true);

    try {
      await confirm({ description: 'do you really want to start reindexing?' });
      await SearchService.reindexCandidates();
      // some checks response
      setNotification({ text: 'Was reindexed', severity: 'success' });
    } catch (error) {
      setNotification({ text: 'Was cancelled', severity: 'warning' });
    }

    setIsCandidateIndexingInProgress(false);
  }, []);

  const reIndexJobs = useCallback(async () => {
    setIsJobIndexingInProgress(true);

    try {
      await confirm({ description: 'do you really want to start reindexing?' });
      await SearchService.reindexJobs();
      // some checks response
      setNotification({ text: 'Was reindexed', severity: 'success' });
    } catch (error) {
      setNotification({ text: 'Was cancelled', severity: 'warning' });
    }

    setIsJobIndexingInProgress(false);
  }, []);

  return (
    <MainLayout>
      <section>
        <PageTitleWithCount title="System management" />
        <Box mt={2} mb={2}>
          <Grid container spacing={3}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={reIndexCandidates}
                disabled={isCandidateIndexingInProgress}
              >
                Reindex candidates
              </Button>
            </Grid>
            <Grid item>
              <Button color="secondary" variant="contained" onClick={reIndexJobs} disabled={isJobIndexingInProgress}>
                Reindex jobs
              </Button>
            </Grid>
          </Grid>
        </Box>
      </section>
    </MainLayout>
  );
};
