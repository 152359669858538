import { makeStyles } from '@material-ui/core/styles';

export const useNotFoundStyles = makeStyles({
  NotFound: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
