import { makeStyles } from '@material-ui/core/styles';

export const useFiltersStyles = makeStyles((theme) => ({
  FiltersLayout: {
    background: theme.palette.background.paper,
    position: 'relative',
    zIndex: 3,
    width: 200,
    minWidth: 200,
    minHeight: '100%',
    transformOrigin: '0 0',
    padding: 14,
  },

  Title: {
    fontSize: 16,
    fontFamily: 'GT Walsheim Pro Regular',
    marginBottom: 10,
  },
  _filtersHide: {
    animation: '$hide 100ms forwards',
  },
  _filtersShow: {
    animation: '$show 100ms forwards',
  },
  '@keyframes hide': {
    '0%': {
      transform: 'scaleX(1)',
      width: 200,
      minWidth: 200,
    },
    '100%': {
      transform: 'scaleX(0)',
      width: 0,
      minWidth: 0,
    },
  },
  '@keyframes show': {
    '0%': {
      width: 0,
      minWidth: 0,
    },
    '100%': {
      transform: 'scaleX(1)',
      width: 200,
      minWidth: 200,
    },
  },
}));
