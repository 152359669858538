import { FormControl, FormLabel } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { ErrorOutput } from '../../../../../components/FormComponents/getFormInput/ErrorOutput';
import React from 'react';
import Button from '@material-ui/core/Button';
import { useFormCvrStyles } from './FormCvrStyles';
import { ICompanyDetailsForm } from '../index';
import { StyledInput } from '../../../../../components/ui/StyledInput';
import { Confirmed } from '../../../../../components/Confirmed';

export const FormCVR = () => {
  const classNames = useFormCvrStyles();
  const { values, setFieldValue, errors, setFieldTouched, validateForm } = useFormikContext<ICompanyDetailsForm>();
  const cvrConfirmed = values.cvrConfirmed;

  const onConfirmClick = async () => {
    setFieldTouched('cvrCode', true, true);

    const err = await validateForm(values);

    if (err.cvrCode) {
      return;
    }

    setFieldValue('cvrConfirmed', !cvrConfirmed);
  };

  return (
    <FormControl>
      <FormLabel>CVR</FormLabel>
      <div className={classNames.CvrContainer}>
        <Field as={StyledInput} name="cvrCode" placeholder="CVR" error={!!errors.cvrCode} />
        {values.cvrConfirmed ? (
          <Confirmed type={'Confirmed'} isConfirmed={true} className={classNames.CvrConfirmPositioning} />
        ) : (
          <Field
            as={Button}
            name="cvrConfirmed"
            className={`${classNames.CvrButton} ${classNames.CvrConfirmPositioning}`}
            disabled={Boolean(!values.cvrCode || errors.cvrCode)}
            variant="outlined"
            onClick={onConfirmClick}
          >
            Confirm
          </Field>
        )}
      </div>
      <ErrorOutput<ICompanyDetailsForm> name="cvrCode" forceShow />
      <ErrorOutput<ICompanyDetailsForm> name="cvrConfirmed" />
    </FormControl>
  );
};
