import { makeStyles } from '@material-ui/core/styles';

export const useApplicationsStyles = makeStyles({
  Description: {
    margin: '20px 0',
  },
  StatusTabs: {
    marginBottom: 21,
  },
});
