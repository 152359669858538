import { makeStyles } from '@material-ui/core/styles';

export const useTableNumberOfCellStyles = makeStyles({
  TableNumberOfCell: {
    textAlign: 'left',
    border: 'none',
  },
  Count: {
    fontFamily: 'GT Walsheim Pro Bold',
  },
});
