import React from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { ReactComponent as EditIcon } from '../../assets/edit.svg';
import { ReactComponent as TrashIcon } from '../../assets/trashCan.svg';

export const EditButton = (props: ButtonProps) => {
  return (
    <Button {...props} color="primary" variant="contained" startIcon={<EditIcon />}>
      Edit
    </Button>
  );
};

export const DeleteButton = (props: ButtonProps) => {
  return (
    <Button {...props} color="secondary" variant="outlined" startIcon={<TrashIcon />}>
      Delete
    </Button>
  );
};
