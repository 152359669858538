import React from 'react';
import { Route, Switch } from 'react-router';
import { LoginPageContainer } from './containers/Login';
import { ProtectedLayout } from 'layouts/ProtectedLayout';

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/signin" component={LoginPageContainer} />
      <Route path="/" component={ProtectedLayout} />
    </Switch>
  );
};
