import { makeStyles } from '@material-ui/core/styles';

export const useRepresentativeStyles = makeStyles((theme) => ({
  Representative: {
    display: 'flex',
    flexDirection: 'column',
  },
  Separator: {
    border: 'none',
    height: 2,
    margin: 24,
    width: '100%',
    backgroundColor: theme.palette.secondary.dark,
  },
  Thumbnail: {
    margin: '15px 0 15px 10px',
  },
}));
