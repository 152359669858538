import { makeStyles } from '@material-ui/core/styles';
import shortBackArrow from '../../../../assets/shortBackArrow.svg';

export const useHeaderStyles = makeStyles((theme) => ({
  Header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  BackLink: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    border: 'none',
    fontSize: 14,
    background: 'transparent',
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&::before': {
      marginRight: 8,
      height: 15,
      content: `url(${shortBackArrow})`,
    },
  },
}));
