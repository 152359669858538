import { makeStyles } from '@material-ui/core/styles';

export const useMainStyles = makeStyles((theme) => ({
  FlexWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  Separator: {
    marginTop: 10,
    marginBottom: 10,
    height: 1,
    background: theme.palette.secondary.main,
  },
}));
