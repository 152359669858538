import React, { useCallback } from 'react';
import photo from '../../../../assets/photo.svg';
import { useAddPhotoGroupStyles } from './AddPhotoGroupStyles';
import { UploadInput } from '../UploadInput';
import { useFormikContext } from 'formik';

interface IAddPhotoGroupProps<Form> {
  fileFieldName: keyof Form;
  containerClassName?: string;
}

export function AddPhotoGroup<Form>({ fileFieldName, containerClassName }: IAddPhotoGroupProps<Form>) {
  const classNames = useAddPhotoGroupStyles();
  const { setFieldValue } = useFormikContext<Form>();

  const handleUploadedFile = useCallback(
    (file: File | null) => {
      setFieldValue(`${fileFieldName}`, file);
    },
    [setFieldValue, fileFieldName]
  );

  return (
    <section className={`${classNames.GroupSection} ${containerClassName ? containerClassName : ''}`}>
      <div className={classNames.Photo}>
        <img src={photo} />
        <i className={classNames.PlusICon} />
      </div>
      <div className={classNames.UploadSection}>
        <UploadInput className={classNames.UploadButtonPositioning} handleUploadedFile={handleUploadedFile} />
        <span className={classNames.AvailableFormats}>PNG, JPG files are allowed</span>
      </div>
    </section>
  );
}
