import React from 'react';
import { ToggleFilterHeader } from './ToggleFilterHeader';
import { useToggle } from '../../../../utils/useToggle';
import { useFilterItemLayoutStyles } from './FilterItemLayoutStyles';
import { Collapse, Typography } from '@material-ui/core';

interface IFilterItemProps {
  title: string;
  children: JSX.Element;
  applyHandler: () => void;
}

export const FilterItemLayout = ({ children, title, applyHandler }: IFilterItemProps) => {
  const classNames = useFilterItemLayoutStyles();
  const { toggle, isToggled } = useToggle(true);

  return (
    <section className={classNames.FilterItemSection}>
      <ToggleFilterHeader title={title} toggle={toggle} isToggled={isToggled} />
      <Collapse in={isToggled}>
        <section className={classNames.FilterItemBody}>{children}</section>
        <Typography onClick={applyHandler} classes={{ h4: classNames.ApplyFilterButton }} variant="h4">
          APPLY
        </Typography>
      </Collapse>
    </section>
  );
};
