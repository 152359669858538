import { makeStyles } from '@material-ui/core/styles';

export const useCustomTableCheckboxCellStyles = makeStyles({
  TableCell: {
    minWidth: 23,
    maxWidth: 23,
    textOverflow: 'unset',
  },
  _TableCheckboxCellBodyBg: {
    background: '#FFFFFF',
  },
});
