import { DateTime } from 'luxon';

export const createDateTime = (date?: Date | string): DateTime => {
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (typeof date === 'string') {
    return DateTime.fromISO(date);
  }

  return DateTime.local();
};

export enum DateTimeFormat {
  ISO = 'dd/MM/yyyy hh:mm a',
  FULL = 'dd/MM/yyyy',
  SHORT = 'MMM yyyy',
  BIRTHDAY = 'd MMMM y',
  TIME = 'hh:mm a',
}
