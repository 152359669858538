import { makeStyles } from '@material-ui/core/styles';

export const useSearchFieldStyles = makeStyles((theme) => ({
  Input: {
    width: 265,
    padding: '3px 11px 3px 11px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    borderRadius: 28,
  },
  CloseIcon: {
    width: 16,
    cursor: 'pointer',
  },
}));
