import React, { useCallback, useEffect, useState } from 'react';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { useTableStyles } from '../../../styles';
import { Box, Grid, IconButton, TableBody, TableCell } from '@material-ui/core';
import { Search } from '../../../components/Search';
import { FilterButton } from '../../../components/FilterButton';
import { TableLayout } from '../../../layouts/Table';
import { CustomTableHead, ITableHeadCellData } from '../../../components/TableComponents/TableHead';
import useAxios from 'axios-hooks';
import { IGetJobsItem, IJobsCountsResponse, VacancyType } from '../../../services/http/jobs/interfaces';
import { HttpService } from '../../../services/http/axios';
import { IGetPaginationResponse } from '../../../services/http/interfaces';
import { useQuery } from '../../../utils/useQuery';
import { AccountRole, JobsParams, QueryParams } from '../../../enums';
import { useCheckboxState } from '../../../utils/useCheckboxState';
import { useConfirm } from 'material-ui-confirm';
import { createDateTime, DateTimeFormat } from '../../../utils/createDateTime';
import { CustomTableRow } from '../../../components/TableComponents/TableRow';
import { CustomTableBodyCheckboxCell } from '../../../components/TableComponents/TableCheckboxCell';
import { Badge } from '../../../components/Badge';
import { getJobStatusBadgeData } from '../../../utils/getJobStatusBadgeData';
import { deleteByIds } from '../../../utils/deleteByIds';
import { CompaniesService } from '../../../services/http/company';
import { useNotificationContext } from '../../../utils/notificationContext';
import { DataSortButton } from 'components/DataSort';
import { useRowClickHandler } from '../../../utils/useRowClickHandler';
import { PageTitleWithCount } from '../../../components/PageTitleWithCount';
import { StyledTab, StyledTabs } from '../../../components/ui/StyledTabs';
import { useMainStyles } from './MainStyles';
import { TableNumberOfCell } from '../../../components/TableComponents/TableRow/TableNumberOfCell';
import { GoToProfileLink } from '../../../components/GoToProfileLink';
import { TabLabelWithCount } from '../../../components/TabLabelWithCount';
import { useAccount } from 'utils/useAccount';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { saveAs } from 'file-saver';

interface IJobsMainProps {
  showFilters: () => void;
  isFiltersShow: boolean;
}

const count = '9';

enum SortNames {
  JOB_TITLE = 'Name',
  COMPANY = 'Company.Name',
  CATEGORY = 'Category.Name',
  LOCATION = 'Location',
  APPLICATIONS = 'Statistics.ApplicationsCount',
  SHORTLISTED = 'Statistics.ShortlistsCount',
  REJECTED = 'Statistics.RejectsCount',
  VIEWS = 'Statistics.ViewsCount',
  STATUS = 'Status',
  DATE = 'PublishDate',
}

const tableHeaders: ITableHeadCellData[] = [
  { text: '#' },
  {
    text: 'JOB TITLE',
    sortName: SortNames.JOB_TITLE,
  },
  {
    text: 'COMPANY',
    sortName: SortNames.COMPANY,
  },
  { text: 'CATEGORY', sortName: SortNames.CATEGORY },
  {
    text: 'LOCATION',
    sortName: SortNames.LOCATION,
  },
  {
    text: 'APPLICATIONS',
    sortName: SortNames.APPLICATIONS,
  },
  {
    text: 'SHORTLISTED',
    sortName: SortNames.SHORTLISTED,
  },
  {
    text: 'REJECTED',
    sortName: SortNames.REJECTED,
  },
  {
    text: 'VIEWS',
    sortName: SortNames.VIEWS,
  },
  {
    text: 'STATUS',
    sortName: SortNames.STATUS,
  },
  {
    text: 'DATE',
    sortName: SortNames.DATE,
  },
];

const getJobStatusName = (status: string) => {
  switch (status) {
    case '0':
      return 'Active';
    case '1':
      return 'Closed';
    case '2':
      return 'Deleted';
    default:
      return '';
  }
};

const getIdsStr = (companyId: string, jobId: string) => {
  return `${companyId}, ${jobId}`;
};

const parseIdsStr = (idsStr: string) => {
  const matches = idsStr.match(/^(\w+), (\w+)$/);

  return matches
    ? {
        companyId: matches[1],
        jobId: matches[2],
      }
    : {
        companyId: '',
        jobId: '',
      };
};

export const JobsMain = ({ showFilters, isFiltersShow }: IJobsMainProps) => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const classNames = useMainStyles();
  const tableClassNames = useTableStyles();
  const { currQueryParams, setParam, getFilteredParams } = useQuery();
  const confirm = useConfirm();
  const { setNotification } = useNotificationContext();
  const rowClickHandler = useRowClickHandler();
  const { isAdmin } = useAccount();

  const newQueryParams = new URLSearchParams(currQueryParams.toString());
  newQueryParams.append('vacancyType', VacancyType.Regular.toString());
  const params = newQueryParams.toString();

  const exportCSV = useCallback(() => {
    const newParams = new URLSearchParams(getFilteredParams([QueryParams.PAGE]).toString());
    newParams.append('vacancyType', VacancyType.Regular.toString());

    setExportLoading(true);
    HttpService.get<BlobPart>(`${HttpService.env.adminAPI}/jobs?${newParams}`, {
      headers: { Accept: 'text/csv' },
    })
      .then((res) => {
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `Jobs_${createDateTime().toFormat('yyyy_LLL_dd_HH_mm')}`);
      })
      .finally(() => {
        setExportLoading(false);
      });
  }, [getFilteredParams]);

  const [{ data: vacanciesResponse, loading }, fetchJobs] = useAxios<IGetPaginationResponse<IGetJobsItem>>(
    {
      url: `${HttpService.env.adminAPI}/jobs?${params}`,
      params: {
        count,
      },
    },
    { useCache: false }
  );

  const [{ data: jobsCounts, loading: jobsCountsLoading }, fetchJobsCounts] = useAxios<IJobsCountsResponse>(
    `${HttpService.env.adminAPI}/jobs/count`,
    { useCache: false }
  );

  useEffect(() => {
    if (!currQueryParams.get('sortBy') && !currQueryParams.get('sortByDesc')) {
      setParam(QueryParams.SORT_BY_DESC, SortNames.DATE);
    }

    if (!currQueryParams.get(JobsParams.STATUS)) {
      setParam(JobsParams.STATUS, '0');
    }
  }, []);

  const {
    checkedItemsState,
    getCheckedItemsIds,
    selectPage,
    unselectPage,
    checkboxOnChange,
    resetCheckboxes,
  } = useCheckboxState(vacanciesResponse?.Page.map((el) => ({ Id: getIdsStr(el.Company.Id, el.Id) })) || []);

  // need to work with job id and company id, workaround on my abstraction
  const deleteHandler = useCallback(() => {
    const checkedItemsIds = getCheckedItemsIds();

    deleteByIds(
      checkedItemsIds,
      (idsStr: string) => {
        const idsObj = parseIdsStr(idsStr);

        return CompaniesService.deleteCompanyJob(idsObj.companyId, idsObj.jobId);
      },
      setNotification,
      `${checkedItemsIds.length} jobs deleted`,
      confirm,
      'These jobs will be immediately deleted'
    )
      .then(() => {
        fetchJobs();
        fetchJobsCounts();
      })
      .catch(() => {});
  }, [CompaniesService.deleteCompany, setNotification, confirm, getCheckedItemsIds]);

  const statusFilterOnChange = useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      setParam(JobsParams.STATUS, newValue);
      setParam(QueryParams.PAGE, '1');
      resetCheckboxes();
    },
    [setParam]
  );

  const page = currQueryParams.get(QueryParams.PAGE) || '1';
  const currStatusFilter = currQueryParams.get(JobsParams.STATUS) || '0';
  const totalCount = jobsCounts ? jobsCounts?.Active + jobsCounts?.Closed + jobsCounts?.Deleted : undefined;

  return (
    <MainLayout loading={jobsCountsLoading}>
      <section className={tableClassNames.TableContainer}>
        <PageTitleWithCount title={'All jobs'} itemsCount={totalCount} />
        {!jobsCountsLoading && (
          <StyledTabs
            className={classNames.StatusTabs}
            value={currStatusFilter}
            indicatorColor="primary"
            onChange={statusFilterOnChange}
          >
            <StyledTab
              value={'0'}
              label={
                <TabLabelWithCount title="Active" count={jobsCounts?.Active} isSelected={currStatusFilter === '0'} />
              }
            />
            <StyledTab
              value={'1'}
              label={
                <TabLabelWithCount title="Closed" count={jobsCounts?.Closed} isSelected={currStatusFilter === '1'} />
              }
            />
            <StyledTab
              value={'2'}
              label={
                <TabLabelWithCount title="Deleted" count={jobsCounts?.Deleted} isSelected={currStatusFilter === '2'} />
              }
            />
          </StyledTabs>
        )}
        <Box mt={3} mb={1}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Grid container>
                <Grid item>
                  <Search />
                </Grid>
                <Grid item>
                  <FilterButton onClick={showFilters} isFiltersShow={isFiltersShow} />
                </Grid>
                <Grid item>
                  <DataSortButton fromKey={JobsParams.PUBLISH_DATE_FROM} toKey={JobsParams.PUBLISH_DATE_TO} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton onClick={exportCSV}>
                <CloudDownloadIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {vacanciesResponse && (
          <TableLayout
            loading={loading || exportLoading}
            numberOfPages={Math.ceil(vacanciesResponse.TotalItemCount / parseInt(count))}
            currentPage={parseInt(page)}
            isEmpty={vacanciesResponse.Page.length === 0}
            noItemsMessage={`No ${getJobStatusName(currStatusFilter)} jobs found`}
          >
            <CustomTableHead
              tableHeaders={tableHeaders}
              selectPage={selectPage}
              unselectPage={unselectPage}
              checkedItemsState={checkedItemsState}
              deleteHandler={deleteHandler}
              editable={isAdmin}
            />
            <TableBody>
              {vacanciesResponse.Page.map((el, index) => {
                const badgeData = getJobStatusBadgeData(el.Status);
                const itemIdsStr = getIdsStr(el.Company.Id, el.Id);

                return (
                  <CustomTableRow
                    key={el.Id}
                    clickHandler={(event: React.MouseEvent<HTMLTableRowElement>) =>
                      rowClickHandler(event, `/company/${el.Company.Id}/job/${el.Id}`)
                    }
                  >
                    <CustomTableBodyCheckboxCell
                      isChecked={Boolean(checkedItemsState[itemIdsStr])}
                      onChange={checkboxOnChange}
                      id={itemIdsStr}
                      editable={isAdmin}
                    />
                    <TableCell>{(vacanciesResponse?.PageIndex - 1) * parseInt(count) + index + 1}</TableCell>
                    <TableCell>{el.Name}</TableCell>
                    <TableCell>
                      <GoToProfileLink id={el.Company.Id} role={AccountRole.COMPANY}>
                        {el.Company.Name}
                      </GoToProfileLink>
                    </TableCell>
                    <TableCell>{el.Category.Name}</TableCell>
                    <TableCell>
                      {el.Location.Country},<br />
                      {el.Location.City}
                    </TableCell>
                    <TableNumberOfCell count={el.Statistics?.ApplicationsCount} itemName={'Applies'} />
                    <TableNumberOfCell count={el.Statistics?.ShortlistsCount} itemName={'Shortlists'} />
                    <TableNumberOfCell count={el.Statistics?.RejectsCount} itemName={'Rejected'} />
                    <TableNumberOfCell count={el.Statistics?.ViewsCount} itemName={'Views'} />
                    <TableCell>
                      <Badge type={badgeData.type} text={badgeData.text} />
                    </TableCell>
                    <TableCell>{createDateTime(el.PublishDate).toFormat(DateTimeFormat.FULL)}</TableCell>
                  </CustomTableRow>
                );
              })}
            </TableBody>
          </TableLayout>
        )}
      </section>
    </MainLayout>
  );
};
