import React, { ChangeEvent, useCallback, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { IGeoPlace } from 'services/http/geo/interfaces';
import { debounce, TextField } from '@material-ui/core';
import { GeoService } from 'services/http/geo';

interface LocationFieldProps {
  value: any;
  setValue: (v: any) => void;
  disabled: boolean;
}

const LocationField = ({ value, setValue, disabled = false }: LocationFieldProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<IGeoPlace[]>([]);

  const handleSelectChange = useCallback(async (event: React.ChangeEvent<{}>, value: IGeoPlace | null) => {
    if (!value) {
      setValue('');

      return;
    }

    setLoading(true);
    const location = await GeoService.getPlaceLocation(value);

    setValue(location);
    setLoading(false);
  }, []);

  const load = useCallback(async (v) => {
    setLoading(true);
    const geoPlaces = await GeoService.getPlaces(v);
    setOptions(geoPlaces);
    setLoading(false);
  }, []);

  const getPositionByPlace = debounce(load, 300);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      getPositionByPlace(event.target.value);
    },
    [getPositionByPlace]
  );

  return (
    <Autocomplete
      loading={loading}
      forcePopupIcon={false}
      options={options}
      getOptionLabel={getOptionLabel}
      filterOptions={(x) => x}
      loadingText="Loading"
      noOptionsText="No results"
      onChange={handleSelectChange}
      value={value}
      disabled={disabled}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            placeholder="Location..."
            onChange={handleInputChange}
            InputProps={{
              ...params.InputProps,
            }}
          />
        );
      }}
      renderOption={(options: IGeoPlace) => {
        return <div>{options.Name}</div>;
      }}
    />
  );
};

const getOptionLabel = (option: any) => {
  if (typeof option === 'string') {
    return option;
  }

  const value = [];

  if (option.Country) {
    value.push(option.Country);
  }

  if (option.City) {
    value.push(option.City);
  }

  return value.join(', ');
};

export default LocationField;
