import { withStyles } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

export const StyledSaveButton = withStyles((theme) => ({
  root: {
    width: 105,
    height: 48,
    background: '#6EB258',
    borderRadius: 24,
    color: '#FFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}))(Button);
