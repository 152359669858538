import { FormikHelpers } from 'formik';
import { ErrorMessage } from '../enums';

type ISubmitHandler<TemplateForm> = (
  values: TemplateForm,
  formikHelpers: FormikHelpers<TemplateForm>
) => Promise<unknown>;

export const formSubmitCatchAsync = <TemplateForm>(handler: ISubmitHandler<TemplateForm>) => {
  return async (values: TemplateForm, formikHelpers: FormikHelpers<TemplateForm>) => {
    try {
      await handler(values, formikHelpers);
    } catch (error) {
      const status = error?.response?.status;

      if (error.response === undefined) {
        formikHelpers.setStatus(ErrorMessage.NO_RESPONSE);
      } else if (status === 409) {
        formikHelpers.setStatus(ErrorMessage.EDIT_CONFLICT);
      } else if (status >= 400 && status < 500) {
        formikHelpers.setStatus(ErrorMessage.UNABLE_TO_PROCESS_REQUEST);
      } else {
        formikHelpers.setStatus(ErrorMessage.SERVER_ERROR);
      }
    }
  };
};
