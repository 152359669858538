import { makeStyles } from '@material-ui/core/styles';

export const useTableUserTypeCellStyles = (icon: string) =>
  makeStyles({
    Wrapper: {
      display: 'inline-table',
    },
    Image: {
      marginRight: 7,
      borderRadius: '50%',
    },
    Type: {
      display: 'inline',
      alignItems: 'center',
      '&::before': {
        content: '""',
        display: 'inline',
        padding: 20,
        background: `url(${icon}) no-repeat center`,
      },
    },
  });
