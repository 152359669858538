import React from 'react';
import { CircularProgress, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    alignItems: 'center',
    touchAction: 'none',
    justifyContent: 'center',
    zIndex: theme.zIndex.drawer + 1,
  },
}));

export const CustomBackdrop = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress color="inherit" />
    </div>
  );
};
