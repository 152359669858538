import React, { useCallback } from 'react';
import { Modal, ModalProps } from '@material-ui/core';
import { useCustomModalListStyles } from './ModalStyles';
import CloseIcon from '../../assets/close.svg';

interface IModalListProps {
  heading: JSX.Element;
}

export const CustomModal = ({ children, heading, ...props }: ModalProps & IModalListProps) => {
  const classNames = useCustomModalListStyles();

  const handleClose = useCallback(() => {
    props.onClose && props.onClose({}, 'backdropClick');
  }, [props.onClose]);

  if (!props.onClose) {
    return null;
  }

  return (
    <Modal {...props}>
      <section className={classNames.ModalBody}>
        <div className={classNames.Title}>
          {heading}
          <img className={classNames.CloseButton} src={CloseIcon} onClick={handleClose} />
        </div>
        {children}
      </section>
    </Modal>
  );
};
