import { makeStyles } from '@material-ui/core/styles';

export const useAvatarStyles = makeStyles({
  _md: {
    width: 58,
    height: 58,
  },
  _lg: {
    width: 94,
    height: 94,
  },
  _sm: {
    width: 32,
    height: 32,
  },
});
