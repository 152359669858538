import { withStyles } from '@material-ui/core/styles';
import { Tab, Tabs } from '@material-ui/core';

export const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.secondary.dark}`,
  },
  indicator: {
    height: 3,
    borderRadius: '9px 9px 0 0',
  },
}))(Tabs);

export const StyledTab = withStyles((theme) => ({
  root: {
    fontSize: 16,
    color: theme.palette.text.secondary,
    textTransform: 'none',
  },
  selected: {
    color: theme.palette.text.primary,
  },
}))(Tab);
