import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import { useTextHeadCellSortStatusStyles } from './TextCellStyles';
import { TableCellProps } from '@material-ui/core/TableCell/TableCell';

interface ICustomTHeadCell {
  text: string;
  sortData?: {
    handler: () => void;
    status: SortStatuses;
  };
}

export enum SortStatuses {
  DEFAULT,
  DESC,
  ASC,
}

export const CustomTHeadTextCell = ({ text, sortData, ...tableCellProps }: ICustomTHeadCell & TableCellProps) => {
  const classNames = useTextHeadCellSortStatusStyles();

  if (!sortData) {
    return (
      <TableCell {...tableCellProps}>
        <Typography variant="h5">{text}</Typography>
      </TableCell>
    );
  }

  const statusClassNamesArr = [classNames._default, classNames._desc, classNames._asc];

  return (
    <TableCell {...tableCellProps} onClick={sortData.handler}>
      <Typography
        classes={{
          h5: `${classNames._sortable} ${statusClassNamesArr[sortData.status]}`,
        }}
        variant="h5"
      >
        {text}
      </Typography>
    </TableCell>
  );
};
