import React, { useMemo } from 'react';
import { ILocation } from '../../../../services/http/company/interfaces';
import { useMainHeaderStyles } from './HeaderStyles';
import { Grid, Typography } from '@material-ui/core';
import { Avatar } from '../../../../components/Avatar';
import { createDateTime, DateTimeFormat } from '../../../../utils/createDateTime';
import timeIcon from '../../../../assets/jobDetails/timeIcon.svg';
import { useCommonMainHeaderStyles } from '../../../../styles/MainHeaderStyles';
import { LocationLink } from '../../../../components/LocationLink';
import { WorkHours } from '../../../../services/http/candidates/interfaces';
import PhoneIcon from '@material-ui/icons/Phone';

interface IMainHeaderProps {
  name: string;
  lastSeen: string;
  location: ILocation;
  logo?: string;
  email: string;
  workHours?: WorkHours;
  phone?: string;
}

export const MainHeader = ({ name, location, logo, email, lastSeen, workHours, phone }: IMainHeaderProps) => {
  const classNames = useMainHeaderStyles();
  const commonHeaderClassNames = useCommonMainHeaderStyles();

  const workHoursText = useMemo(() => {
    const values: string[] = [];

    if (!workHours) {
      return undefined;
    }

    if (WorkHours.Weekends & workHours) {
      values.push('Weekends and Holidays');
    }

    if (WorkHours.NightShifts & workHours) {
      values.push('Night shifts');
    }

    if (WorkHours.DayShifts & workHours) {
      values.push('Day shifts');
    }

    return values.join(', ');
  }, [workHours]);

  return (
    <header className={commonHeaderClassNames.Header}>
      <section className={commonHeaderClassNames.Profile}>
        <Avatar logo={logo} alt={name} />
        <section className={commonHeaderClassNames.ProfileInfoSection}>
          <div className={classNames.Info}>
            <Typography classes={{ root: commonHeaderClassNames.Title }} variant="h1">
              {name}
            </Typography>
            <span className={classNames.LastSeen}>
              Last seen: {createDateTime(lastSeen).toFormat(DateTimeFormat.FULL)}
            </span>
          </div>
          <span className={classNames.Email}>{email}</span>
          <LocationLink location={location} />
          <Grid container wrap="nowrap" spacing={1} alignItems="center" className={classNames.Phone}>
            <Grid item>
              <PhoneIcon fontSize="small" color="inherit" />
            </Grid>
            <Grid item>{phone || '-'}</Grid>
          </Grid>
        </section>
      </section>
      {!!workHoursText && (
        <Typography className={classNames.WorkingHours} variant="h3">
          <img className={classNames.WorkingHoursIcon} src={timeIcon} />
          {workHoursText}
        </Typography>
      )}
    </header>
  );
};
