import React from 'react';
import { useBadgeStyles } from './BadgeStyles';
import { Tooltip } from '@material-ui/core';

interface IBadgeProps {
  text: string;
  type: BadgeTypes;
  className?: string;
}

export enum BadgeTypes {
  PREMIUM = 'Badge_premium',
  ACTIVE = 'Badge_active',
  CLOSED = 'Badge_closed',
  UNRESOLVED = 'Badge_unresolved',
}

export const Badge = ({ text, type, className }: IBadgeProps) => {
  const classNames = useBadgeStyles();

  if (text.length > 9) {
    return (
      <Tooltip title={text}>
        <div className={`${classNames.Badge} ${classNames._longText} ${className} ${classNames[type]}`}>{text}</div>
      </Tooltip>
    );
  }

  return <div className={`${classNames.Badge} ${classNames._shortText} ${className} ${classNames[type]}`}>{text}</div>;
};
