import ValidatorJs from 'validatorjs';
import { IEditAccountForm } from 'pages/Team/Main/EditTeamMemberDrawer';

const rules = {
  fullname: 'required',
};

export function validateEditTeamMember(values: IEditAccountForm) {
  const validation = new ValidatorJs(values, rules);

  validation.check();

  return validation.errors.errors;
}
