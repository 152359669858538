export function typedReduce<TElement, TResult>(
  array: TElement[],
  reducer: (result: TResult, el: TElement) => TResult,
  initialResult: TResult
): TResult {
  let result = initialResult;

  for (const element of array) {
    result = reducer(result, element);
  }

  return result;
}
