import React, { useCallback, useEffect, useState } from 'react';
import { CompaniesService } from '../../../services/http/company';
import { ICompany } from '../../../services/http/company/interfaces';
import { Box, Grid, IconButton, TableBody, TableCell } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { useMainStyles } from './MainStyles';
import { TableLayout } from '../../../layouts/Table';
import { CustomTableRow } from '../../../components/TableComponents/TableRow';
import { CustomTableBodyCheckboxCell } from '../../../components/TableComponents/TableCheckboxCell';
import { Search } from '../../../components/Search';
import { TableCvrCell } from './TableCvrCell';
import { TableNumberOfCell } from '../../../components/TableComponents/TableRow/TableNumberOfCell';
import { createDateTime, DateTimeFormat } from '../../../utils/createDateTime';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { FilterButton } from '../../../components/FilterButton';
import { Badge, BadgeTypes } from '../../../components/Badge';
import { useQuery } from '../../../utils/useQuery';
import { QueryParams } from '../../../enums';
import axios from 'axios';
import { CustomTableHead, ITableHeadCellData } from '../../../components/TableComponents/TableHead';
import { useCheckboxState } from '../../../utils/useCheckboxState';
import { useRowClickHandler } from '../../../utils/useRowClickHandler';
import { useNotificationContext } from '../../../utils/notificationContext';
import { deleteByIds } from '../../../utils/deleteByIds';
import { PageTitleWithCount } from '../../../components/PageTitleWithCount';
import { useAccount } from 'utils/useAccount';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { HttpService } from 'services/http/axios';
import { saveAs } from 'file-saver';

const count = '9';

interface ICompaniesBodyProps {
  showFilters: () => void;
  isFiltersShow: boolean;
}
enum SortNames {
  NAME = 'Name',
  INDUSTRY = 'Industry',
  JOB_COUNT = 'JobCount',
  PURCHASES = 'Purchases',
  DATE = 'CreatedOn',
  CVR = 'Cvr',
  LOCATION = 'LocationInfo',
}

export const CompaniesMain = ({ showFilters, isFiltersShow }: ICompaniesBodyProps) => {
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [companiesUpdated, setCompaniesUpdated] = useState(false);
  const { setNotification } = useNotificationContext();
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const { isAdmin } = useAccount();
  const {
    checkedItemsState,
    resetCheckboxes,
    selectPage,
    unselectPage,
    checkboxOnChange,
    getCheckedItemsIds,
  } = useCheckboxState(companies);
  const rowClickHandler = useRowClickHandler();

  const { currQueryParams, getFilteredParams } = useQuery();
  const confirm = useConfirm();

  const exportCSV = useCallback(() => {
    const params = getFilteredParams([QueryParams.PAGE]).toString();

    HttpService.get<BlobPart>(`${HttpService.env.adminAPI}/companies?${params}`, {
      headers: { Accept: 'text/csv' },
    })
      .then((res) => {
        const blob = new Blob([res], { type: 'text/csv;charset=utf-8' });
        saveAs(blob, `Companies_${createDateTime().toFormat('yyyy_LLL_dd_HH_mm')}`);
      })
      .finally(() => {
        setExportLoading(false);
      });
  }, [getFilteredParams]);

  const deleteHandler = useCallback(() => {
    const checkedItemsIds = getCheckedItemsIds();

    deleteByIds(
      checkedItemsIds,
      CompaniesService.deleteCompany,
      setNotification,
      `${checkedItemsIds.length} companies deleted`,
      confirm
    )
      .then(() => setCompaniesUpdated((prev) => !prev))
      .catch(() => {});
  }, [CompaniesService.deleteCompany, setNotification, confirm, getCheckedItemsIds]);

  const tableHeaders: ITableHeadCellData[] = [
    { text: '#' },
    {
      text: 'COMPANY NAME',
      sortName: SortNames.NAME,
    },
    {
      text: 'INDUSTRY',
      sortName: SortNames.INDUSTRY,
    },
    { text: 'CVR', sortName: SortNames.CVR },
    { text: 'LOCATION' },
    {
      text: 'VACANCIES',
      sortName: SortNames.JOB_COUNT,
    },
    { text: 'EMPLOYEES' },
    { text: 'DATE', sortName: SortNames.DATE },
    {
      text: 'PLAN',
      sortName: SortNames.PURCHASES,
    },
  ];

  const classNames = useMainStyles();
  const page = currQueryParams.get(QueryParams.PAGE) || '1';

  useEffect(() => {
    setLoading(true);
    let source = axios.CancelToken.source();
    CompaniesService.getAll(currQueryParams.toString(), count, source.token).then((data) => {
      setTotalItemCount(data.TotalItemCount);
      setCompanies(data.Page);
      setPageIndex(data.PageIndex);
      setLoading(false);
      resetCheckboxes();
    });

    return () => {
      source.cancel('Cancelling in cleanup');
    };
  }, [currQueryParams, companiesUpdated]);

  return (
    <MainLayout>
      <section className={classNames.CompaniesBody}>
        <section className={classNames.CompaniesContainer}>
          <PageTitleWithCount title="Companies" itemsCount={totalItemCount} />
          <Box mt={3} mb={2}>
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Grid container>
                  <Grid item>
                    <Search />
                  </Grid>
                  <Grid item>
                    <FilterButton onClick={showFilters} isFiltersShow={isFiltersShow} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <IconButton onClick={exportCSV}>
                  <CloudDownloadIcon fontSize="small" />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
          <TableLayout
            numberOfPages={Math.ceil(totalItemCount / parseInt(count))}
            currentPage={parseInt(page)}
            loading={loading || exportLoading}
            isEmpty={totalItemCount === 0}
            noItemsMessage={'No companies found'}
          >
            <CustomTableHead
              tableHeaders={tableHeaders}
              selectPage={selectPage}
              unselectPage={unselectPage}
              checkedItemsState={checkedItemsState}
              deleteHandler={deleteHandler}
              editable={isAdmin}
            />
            <TableBody>
              {companies.map((el, index) => {
                return (
                  <CustomTableRow
                    key={el.Id}
                    clickHandler={(event: React.MouseEvent<HTMLTableRowElement>) =>
                      rowClickHandler(event, `/company/${el.Id}`)
                    }
                  >
                    <CustomTableBodyCheckboxCell
                      isChecked={!!checkedItemsState[el.Id]}
                      onChange={checkboxOnChange}
                      id={el.Id}
                      editable={isAdmin}
                    />
                    <TableCell>{(pageIndex - 1) * parseInt(count) + index + 1}</TableCell>
                    <TableCell>{el.Name}</TableCell>
                    <TableCell>{el.Industry}</TableCell>
                    <TableCvrCell cvrData={el.Cvr} style={{ minWidth: '120px' }} />
                    <TableCell>
                      {el.LocationInfo.Country},<br />
                      {el.LocationInfo.City}
                    </TableCell>
                    <TableNumberOfCell count={el.VacancyCount} itemName={'vacancies'} style={{ minWidth: '100px' }} />
                    <TableNumberOfCell
                      count={el.EmployeeCount}
                      itemName={el.EmployeeCount ? 'employees' : ''}
                      style={{ minWidth: '120px' }}
                    />
                    <TableCell>{createDateTime(el.CreatedOn).toFormat(DateTimeFormat.FULL)}</TableCell>
                    <TableCell width="200px">
                      {el.Purchases.length === 0 && <Badge text="No subscriptions" type={BadgeTypes.CLOSED} />}
                      {el.Purchases.length !== 0 &&
                        el.Purchases.map((p) => (
                          <Badge
                            key={p.Id}
                            text={p.UiDescription?.en?.Title || ''}
                            type={!p.IsSubscriptionStopped ? BadgeTypes.PREMIUM : BadgeTypes.UNRESOLVED}
                          />
                        ))}
                    </TableCell>
                  </CustomTableRow>
                );
              })}
            </TableBody>
          </TableLayout>
        </section>
      </section>
    </MainLayout>
  );
};
