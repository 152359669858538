import React from 'react';
import { MainHeader } from '../MainLayout/Header';
import { useMainLayoutStyles } from './MainLayoutStyles';
import { CustomBackdrop } from 'components/CustomBackdrop';

interface IFilterProps {
  children: JSX.Element;
  hasBackPath?: boolean;
  loading?: boolean;
}

export const MainLayout = ({ children, hasBackPath, loading }: IFilterProps) => {
  const classNames = useMainLayoutStyles();

  return (
    <main className={classNames.Main}>
      <MainHeader hasBackPath={hasBackPath} />
      {loading && <CustomBackdrop />}
      {children}
    </main>
  );
};
