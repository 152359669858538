import { HttpService } from './axios';

interface FileUploaded {
  Uri: string;
  Owner: string;
  OriginalName: string;
  MimeType: string;
  Extra: any;
}

export class FsService {
  public static uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return HttpService.post<FileUploaded>(HttpService.env.fsAPI, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }
}
