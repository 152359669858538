import { connect, ConnectedProps } from 'react-redux';
import { LoginPage } from 'pages/Login';
import { loginUser } from '../reduxStore/actions/user';

const mapDispatchToProps = {
  loginUser,
};

const connector = connect(null, mapDispatchToProps);

export type ILoginReduxProps = ConnectedProps<typeof connector>;

export const LoginPageContainer = connector(LoginPage);
