import React from 'react';
import { HomeLayout } from 'layouts/Home';
import { CitiesMain } from './Main';

export const Sities = () => {
  return (
    <HomeLayout>
      <CitiesMain />
    </HomeLayout>
  );
};
