import React, { useMemo } from 'react';
import { useInfoStyles } from './InfoStyles';
import { InfoItem } from '../../../../components/Info';
import member from '../../../../assets/member.svg';
import birthdayIcon from '../../../../assets/birthday.svg';
import bag from '../../../../assets/bag.svg';
import education from '../../../../assets/candidateDetails/education.svg';
import language from '../../../../assets/language.svg';
import license from '../../../../assets/candidateDetails/license.svg';
import permissionIcon from '../../../../assets/candidateDetails/permission.svg';
import { createDateTime, DateTimeFormat } from '../../../../utils/createDateTime';
import { Grid, Typography } from '@material-ui/core';
import { getWorkingExperienceByMonths } from '../../../../utils/getMonthExperience';
import { ILanguage } from '../../../../intefaces';
import { DriverLicenseCategory } from '../../../../services/http/candidates/interfaces';
import { enumKeys } from 'enums';

interface IInfoProps {
  dateOfAccountCreation: string;
  birthday: string;
  monthExperience: number;
  educations: string[];
  languages: ILanguage[];
  permission?: boolean;
  driverLicenseCategory?: DriverLicenseCategory;
}

const getPermissionText = (permission?: boolean) => {
  if (permission === undefined) {
    return 'No data';
  }

  return permission ? 'Yes' : ' No';
};

export const Info = ({
  dateOfAccountCreation,
  birthday,
  monthExperience,
  educations,
  languages,
  permission,
  driverLicenseCategory,
}: IInfoProps) => {
  const classNames = useInfoStyles();

  const driverLicenseCategoryArray = useMemo(() => {
    const values: string[] = [];

    for (const value of enumKeys(DriverLicenseCategory)) {
      if (driverLicenseCategory && DriverLicenseCategory[value] & driverLicenseCategory) {
        values.push(`Category ${value}`);
      }
    }

    if (!values.length) {
      values.push('No data');
    }

    return values;
  }, [driverLicenseCategory]);

  return (
    <Grid container spacing={4} className={classNames.InfoContainer}>
      <InfoItem image={member} title="Date of account creation">
        <Typography color="textPrimary" variant="subtitle1">
          {createDateTime(dateOfAccountCreation).toFormat(DateTimeFormat.FULL)}
        </Typography>
      </InfoItem>
      <InfoItem image={birthdayIcon} title="Birthday date">
        <Typography color="textPrimary" variant="subtitle1">
          {createDateTime(birthday).toFormat(DateTimeFormat.BIRTHDAY)}
        </Typography>
      </InfoItem>
      <InfoItem image={bag} title="Working experience">
        <Typography color="textPrimary" variant="subtitle1">
          {getWorkingExperienceByMonths(monthExperience)}
        </Typography>
      </InfoItem>
      <InfoItem image={education} title="Candidate Education">
        <Typography color="textPrimary" variant="subtitle1">
          {educations.map((el) => (
            <div key={el}>{el}</div>
          ))}
        </Typography>
      </InfoItem>
      <InfoItem image={language} title="Languages">
        <Typography color="textPrimary" variant="subtitle1">
          {languages.map((el) => (
            <div key={el.Name + el.Level.Id}>
              {el.Name} - {el.Level.Name}
            </div>
          ))}
        </Typography>
      </InfoItem>
      <InfoItem image={license} title="Driving License">
        <Typography color="textPrimary" variant="subtitle1">
          {driverLicenseCategoryArray.map((str, idx) => (
            <div key={idx}>{str}</div>
          ))}
        </Typography>
      </InfoItem>
      <InfoItem image={permissionIcon} title="Work Permission">
        <Typography color="textPrimary" variant="subtitle1">
          {getPermissionText(permission)}
        </Typography>
      </InfoItem>
    </Grid>
  );
};
