import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

export const useLoginStyles = makeStyles((theme) => ({
  Login: {
    background: grey[50],
    border: 0,
    borderRadius: 3,
    boxShadow: `0 1px 2px 2px ${grey[100]}`,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    height: '100vh',
  },
  LogoIcon: {
    position: 'absolute',
    left: 20,
    top: 20,
  },
  FormGroup: {
    width: '40%',
    height: '25%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-around',
  },
  LoginButton: {
    borderRadius: '24px',
    width: '30%',
    margin: '0 auto',
    background: theme.palette.primary.main,
  },
}));
