import React, { FunctionComponent, createContext, useContext, useRef, useCallback, useState } from 'react';

export interface ISelectedFilterItem {
  label: string;
  params: string[];
}

interface ISelectedFilters {
  set(item: ISelectedFilterItem): void;
  remove(key: string): void;
  getFilters(): ISelectedFilterItem[];
}

const defaultValue: ISelectedFilters = {
  set: () => {},
  remove: () => undefined,
  getFilters: () => [],
};

type SelectedFiltersStorage = Map<string, ISelectedFilterItem>;

export const SelectedFiltersContext = createContext<ISelectedFilters>(defaultValue);

export const SelectedFiltersProvider: FunctionComponent = (props) => {
  const { current: selectedFiltersStorage } = useRef<SelectedFiltersStorage>(new Map());
  const [mapChanged, setMapChanged] = useState(false);

  const set = useCallback(
    (item: ISelectedFilterItem) => {
      selectedFiltersStorage.set(item.label, item);
      setMapChanged(true);
    },
    [selectedFiltersStorage]
  );

  const remove = useCallback(
    (label: string) => {
      selectedFiltersStorage.delete(label);
      setMapChanged(true);
    },
    [selectedFiltersStorage]
  );

  const getFilters = useCallback(() => {
    return Array.from(selectedFiltersStorage.values());
  }, [mapChanged]);

  return (
    <SelectedFiltersContext.Provider value={{ set, remove, getFilters }}>
      {props.children}
    </SelectedFiltersContext.Provider>
  );
};

export const useSelectedFiltersContext = () => useContext(SelectedFiltersContext);
