import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import useAxios from 'axios-hooks';
import {
  getFormInput,
  getFormRadioGroup,
  getFormSelect,
  getFormTextArea,
  getJobTypeField,
  getLngCheckboxField,
} from 'components/FormComponents/getFormInput';
import { LocationAutoComplete } from 'components/FormComponents/GoogleMapComponents/AutoComplete';
import { PhotoGroup } from 'components/FormComponents/PhotoGroup';
import { Loading } from 'components/Loading';
import { StyledCancelButton } from 'components/ui/CancelButton';
import { StyledSaveButton } from 'components/ui/SaveButton';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { useHistory, useParams } from 'react-router';
import { HttpService } from 'services/http/axios';
import { FsService } from 'services/http/fsService';
import { IGeoLocation } from 'services/http/geo/interfaces';
import { ICategory } from 'services/http/interfaces';
import { JobService } from 'services/http/jobs';
import { useNotificationContext } from 'utils/notificationContext';
import { formSubmitCatchAsync } from 'utils/submitFormCatchAsync';
import { validateExternalJob } from 'utils/validations/validateExternalJob';
import { useConfirm } from 'material-ui-confirm';
import { IGetJobsItem, PaymentPeriod } from 'services/http/jobs/interfaces';
import { isEqual } from 'lodash';

export const jobExperienceOptions = [
  {
    value: '0',
    label: 'Any',
  },
  {
    value: '1',
    label: 'Over 1 year',
  },
  {
    value: '2',
    label: 'Over 3 years',
  },
];
export const jobStartDateOptions = [
  {
    value: '0',
    label: 'Immediate Start',
  },
  {
    value: '1',
    label: 'No exact date',
  },
];

export const currencyOptions = [
  { value: 'EUR', label: 'EUR' },
  { value: 'DKK', label: 'DKK' },
];

export interface IExternaJoblDetailsForm {
  newLogoFile?: File | null;
  name: string;
  categoryId: string;
  description: string;
  wage: string;
  currency: string;
  url: string;
  companyName: string;
  companyLogo: string;
  location?: IGeoLocation;
  experience: string;
  jobType: string | null;
  startDate: '0' | '1';
  languages: string[];
}
const useStyles = makeStyles({
  full: {
    width: '100%',
  },
  map: {
    height: 300,
    marginTop: 12,
  },
  photo: {},
});

const FormInput = getFormInput<IExternaJoblDetailsForm>();
const FormSelect = getFormSelect<IExternaJoblDetailsForm>();
const FormTextArea = getFormTextArea<IExternaJoblDetailsForm>();
const FormFormRadioGroup = getFormRadioGroup<IExternaJoblDetailsForm>();
const FormJobType = getJobTypeField<IExternaJoblDetailsForm>();
const FormLngCheckboxGroup = getLngCheckboxField<IExternaJoblDetailsForm>();

const initialValues: IExternaJoblDetailsForm = {
  name: '',
  categoryId: '',
  currency: 'EUR',
  location: undefined,
  description: '',
  wage: '',
  url: '',
  companyName: '',
  companyLogo: '',
  experience: '0',
  jobType: '1',
  startDate: '0',
  languages: [],
};

export const DetailsForm = () => {
  const { jobId } = useParams<{ jobId: string }>();
  const [{ data: categories }] = useAxios<ICategory[]>(`${HttpService.env.adminAPI}/jobs/categories`);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState<IGetJobsItem | undefined>(undefined);

  useEffect(() => {
    if (jobId === '0') {
      return;
    }

    setDetailsLoading(true);
    JobService.getById(jobId)
      .then((res) => {
        setJobDetails(res);
      })
      .finally(() => {
        setDetailsLoading(false);
      });
  }, [jobId]);

  const categoryOptions = useMemo(() => (categories || []).map((v) => ({ value: v.Id, label: v.Name })), [categories]);
  const initialValues = useMemo(() => getInitialValues(jobDetails), [jobDetails]);

  const confirm = useConfirm();
  const history = useHistory();
  const { setNotification } = useNotificationContext();

  const isNew = useMemo(() => jobId === '0', [jobId]);
  const title = useMemo(() => (isNew ? 'Add new external job' : 'jopa'), [isNew]);

  const submitHandler = useCallback(
    formSubmitCatchAsync<IExternaJoblDetailsForm>(
      async (formData: IExternaJoblDetailsForm, formikHelpers: FormikHelpers<IExternaJoblDetailsForm>) => {
        const requestBody = await (isNew
          ? getCreateRequestBody(formData)
          : getUpdateRequestBody(formData, initialValues));

        if (!requestBody) {
          formikHelpers.setStatus('No data was provided');
          formikHelpers.setSubmitting(false);

          return;
        }

        // https://sandboxworkee.teamtailor.com/jobs/2960159-yan-test-5/applications/new?promotion=709307-workee-qa
        await JobService.saveExternalJob(jobId, requestBody);

        const text = isNew
          ? `${requestBody.name} job was successfully created`
          : `${requestBody.Name} job was successfully updated`;
        formikHelpers.setSubmitting(false);
        setNotification({ text, severity: 'success' });
        history.push('/externaljobs');
      }
    ),
    [isNew, initialValues]
  );

  const cancelHandler = useCallback(() => {
    confirm({ description: 'Data will be lost, are you sure you want to cancel current changes?' })
      .then(() => history.push('/externaljobs'))
      .catch(() => console.log('error'));
  }, [history]);

  if (!categoryOptions.length || detailsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h1">{title}</Typography>
      <Formik initialValues={initialValues} onSubmit={submitHandler} validate={validateExternalJob}>
        {(props) => <InnerForm {...props} categoryOptions={categoryOptions} cancelHandler={cancelHandler} />}
      </Formik>
    </>
  );
};

interface IInnerFormProps {
  cancelHandler: () => void;
  categoryOptions: { value: string; label: string }[];
}

const InnerForm = ({
  status,
  isSubmitting,
  cancelHandler,
  categoryOptions,
}: FormikProps<IExternaJoblDetailsForm> & IInnerFormProps) => {
  const classNames = useStyles();

  return (
    <Box mt={3}>
      <Form>
        <Grid container style={{ width: 750 }} spacing={2}>
          <Grid item xs={6}>
            <FormInput label={'Name'} name={'name'} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <FormSelect
              label={'Category'}
              name={'categoryId'}
              options={categoryOptions}
              placeholder="Select category"
              fullWidth
            />
          </Grid>
          <Grid item xs={2}>
            <FormSelect label={'Currency'} name={'currency'} options={currencyOptions} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <FormInput label={'Wage'} name={'wage'} fullWidth type="number" />
          </Grid>
          <Grid item xs={6}>
            <FormInput label={'URL'} name={'url'} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <LocationAutoComplete inputClassName={classNames.full} mapClassName={classNames.map} />
          </Grid>
          <Grid item xs={6}>
            <FormFormRadioGroup label={'Experience'} name={'experience'} options={jobExperienceOptions} />
          </Grid>
          <Grid item xs={6}>
            <FormJobType label={'Job type'} name={'jobType'} />
          </Grid>
          <Grid item xs={6}>
            <FormFormRadioGroup label={'Start date'} name={'startDate'} options={jobStartDateOptions} />
          </Grid>
          <Grid item xs={6}>
            <FormLngCheckboxGroup label={'Required languages'} name={'languages'} />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea name="description" label="Description" placeholder="Description" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <PhotoGroup<IExternaJoblDetailsForm>
                  name="companyLogo"
                  fileFieldName="newLogoFile"
                  containerClassName={classNames.photo}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput label={'Company Name'} name={'companyName'} fullWidth />
              </Grid>
            </Grid>
          </Grid>
          {status && (
            <Grid item xs={12}>
              <Alert className={classNames.full} severity="error">
                {status}
              </Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box mt={2} mb={3}>
              <Grid container wrap="nowrap" spacing={2} justify="flex-end">
                <Grid item>
                  <StyledCancelButton onClick={cancelHandler}>Cancel</StyledCancelButton>
                </Grid>
                <Grid item>
                  <StyledSaveButton type="submit">{isSubmitting ? <Loading /> : 'Save'}</StyledSaveButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};

const getInitialValues = (jobDetails?: IGetJobsItem): IExternaJoblDetailsForm => {
  if (!jobDetails) {
    return initialValues;
  }

  return {
    name: jobDetails.Name,
    categoryId: jobDetails.Category.Id,
    currency: jobDetails.Salary?.Currency || 'EUR',
    wage: jobDetails.Salary?.Amount?.toString() || '',
    description: jobDetails.Description,
    companyName: jobDetails.Company.Name,
    companyLogo: jobDetails.Company.Logo || '',
    url: jobDetails.Url || '',
    location: jobDetails.Location,
    experience: '' + jobDetails.Experience,
    jobType: '' + jobDetails.JobType,
    startDate: jobDetails.StartDate ? '0' : '1',
    languages: jobDetails.Languages || [],
  };
};

const getUpdateRequestBody = async (formData: IExternaJoblDetailsForm, initialValues: IExternaJoblDetailsForm) => {
  const {
    name,
    categoryId,
    companyLogo,
    newLogoFile,
    companyName,
    location,
    wage: wageForm,
    currency,
    url,
    description,
    experience: experienceForm,
    jobType: jobTypeForm,
    languages,
    startDate: startDateForm,
  } = formData;
  const wage = Number(wageForm) || null;
  const experience = Number(experienceForm);
  const jobType = Number(jobTypeForm) || 1;
  const StartDate = startDateForm === '0' ? new Date('1991-04-12T00:00:00.000Z') : null;

  const requestData: any = {
    Name: name,
    StartDate,
  };

  if (!isEqual(categoryId, initialValues.categoryId)) {
    requestData['Category'] = { Id: categoryId };
  }

  let logo = companyLogo;

  if (newLogoFile) {
    const { Uri } = await FsService.uploadFile(newLogoFile);
    logo = Uri;
  }

  if (!isEqual(logo, initialValues.companyLogo) || !isEqual(companyName, initialValues.companyName)) {
    requestData['Company'] = { Name: companyName, Logo: logo };
  }

  if (!isEqual(location, initialValues.location)) {
    requestData['Location'] = {
      Latitude: location?.Latitude,
      Longitude: location?.Longitude,
    };
  }

  if (!isEqual(wage, initialValues.wage) || !isEqual(currency, initialValues.currency)) {
    requestData['Salary'] = wage
      ? {
          Amount: wage,
          Period: PaymentPeriod.Hourly,
          Currency: currency,
        }
      : null;
  }

  if (!isEqual(url, initialValues.url)) {
    requestData['Url'] = url;
  }

  if (!isEqual(description, initialValues.description)) {
    requestData['Description'] = description;
  }

  if (!isEqual('' + experience, '' + initialValues.experience)) {
    requestData['Experience'] = experience;
  }

  if (!isEqual('' + jobType, '' + initialValues.jobType)) {
    requestData['JobType'] = jobType;
  }

  if (!isEqual(languages, initialValues.languages)) {
    requestData['Languages'] = languages;
  }

  return requestData;
};

const getCreateRequestBody = async (formData: IExternaJoblDetailsForm) => {
  const {
    companyLogo,
    newLogoFile,
    location,
    currency,
    wage: wageForm,
    startDate: startDateForm,
    experience: experienceForm,
    jobType: jobTypeForm,
    ...data
  } = formData;
  let logo = companyLogo;

  if (newLogoFile) {
    const { Uri } = await FsService.uploadFile(newLogoFile);
    logo = Uri;
  }

  const experience = Number(experienceForm);
  const wage = Number(wageForm) || null;
  const jobType = Number(jobTypeForm) || 1;
  const startDate = startDateForm === '0' ? new Date('1991-04-12T00:00:00.000Z') : null;

  return {
    ...data,
    companyLogo: logo,
    experience,
    jobType,
    startDate,
    salary: wage
      ? {
          Amount: wage,
          Period: PaymentPeriod.Hourly,
          Currency: currency,
        }
      : null,
    location: {
      Latitude: location?.Latitude,
      Longitude: location?.Longitude,
    },
  };
};
