import React, { useCallback, useMemo } from 'react';
import { Box, Button, Grid, IconButton, InputAdornment, Typography } from '@material-ui/core';
import useAxios from 'axios-hooks';
import { useHistory, useParams } from 'react-router-dom';
import { HttpService } from 'services/http/axios';
import { IGetVacancy } from 'services/http/jobs/interfaces';
import { Loading } from 'components/Loading';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { getFormInput, getFormTextArea } from 'components/FormComponents/getFormInput';
import { makeStyles } from '@material-ui/styles';
import { StyledSaveButton } from 'components/ui/SaveButton';
import { useConfirm } from 'material-ui-confirm';
import { StyledCancelButton } from 'components/ui/CancelButton';
import { formSubmitCatchAsync } from 'utils/submitFormCatchAsync';
import { Alert } from '@material-ui/lab';
import { CompaniesService } from 'services/http/company';
import DeleteIcon from '@material-ui/icons/Delete';
import { useNotificationContext } from 'utils/notificationContext';

const FormInput = getFormInput<IEditVacancyForm>();
const FormTextArea = getFormTextArea<IEditVacancyForm>();

const useStyles = makeStyles({
  form: {
    maxWidth: 2000,
  },
  full: {
    width: '100%',
  },
});
interface IQuestionEditForm {
  text: string;
}
interface IEditVacancyForm {
  name: string;
  description: string;
  schedule: string;
  questions: IQuestionEditForm[];
}

const initialValues: IEditVacancyForm = {
  name: '',
  description: '',
  schedule: '',
  questions: [],
};

const getRequestBody = (formData: IEditVacancyForm | undefined): Partial<IGetVacancy> | undefined => {
  if (!formData) {
    return;
  }

  return {
    Name: formData.name,
    Description: formData.description,
    Schedule: formData.schedule,
    Form: formData.questions.length ? { Questions: formData.questions.map((q) => ({ Text: q.text })) } : null,
  };
};

const getInitialValues = (jobDetails: IGetVacancy | undefined): IEditVacancyForm => {
  if (!jobDetails) {
    return initialValues;
  }

  return {
    name: jobDetails.Name,
    description: jobDetails.Description,
    schedule: jobDetails.Schedule,
    questions: jobDetails.Form ? (jobDetails.Form.Questions || []).map((q) => ({ text: q.Text })) : [],
  };
};

export const JobEditForm = () => {
  const { companyId, jobId } = useParams<{ companyId: string; jobId: string }>();
  const { setNotification } = useNotificationContext();
  const confirm = useConfirm();
  const history = useHistory();

  const [{ data: jobDetails, loading: detailsLoading }] = useAxios<IGetVacancy>(
    `${HttpService.env.adminAPI}/companies/${companyId}/jobs/${jobId}`,
    { useCache: false }
  );

  const initialValues = useMemo(() => getInitialValues(jobDetails), [jobDetails]);

  const cancelHandler = useCallback(() => {
    confirm({ description: 'Data will be lost, are you sure you want to cancel current changes?' })
      .then(() => {
        history.push(`/company/${companyId}/job/${jobId}`);
      })
      .catch(() => console.log(''));
  }, [history, companyId, jobId]);

  const submitHandler = useCallback(
    formSubmitCatchAsync<IEditVacancyForm>(
      async (formData: IEditVacancyForm | undefined, formikHelpers: FormikHelpers<IEditVacancyForm>) => {
        const requestBody = await getRequestBody(formData);

        if (!requestBody) {
          formikHelpers.setStatus('No data was provided');
          formikHelpers.setSubmitting(false);

          return;
        }

        await CompaniesService.editCompanyVacancy(companyId, jobId, requestBody);
        formikHelpers.setSubmitting(false);

        setNotification({ text: `${requestBody.Name} job was successfully updated`, severity: 'success' });
        history.push(`/company/${companyId}/job/${jobId}`);
      }
    ),
    [companyId, jobId, history]
  );

  if (detailsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Typography variant="h1">Edit Job</Typography>
      <Formik initialValues={initialValues} onSubmit={submitHandler}>
        {(props) => <InnerForm {...props} cancelHandler={cancelHandler} />}
      </Formik>
    </>
  );
};

interface IInnerFormProps {
  cancelHandler: () => void;
}

const InnerForm = ({
  isSubmitting,
  status,
  cancelHandler,
  values,
}: FormikProps<IEditVacancyForm> & IInnerFormProps) => {
  const classes = useStyles();
  const { questions } = values;

  return (
    <Box pt={2}>
      <Form className={classes.form}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormInput label="Name" name="name" wrapperClassName={classes.full} />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea label="Description" name="description" wrapperClassName={classes.full} />
          </Grid>
          <Grid item xs={12}>
            <FormTextArea label="Schedule (optional)" name="schedule" wrapperClassName={classes.full} />
          </Grid>
          <Grid item xs={12}>
            {status && (
              <Alert className={classes.full} severity="error">
                {status}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography>Additional questions</Typography>
            <Box mt={2}>
              <FieldArray name="questions">
                {({ push, remove }) => (
                  <>
                    <Box mt={2}>
                      {questions.length > 0 && (
                        <Grid container spacing={2}>
                          {questions.map((question, idx) => (
                            <Grid item xs={12} key={idx}>
                              <FormInput
                                name={`questions.${idx}.text`}
                                wrapperClassName={classes.full}
                                startAdornment={<InputAdornment position="start">{`${idx + 1}.`}</InputAdornment>}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <IconButton onClick={() => remove(idx)}>
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  </InputAdornment>
                                }
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </Box>
                    <Box mt={2}>
                      <Button onClick={() => push({ text: '' })} variant="text" disabled={questions.length > 2}>
                        Add question
                      </Button>
                    </Box>
                  </>
                )}
              </FieldArray>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Grid container justify="flex-end" spacing={2}>
              <Grid item>
                <StyledCancelButton onClick={cancelHandler}>Cancel</StyledCancelButton>
              </Grid>
              <Grid item>
                <StyledSaveButton type="submit">{isSubmitting ? <Loading /> : 'Save'}</StyledSaveButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Box>
  );
};
