import React, { useCallback, useMemo, useState, useEffect } from 'react';
import useAxios from 'axios-hooks';
import { HttpService } from '../../../../services/http/axios';
import { useCheckboxState } from '../../../../utils/useCheckboxState';
import { CompaniesParams, QueryParams } from '../../../../enums';
import { useQuery } from '../../../../utils/useQuery';
import { useConfirm } from 'material-ui-confirm';
import { useParams } from 'react-router-dom';
import { TableLayout } from '../../../../layouts/Table';
import { CustomTableHead, ITableHeadCellData } from '../../../../components/TableComponents/TableHead';
import { TableBody, TableCell, Typography } from '@material-ui/core';
import { CustomTableRow } from '../../../../components/TableComponents/TableRow';
import { CustomTableBodyCheckboxCell } from '../../../../components/TableComponents/TableCheckboxCell';
import { createDateTime, DateTimeFormat } from '../../../../utils/createDateTime';
import { Badge } from '../../../../components/Badge';
import { CustomModal } from '../../../../components/CustomModal';
import { ModalApplicationsBody } from './ModalApplicationsBody';
import { useCompanyVacanciesStyles } from './CompanyVacanciesStyles';
import { deleteByIds } from '../../../../utils/deleteByIds';
import { CompaniesService } from '../../../../services/http/company';
import { useNotificationContext } from '../../../../utils/notificationContext';
import { IGetVacancy } from '../../../../services/http/jobs/interfaces';
import { getJobStatusBadgeData } from '../../../../utils/getJobStatusBadgeData';
import { TableNumberOfCell } from '../../../../components/TableComponents/TableRow/TableNumberOfCell';
import { PageTitleWithCount } from '../../../../components/PageTitleWithCount';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { useAccount } from 'utils/useAccount';

enum ModalModes {
  PRESENT_APPLICATIONS = 'present_applications',
}

enum SortNames {
  NAME = 'Name',
  APPLICATIONS = 'Statistics.ApplicationsCount',
  SHORTLISTED = 'Statistics.ShortlistsCount',
  REJECTED = 'Statistics.RejectsCount',
  PUBLISH_DATE = 'PublishDate',
}

const tableHeaders: ITableHeadCellData[] = [
  { text: '#' },
  {
    text: 'VACANCY NAME',
    sortName: SortNames.NAME,
  },
  {
    text: 'APPLICATIONS',
    sortName: SortNames.APPLICATIONS,
  },
  {
    text: 'SHORTLISTED',
    sortName: SortNames.SHORTLISTED,
  },
  {
    text: 'REJECTED',
    sortName: SortNames.REJECTED,
  },
  {
    text: 'DATE',
    sortName: SortNames.PUBLISH_DATE,
  },
  {
    text: 'STATUS',
  },
];

export const companyVacancyModalParams = [
  CompaniesParams.MODAL_VACANCY_ID,
  CompaniesParams.APPLICANTS_STATUS,
  CompaniesParams.MODAL_VACANCY_MODE,
];

export const CompanyVacancies = () => {
  const { currQueryParams, removeParams, setParam, getFilteredParams } = useQuery();
  const [modalHeading, setModalHeading] = useState<string | undefined>(undefined);
  const [modalItemsCount, setModalItemsCount] = useState<number | undefined>(undefined);
  const history = useHistory();
  const { setNotification } = useNotificationContext();
  const confirm = useConfirm();
  const classNames = useCompanyVacanciesStyles();
  const { companyId: id } = useParams<{ companyId: string }>();
  const vacanciesParamsString = useMemo(() => getFilteredParams(companyVacancyModalParams).toString(), [
    currQueryParams,
  ]);
  const { isAdmin } = useAccount();

  const [{ data: companyVacancies = [], loading: vacanciesLoading }, fetchVacancies] = useAxios<IGetVacancy[]>(
    `${HttpService.env.adminAPI}/companies/${id}/jobs?${vacanciesParamsString}`
  );

  useEffect(() => {
    if (currQueryParams.get('sortBy') || currQueryParams.get('sortByDesc')) {
      return;
    }

    setParam(QueryParams.SORT_BY_DESC, SortNames.PUBLISH_DATE);
  }, []);
  const currentApplicantsStatus = currQueryParams.get(CompaniesParams.APPLICANTS_STATUS);

  useEffect(() => {
    switch (currentApplicantsStatus) {
      case null:
        setModalHeading('Applicants');
        break;
      case '2':
        setModalHeading('Shortlisted');
        break;
      case '3':
        setModalHeading('Rejected');
        break;
      default:
        break;
    }
  }, [currentApplicantsStatus]);

  const {
    checkedItemsState,
    selectPage,
    unselectPage,
    checkboxOnChange,
    getCheckedItemsIds,
  } = useCheckboxState<IGetVacancy>(companyVacancies || []);

  const deleteHandler = useCallback(() => {
    const checkedItemsIds = getCheckedItemsIds();

    deleteByIds(
      checkedItemsIds,
      (jobId: string) => CompaniesService.deleteCompanyJob(id, jobId),
      setNotification,
      `${checkedItemsIds.length} vacancies deleted`,
      confirm,
      'These jobs will be immediately deleted'
    )
      .then(() => fetchVacancies())
      .catch(() => {});
  }, [id, CompaniesService.deleteCompany, setNotification, confirm, getCheckedItemsIds]);

  const closeModal = useCallback(() => {
    removeParams(companyVacancyModalParams);
  }, [removeParams]);

  const openApplicationsModal = useCallback(
    (id: string, status?: '2' | '3') => {
      status && setParam(CompaniesParams.APPLICANTS_STATUS, status);
      setParam(CompaniesParams.MODAL_VACANCY_ID, id);
      setParam(CompaniesParams.MODAL_VACANCY_MODE, ModalModes.PRESENT_APPLICATIONS);
    },
    [setParam]
  );

  const directToJobProfile = useCallback(
    (jobId: string) => {
      history.push(`/company/${id}/job/${jobId}`);
    },
    [id]
  );

  return (
    <React.Fragment>
      <Typography className={classNames.TableTitle} variant="h1">
        Vacancies({companyVacancies.length})
      </Typography>
      {!vacanciesLoading && (
        <React.Fragment>
          <CustomModal
            open={Boolean(
              currQueryParams.get(CompaniesParams.MODAL_VACANCY_ID) &&
                currQueryParams.get(CompaniesParams.MODAL_VACANCY_MODE) === ModalModes.PRESENT_APPLICATIONS
            )}
            onClose={closeModal}
            heading={<PageTitleWithCount title={modalHeading || ''} itemsCount={modalItemsCount} />}
          >
            <ModalApplicationsBody setModalItemsCount={setModalItemsCount} />
          </CustomModal>
        </React.Fragment>
      )}
      <TableLayout
        loading={vacanciesLoading}
        isEmpty={companyVacancies.length === 0}
        noItemsMessage="No vacancies found"
      >
        <CustomTableHead
          tableHeaders={tableHeaders}
          selectPage={selectPage}
          unselectPage={unselectPage}
          checkedItemsState={checkedItemsState}
          deleteHandler={deleteHandler}
          editable={isAdmin}
        />
        <TableBody>
          {companyVacancies.map((el, index) => {
            const badgeStatusData = getJobStatusBadgeData(el.Status);

            return (
              <CustomTableRow key={el.Id}>
                <CustomTableBodyCheckboxCell
                  isChecked={Boolean(checkedItemsState[el.Id])}
                  onChange={checkboxOnChange}
                  id={el.Id}
                  editable={isAdmin}
                />
                <TableCell>{index + 1}</TableCell>
                <TableCell className={classNames._clickable} onClick={() => directToJobProfile(el.Id)}>
                  {el.Name}
                </TableCell>
                <TableNumberOfCell
                  className={clsx({ [classNames._clickable]: !!el.Statistics?.ApplicationsCount })}
                  count={el.Statistics?.ApplicationsCount}
                  itemName="Applicants"
                  onClick={el.Statistics?.ApplicationsCount ? () => openApplicationsModal(el.Id) : undefined}
                />

                <TableNumberOfCell
                  className={clsx({ [classNames._clickable]: !!el.Statistics?.ShortlistsCount })}
                  onClick={el.Statistics?.ShortlistsCount ? () => openApplicationsModal(el.Id, '2') : undefined}
                  count={el.Statistics?.ShortlistsCount}
                  itemName="ShortListed"
                />

                <TableNumberOfCell
                  className={clsx({ [classNames._clickable]: !!el.Statistics?.RejectsCount })}
                  onClick={el.Statistics?.RejectsCount ? () => openApplicationsModal(el.Id, '3') : undefined}
                  count={el.Statistics?.RejectsCount}
                  itemName="Rejected"
                />

                <TableCell>{createDateTime(el.PublishDate).toFormat(DateTimeFormat.FULL)}</TableCell>
                <TableCell style={{ width: 120 }}>
                  <Badge type={badgeStatusData.type} text={badgeStatusData.text} />
                </TableCell>
              </CustomTableRow>
            );
          })}
        </TableBody>
      </TableLayout>
    </React.Fragment>
  );
};
