import React, { useCallback, useMemo, useState } from 'react';
import useAxios from 'axios-hooks';
import { PageTitleWithCount } from 'components/PageTitleWithCount';
import { MainLayout } from 'layouts/Home/MainLayout';
import { HttpService } from 'services/http/axios';
import { Box, Button, IconButton, TableBody, TableCell } from '@material-ui/core';
import { CustomTableHead, ITableHeadCellData } from 'components/TableComponents/TableHead';
import { TableLayout } from 'layouts/Table';
import { IAccount } from 'services/http/auth/interfaces';
import { IGetPaginationResponse } from 'services/http/interfaces';
import { CustomTableRow } from 'components/TableComponents/TableRow';
import { TableUserTypeCell } from 'components/TableComponents/TableRow/TableUserTypeCell';
import { AddTeamMember } from './AddTeamMember';
import { AccountRole, QueryParams } from 'enums';
import { useQuery } from 'utils/useQuery';
import { useAccount } from 'utils/useAccount';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { useConfirm } from 'material-ui-confirm';
import { AuthService } from 'services/http/auth';
import { EditTeamMemberDrawer } from './EditTeamMemberDrawer';

const count = '9';

const tableHeaders: ITableHeadCellData[] = [
  { text: '#' },
  { text: 'Name' },
  { text: 'Email' },
  { text: 'Company role' },
  { text: '' },
];

export const TeamMain = () => {
  const [openedAddDrawer, setOpenedAddDrawer] = useState<boolean>(false);
  const [editableAccount, setEditableAccount] = useState<string>('');

  const { myAccount, isAdmin } = useAccount();
  const confirm = useConfirm();
  const { currQueryParams, getFilteredParams } = useQuery();
  const page = currQueryParams.get(QueryParams.PAGE) || '1';
  const accountsParams = useMemo(() => getFilteredParams([]).toString(), [getFilteredParams]);

  const [{ data: accountsResponse, loading: profilesLoading }, fetchAccounts] = useAxios<
    IGetPaginationResponse<IAccount>
  >(
    {
      url: `${HttpService.env.adminAPI}/users?roles=8&roles=16&${accountsParams}`,
      params: {
        count,
        sortByDesc: 'CreatedOn',
      },
    },
    { useCache: false }
  );

  const closeAddDrawer = useCallback(() => {
    setOpenedAddDrawer(false);
  }, []);

  const openAddDrawer = useCallback(() => {
    setOpenedAddDrawer(true);
  }, []);

  const onAddHandler = useCallback(() => {
    closeAddDrawer();
    fetchAccounts();
  }, [fetchAccounts]);

  const onDeleleTeamHandler = useCallback(
    async (accountId: string) => {
      try {
        await confirm({ description: 'Do you want to remove your team member?' });
        await AuthService.patchUser(accountId, { Role: AccountRole.CANDIDATE });
      } catch (error) {
        return;
      }

      fetchAccounts();
    },
    [fetchAccounts]
  );

  const onEditedHandler = useCallback(() => {
    setEditableAccount('');
    fetchAccounts();
  }, [fetchAccounts]);

  return (
    <MainLayout loading={!accountsResponse && profilesLoading}>
      <section>
        <PageTitleWithCount title="Workee Team manager" />
        <Box mt={2} mb={2}>
          <Button onClick={openAddDrawer}>+ Add a team member</Button>
        </Box>

        {accountsResponse && (
          <TableLayout
            loading={profilesLoading}
            numberOfPages={Math.ceil(accountsResponse.TotalItemCount / parseInt(count))}
            currentPage={parseInt(page)}
            isEmpty={false}
            noItemsMessage={'No team member found'}
          >
            <CustomTableHead tableHeaders={tableHeaders} editable={false} />
            <TableBody>
              {accountsResponse.Page.map((account, idx) => (
                <CustomTableRow key={account.Id}>
                  <TableCell></TableCell>
                  <TableCell>{(accountsResponse.PageIndex - 1) * parseInt(count) + idx + 1}</TableCell>
                  <TableCell>{account.FullName}</TableCell>
                  <TableCell>{account.Email}</TableCell>
                  <TableUserTypeCell type={account.Role} />
                  <TableCell style={{ width: '50px' }}>
                    {isAdmin && (
                      <IconButton onClick={() => setEditableAccount(account.Id)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    )}
                    {isAdmin && myAccount.Id !== account.Id && (
                      <IconButton onClick={() => onDeleleTeamHandler(account.Id)}>
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </TableCell>
                </CustomTableRow>
              ))}
            </TableBody>
          </TableLayout>
        )}
        {openedAddDrawer && <AddTeamMember onCancel={closeAddDrawer} onAdd={onAddHandler} />}
        <EditTeamMemberDrawer
          accountId={editableAccount}
          closeDrawer={() => setEditableAccount('')}
          onEdited={onEditedHandler}
        />
      </section>
    </MainLayout>
  );
};
