import { makeStyles } from '@material-ui/core/styles';
import profileDropdown from '../../../assets/dropdown.svg';

export const useProfileStyles = makeStyles((theme) => ({
  ProfileWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  MenuList: {
    minWidth: 140,
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: theme.palette.background.paper,
    borderRadius: 8,
    boxShadow: '0px 4px 12px rgba(120, 132, 155, 0.1)',
  },
  MenuItem: {
    fontSize: 14,
    color: theme.palette.text.secondary,
    cursor: 'pointer',
  },
  MenuButton: {
    cursor: 'pointer',
    '&::after': {
      content: `url(${profileDropdown})`,
      display: 'inline-block',
      width: 10,
      marginLeft: 10,
    },
  },
}));
