import { makeStyles } from '@material-ui/core/styles';

export const useTableFooterStyles = makeStyles((theme) => ({
  TableFooter: {
    display: 'flex',
    justifyContent: 'center',
    margin: '18px 0',
    borderRadius: 6,
    '& button': {
      '&:first-child': {
        marginRight: 25,
      },
      '&:last-child': {
        marginLeft: 25,
      },
    },
  },
  TextCell: {
    padding: '9px 14px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  _active: {
    background: theme.palette.background.paper,
  },
  NumberCell: {
    padding: '9px 14px',
    borderRadius: 6,
    margin: '0px 3px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.background.paper,
    },
  },
}));
