import React, { useCallback, useEffect, useState } from 'react';
import { Polygon } from '@react-google-maps/api';

interface PolygonNewWrapperProps {
  map: google.maps.Map;
  addPolygon: (cityId: string, polygon: google.maps.Polygon) => void;
  removePolygon: (cityId: string) => void;
}

const PolygonNewWrapper = ({ map, addPolygon, removePolygon }: PolygonNewWrapperProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [polygon, setPolygon] = useState<google.maps.Polygon | null>(null);
  const [path, setPath] = useState<{ lat: number; lng: number }[]>([]);

  useEffect(() => {
    const center = map.getCenter().toJSON();
    const lat = +center.lat.toFixed(5);
    const lng = +center.lng.toFixed(5);
    const path = [
      {
        lat: lat - 0.5,
        lng: lng - 1,
      },
      {
        lat: lat + 0.5,
        lng: lng - 1,
      },
      {
        lat: lat + 0.5,
        lng: lng + 1,
      },
      {
        lat: lat - 0.5,
        lng: lng + 1,
      },
      {
        lat: lat - 0.5,
        lng: lng - 1,
      },
    ];
    setPath(path);
  }, []);

  const onLoad = useCallback(
    (pol: google.maps.Polygon) => {
      setPolygon(pol);
      addPolygon('new', pol);
    },
    [setPolygon, addPolygon]
  );

  const onUnmount = useCallback(() => {
    removePolygon('new');
    setPolygon(null);
  }, [setPolygon]);

  return (
    <>
      {!!path.length && (
        <Polygon
          path={path}
          options={{
            editable: true,
            fillColor: '#FFB002',
            strokeColor: '#FFB002',
          }}
          onLoad={onLoad}
          onUnmount={onUnmount}
        />
      )}
    </>
  );
};

export default PolygonNewWrapper;
