import { ILocation } from '../company/interfaces';
import { ICategory } from '../interfaces';

export enum VACANCY_EXPERIENCE {
  ANY,
  OVER_YEAR,
  OVER_THREE_YEARS,
}
export interface IVacancyQuestion {
  Text: string;
}
export enum VacancyType {
  Regular,
  External,
}

export enum PaymentPeriod {
  Hourly,
  Daily,
  Weekly,
  Monthly,
  Yearly,
}

export interface IGetVacancy {
  Status: number;
  ClosingReason: number;
  Id: string;
  Name: string;
  Description: string;
  Wage?: number;
  PublishDate: string;
  Publisher: string;
  ExpireDate: string;
  Experience: VACANCY_EXPERIENCE;
  JobType: number;
  Languages: string[];
  StartDate: string;
  Schedule: string;
  Category: ICategory;
  Location: ILocation;
  Form?: {
    Questions?: IVacancyQuestion[];
  } | null;
  Company: {
    Id: string;
    Name: string;
    State: 0;
    SsCvrConfirmed: true;
  };
  Statistics?: {
    ApplicationsCount: number;
    RejectsCount: number;
    ShortlistsCount: number;
    ExpiresCount: number;
  };
}

export interface IGetJobsItem {
  Id: string;
  Name: string;
  Category: ICategory;
  Company: {
    Id: string;
    Name: string;
    State: 0;
    IsCvrConfirmed: boolean;
    Logo?: string;
  };
  Location: ILocation;
  Statistics?: {
    ApplicationsCount: number;
    ExpiresCount: number;
    RejectsCount: number;
    ShortlistsCount: number;
    ViewsCount: number;
  };
  PublishDate: string;
  StartDate?: string;
  Status: 0 | 1 | 2;
  Description: string;
  JobType: number;
  Url?: string;
  Wage?: number;
  Experience: number;
  Salary?: {
    Amount?: number;
    Currency: string;
    Period: PaymentPeriod;
  };
  Languages: string[];
}

export interface IJobsCountsResponse {
  Active: number;
  Closed: number;
  Deleted: number;
}
