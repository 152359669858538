import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/styles';

export const useSelectedFiltersStyles = makeStyles((theme) => ({
  SelectedFilters: {},
  Title: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    margin: '10px auto 13px 0',
  },
  FilterItemsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  FilterItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px 15px',
    borderRadius: 24,
    margin: 3,
    background: '#FFF',
    fontSize: 12,
  },
  ClearAll: {
    color: theme.palette.text.primary,
    fontSize: 10,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
}));

export const CustomCloseIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
    height: 15,
    lineHeight: 'bold',
    cursor: 'pointer',
  },
}))(CloseIcon);
