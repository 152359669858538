import React from 'react';
import { Typography } from '@material-ui/core';
import { useDetailsDescriptionStyles } from './DescriptionStyles';

interface IDetailsDescription {
  children: React.ReactNode;
  title: string;
}

export const DetailsDescription = ({ children, title }: IDetailsDescription) => {
  const classNames = useDetailsDescriptionStyles();

  return (
    <div className={classNames.Description}>
      <Typography className={classNames.DescriptionTitle} variant="h5">
        {title}
      </Typography>
      <Typography variant="subtitle1">{children}</Typography>
    </div>
  );
};
