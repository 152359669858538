import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useInfoItemStyles } from './InfoStyles';

interface IInfoItemProps {
  image: string;
  title: string;
  children: JSX.Element | JSX.Element[];
  className?: string;
}

export const InfoItem = ({ image, title, children }: IInfoItemProps) => {
  const classNames = useInfoItemStyles();

  return (
    <Grid item className={classNames.root}>
      <div>
        <span className={classNames.ImageWrapper}>
          <img src={image} />
        </span>
        <Typography className={classNames.Title} variant="h5">
          {title}
        </Typography>
        {children}
      </div>
    </Grid>
  );
};
