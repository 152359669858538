import { Box } from '@material-ui/core';
import React from 'react';

import { MainLayout } from '../../../layouts/Home/MainLayout';
import { JobEditForm } from './JobEditForm';

export const JobEditMain = () => {
  return (
    <MainLayout hasBackPath={true}>
      <Box mt={2}>
        <JobEditForm />
      </Box>
    </MainLayout>
  );
};
