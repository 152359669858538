import React from 'react';
import { CategoriesFilter } from 'components/CategoriesFilter';
import { CandidatesParams } from 'enums';
import { HttpService } from 'services/http/axios';
import { FiltersLayout } from '../../../layouts/Home/Filters';
import { SelectedFiltersProvider } from '../../../utils/selectedFiltersContext';
import { EmployeesAge } from './EmployeeAge';
import { LocationFilter } from './Location';

interface IFilterProps {
  isShow: boolean | null;
  hideFilters: () => void;
}

export const Filters = ({ isShow, hideFilters }: IFilterProps) => {
  return (
    <SelectedFiltersProvider>
      <FiltersLayout isShow={isShow} hideFilters={hideFilters}>
        <LocationFilter />
        <CategoriesFilter
          title="Categories"
          queryParam={CandidatesParams.EXPERIENCE_CATEGORY}
          fetchCategoriesUrl={`${HttpService.env.adminAPI}/jobs/categories`}
        />
        <EmployeesAge />
        {/*<StatusFilter />*/}
      </FiltersLayout>
    </SelectedFiltersProvider>
  );
};
