import React from 'react';
import { SideBar } from './SideBar';
import { useHomePageStyles } from './HomeStyles';

interface IMainPageProps {
  children: JSX.Element;
  filters?: JSX.Element;
  isFiltersShown?: boolean;
}

export const HomeLayout = ({ children, filters, isFiltersShown }: IMainPageProps) => {
  const classNames = useHomePageStyles();

  return (
    <div className={classNames.HomeLayout}>
      <SideBar />
      {(!isFiltersShown || !filters) && <div className={classNames.VerticalBorder} />}
      {filters}
      {children}
    </div>
  );
};
