import ValidatorJs from 'validatorjs';
import { IExternaJoblDetailsForm } from 'pages/ExternalJobsEdit/Main/DetailsFrom';

ValidatorJs.register(
  'check_location_available',
  (value) => {
    if (value !== false && value !== true) {
      return false;
    }

    return value;
  },
  'The location is not available'
);

const rules = {
  name: 'required|max:50',
  categoryId: 'required',
  description: 'required|max:2000',
  wage: 'min:1|max:999',
  url: 'required|url',
  companyName: 'required|max:50',
  location: 'required',
  'location.IsAvailable': 'check_location_available',
  jobType: 'required',
};

export function validateExternalJob(values: IExternaJoblDetailsForm) {
  const validation = new ValidatorJs(values, rules);

  validation.check();

  return validation.errors.errors;
}
