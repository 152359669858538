import { makeStyles } from '@material-ui/core/styles';

export const useInfoItemStyles = makeStyles({
  root: {
    minWidth: 150,
  },
  ImageWrapper: {
    borderRadius: '50%',
    background: '#F2F5F9',
    width: 30,
    height: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  Title: {
    margin: '12px 0 18px 0',
  },
});
