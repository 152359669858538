import React, { createContext, FunctionComponent, useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Notification } from '../components/Notification';

type INotificationSeverity = 'error' | 'warning' | 'info' | 'success';

interface INotification {
  text: string;
  severity: INotificationSeverity;
}

interface INotificationState {
  id: string;
  data: INotification;
}

export interface INotificationContext {
  setNotification: (item: INotification) => void;
}

const defaultValue: INotificationContext = {
  setNotification: () => {},
};

const NotificationContext = createContext<INotificationContext>(defaultValue);

export const NotificationProvider: FunctionComponent = ({ children }) => {
  const [notificationState, setNotificationState] = useState<INotificationState[]>([]);

  const remove = useCallback(
    (id: string) => {
      setNotificationState((prevState) => prevState.filter((el) => el.id !== id));
    },
    [setNotificationState]
  );

  const setNotification = useCallback(
    (item: INotification) => {
      setNotificationState((prevState) => [...prevState, { id: uuidv4(), data: item }]);
    },
    [setNotificationState]
  );

  return (
    <NotificationContext.Provider value={{ setNotification }}>
      {notificationState.map(({ id, data: { text, severity } }, index) => {
        return <Notification key={id} text={text} severity={severity} index={index} onAlertClose={() => remove(id)} />;
      })}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
