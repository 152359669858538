import React from 'react';
import { HomeLayout } from 'layouts/Home';
import { SystemMain } from './Main';

export const System = () => {
  return (
    <HomeLayout>
      <SystemMain />
    </HomeLayout>
  );
};
