import { makeStyles } from '@material-ui/core/styles';

export const useFilterItemLayoutStyles = makeStyles((theme) => ({
  FilterItemSection: {
    background: '#FFF',
    borderRadius: 6,
    margin: '14px 0',
  },
  FilterItemBody: {
    padding: 14,
    color: '#78849B',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: 'normal',
  },
  ApplyFilterButton: {
    color: theme.palette.primary.main,
    float: 'right',
    margin: '0 22px 19px 0',
    cursor: 'pointer',
    padding: '7px 10px 7px 10px',
    borderRadius: 24,
    transition: '.6s ease',
    '&:hover': {
      background: 'rgba(255, 176, 2, 0.1)',
    },
  },
  AnimationContainer: {
    transformOrigin: 'top',
  },
}));
