import React, { useCallback, useMemo } from 'react';
import { useTableFooterStyles } from './TableFooterStyles';
import { generatePages } from './generatePages';
import { useQuery } from '../../../utils/useQuery';
import { QueryParams } from '../../../enums';
import { NextButton, PrevButton } from './TableFooterButtons';

interface ICustomTableFooterProps {
  numberOfPages: number;
  currentPage: number;
}

export const CustomTableFooter = ({ numberOfPages, currentPage }: ICustomTableFooterProps) => {
  const classNames = useTableFooterStyles();
  const formattedPagesArr = useMemo(() => generatePages(numberOfPages, currentPage, 7), [numberOfPages, currentPage]);
  const { setParam } = useQuery();

  const setPage = useCallback(
    (pageNumber: number) => {
      setParam(QueryParams.PAGE, `${pageNumber}`);
    },
    [setParam]
  );

  return (
    <section className={classNames.TableFooter}>
      <PrevButton />
      {formattedPagesArr.map((el, index) =>
        el.disabled ? (
          <div className={classNames.TextCell} key={index + el.text}>
            {el.text}
          </div>
        ) : (
          <div
            className={`${classNames.NumberCell} ${currentPage === parseInt(el.text) ? classNames._active : ''}`}
            key={index + el.text}
            onClick={() => setPage(parseInt(el.text))}
          >
            {el.text}
          </div>
        )
      )}
      <NextButton numberOfPages={numberOfPages} />
    </section>
  );
};
