import React from 'react';
import { Routes } from './routes';
import { HashRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { baseTheme } from './theme';
import { ConfirmProvider } from 'material-ui-confirm';
import { NotificationProvider } from './utils/notificationContext';

export const App = () => {
  return (
    <ThemeProvider theme={baseTheme}>
      <ConfirmProvider>
        <NotificationProvider>
          <HashRouter>
            <Routes />
          </HashRouter>
        </NotificationProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
};
