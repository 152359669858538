import { makeStyles } from '@material-ui/core/styles';
import email from '../../../../assets/email.svg';

export const useMainHeaderStyles = makeStyles((theme) => ({
  Info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  LastSeen: {
    padding: '5px 15px',
    margin: '0 15px',
    borderRadius: '13px',
    border: `1px solid ${theme.palette.secondary.dark}`,
    color: '#78849B',
  },
  Email: {
    color: theme.palette.text.secondary,
    marginRight: 10,
    '&:before': {
      content: `url(${email})`,
      marginRight: 4,
    },
  },
  Phone: {
    color: theme.palette.text.secondary,
    fontSize: 14,
  },
  NoAvatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.background.paper,
    width: 94,
    height: 94,
    borderRadius: '50%',
  },
  Avatar: {
    width: 94,
    height: 94,
    borderRadius: '50%',
  },
  WorkingHours: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  WorkingHoursIcon: {
    marginRight: 5,
  },
}));
