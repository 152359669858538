import React from 'react';
import backArrowImg from '../../../../assets/backArrow.svg';
import { useFiltersHideButtonStyles } from './FiltersHideButtonStyles';

interface IFiltersHideButtonProps {
  hide: () => void;
}

export const FiltersHideButton = ({ hide }: IFiltersHideButtonProps) => {
  const classNames = useFiltersHideButtonStyles();

  return (
    <div className={classNames.BackArrowButton} onClick={hide}>
      <img src={backArrowImg} className={classNames.BackArrow} />
    </div>
  );
};
