import React from 'react';
import { Link } from '@material-ui/core';
import { useLocationLinkStyles } from './LocationLinkStyles';
import { ILocation } from '../../services/http/company/interfaces';

interface ILocationLinkProps {
  location: ILocation;
}

export const LocationLink = ({ location }: ILocationLinkProps) => {
  const classNames = useLocationLinkStyles();

  return (
    <div className={classNames.Location}>
      <span>
        {location.Country}, {location.City}
      </span>
      <Link
        href={`https://maps.google.com/?ll=${location.Latitude},${location.Longitude}`}
        className={classNames.Link}
        target="__blank"
      >
        View on map
      </Link>
    </div>
  );
};
