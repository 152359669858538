import React, { useCallback, useMemo, useState } from 'react';
import useAxios from 'axios-hooks';
import { PageTitleWithCount } from 'components/PageTitleWithCount';
import { MainLayout } from 'layouts/Home/MainLayout';
import { HttpService } from 'services/http/axios';
import { IGeoCity } from 'services/http/geo/interfaces';
import { Box, Grid } from '@material-ui/core';
import { CitiesMapWrapper } from './CitiesMapWrapper';
import { CitiesListWrapper } from './CitiesListWrapper';
import { CityEditForm, ICityEditFormData } from './CityEditForm';
import { formSubmitCatchAsync } from 'utils/submitFormCatchAsync';
import { GeoService } from 'services/http/geo';
import { StyledSaveButton } from 'components/ui/SaveButton';
import { useConfirm } from 'material-ui-confirm';

const getGeoCity = (formData: ICityEditFormData, cityId: string, latLngs: google.maps.LatLng[]): IGeoCity => {
  const exterior = latLngs.map((latLng) => ({
    Latitude: +latLng.lat().toFixed(5),
    Longitude: +latLng.lng().toFixed(5),
  }));

  if (
    exterior[0].Latitude !== exterior[exterior.length - 1].Latitude ||
    exterior[0].Longitude !== exterior[exterior.length - 1].Longitude
  ) {
    exterior.push(exterior[0]);
  }

  return {
    Id: cityId,
    Name: formData.name,
    Countries: [
      {
        Code: 'DK',
        Name: 'Denmark',
        NameTranslations: [
          { Culture: 'en-US', Value: 'Denmark' },
          { Culture: 'da-DK', Value: 'Danmark' },
        ],
      },
    ],
    NameTranslations: [
      { Culture: 'en-US', Value: formData.name },
      { Culture: 'da-DK', Value: formData.nameDa },
    ],
    Polygon: {
      Exterior: exterior,
    },
  };
};

export const CitiesMain = () => {
  const [{ data: cities = [], loading: detailsLoading }, fetchCities] = useAxios<IGeoCity[]>(
    `${HttpService.env.searchAPI}/geo/cities`,
    { useCache: false }
  );
  const [hoveredId, setHovered] = useState<string | undefined>();
  const [editableId, setEditable] = useState<string | undefined>(undefined);
  const [polygons, setPolygons] = useState<{ cityId: string; polygon: google.maps.Polygon }[]>([]);
  const [isNew, setIsNew] = useState<boolean>(false);
  const confirm = useConfirm();

  const deleteCity = useCallback(
    async (cityId: string) => {
      try {
        await confirm({ title: 'Deleting a city', description: 'This action is permanent!' });
        await GeoService.deleteGeoCity(cityId);
        await fetchCities();
        cancelEdit();
      } catch (error) {
        console.log('decline');
      }
    },
    [confirm]
  );
  const cancelEdit = useCallback(() => {
    setIsNew(false);
    setEditable(undefined);
  }, []);

  const editableCity = useMemo(() => {
    return cities.find((c) => c.Id === editableId);
  }, [editableId]);

  const addPolygon = (cityId: string, polygon: google.maps.Polygon) => {
    setPolygons((prevState) => {
      const newState = prevState.map((s) => ({ ...s }));

      return [
        ...newState,
        {
          cityId,
          polygon,
        },
      ];
    });
  };

  const removePolygon = useCallback((cityId: string) => {
    setPolygons((prevState) => {
      const index = prevState.findIndex((p) => p.cityId === cityId);

      if (index === -1) {
        return [...prevState];
      }

      const newState = [...prevState];

      newState.splice(index, 1);

      return [...newState];
    });
  }, []);

  const submitHandler = useCallback(
    formSubmitCatchAsync<ICityEditFormData>(async (formData) => {
      const polygonInfo = isNew
        ? polygons.find((p) => p.cityId === 'new')
        : polygons.find((p) => p.cityId === editableCity?.Id);

      if (!polygonInfo) {
        return;
      }

      const polygon = polygonInfo.polygon;
      const geoCity = getGeoCity(formData, polygonInfo.cityId, polygon.getPath().getArray());

      if (polygonInfo.cityId === 'new') {
        await GeoService.createGeoCity(geoCity);
      } else {
        await GeoService.updateGeoCity(geoCity);
      }

      await fetchCities();
      cancelEdit();
    }),
    [polygons, fetchCities, cancelEdit, editableCity]
  );

  const addNewHandler = useCallback(() => {
    setIsNew(true);
    setEditable('new');
  }, []);

  return (
    <MainLayout loading={detailsLoading}>
      <section>
        <PageTitleWithCount title="City manager" />

        <Box mt={2} mb={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledSaveButton disabled={!!editableCity || isNew} onClick={addNewHandler}>
                Add new
              </StyledSaveButton>
            </Grid>
            <Grid item>
              <CitiesMapWrapper
                cities={cities}
                hoveredId={hoveredId}
                setHovered={setHovered}
                editableId={editableId}
                setEditable={setEditable}
                addPolygon={addPolygon}
                removePolygon={removePolygon}
                isNew={isNew}
              />
            </Grid>
            <Grid item xs>
              <CitiesListWrapper
                cities={cities}
                hoveredId={hoveredId}
                setHovered={setHovered}
                editableId={editableId}
                setEditable={setEditable}
                deleteCity={deleteCity}
              />
            </Grid>
            {(editableCity || isNew) && (
              <Grid item xs={12}>
                <CityEditForm city={editableCity} cancelEdit={cancelEdit} saveHandler={submitHandler} />
              </Grid>
            )}
          </Grid>
        </Box>
      </section>
    </MainLayout>
  );
};
