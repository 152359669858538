import { useState, useRef, useEffect, useCallback } from 'react';
import { IGetPaginationResponse } from '../services/http/interfaces';

const count = '6';

export type IPaginationFetchFunc<ItemType> = (
  id: string,
  pageNumber: number,
  count: string
) => Promise<IGetPaginationResponse<ItemType>>;

interface IFetchState<ItemType> {
  isLoading: boolean;
  hasMore: boolean;
  page: ItemType[];
  totalCount: number;
}

export function useInfiniteScrolling<ItemType>(
  fetchFunc: IPaginationFetchFunc<ItemType>,
  id: string,
  root?: Element | null
): {
  lastElement(node: any): void;
  isLoading: boolean;
  page: ItemType[];
  totalCount: number;
} {
  const [pageNumber, setPageNumber] = useState(1);

  const [fetchState, setFetchState] = useState<IFetchState<ItemType>>({
    isLoading: false,
    hasMore: true,
    page: [],
    totalCount: 0,
  });
  const observer = useRef<IntersectionObserver>();

  useEffect(() => {
    setFetchState((lastState) => ({ ...lastState, isLoading: true }));
    fetchFunc(id, pageNumber, count).then(({ TotalItemCount, Page }) => {
      setFetchState((lastState) => ({
        isLoading: false,
        hasMore: lastState.page.length < TotalItemCount,
        page: lastState.page.concat(Page),
        totalCount: TotalItemCount,
      }));
    });
  }, [pageNumber, fetchFunc]);

  const lastElement = useCallback(
    (node) => {
      if (fetchState.isLoading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && fetchState.hasMore) {
            setPageNumber((prevPageNumber) => prevPageNumber + 1);
          }
        },
        { root }
      );

      if (node) {
        observer.current.observe(node);
      }
    },
    [fetchState.isLoading, fetchState.hasMore, observer, root]
  );

  return { lastElement, page: fetchState.page, isLoading: fetchState.isLoading, totalCount: fetchState.totalCount };
}
