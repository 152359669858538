import { makeStyles } from '@material-ui/core/styles';

export const useBackdropContainerStyles = makeStyles({
  LoadingContainer: {
    position: 'relative',
    borderRadius: 5,
    width: '100%',
  },
  Loading: {
    position: 'absolute',
    zIndex: 1,
  },
});
