import React, { useCallback, useEffect, useState } from 'react';
import { TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { CustomTHeadTextCell, SortStatuses } from './TextHeadCell';
import { useQuery } from '../../../utils/useQuery';
import { QueryParams } from '../../../enums';
import { CustomHeaderCheckbox, DeleteButton, useCustomTheadStyles } from './TableHeadStyles';
import { ICheckboxState } from '../../../intefaces';
import { StyledCheckbox } from '../../ui/StyledCheckbox';

export interface ITableHeadCellData {
  text: string;
  sortName?: string;
}

interface ITableHead {
  tableHeaders: ITableHeadCellData[];
  selectPage: () => void;
  unselectPage: () => void;
  checkedItemsState: ICheckboxState;
  deleteHandler: () => void;
}
type ITableHeadProps = (ITableHead & { editable: true }) | (Partial<ITableHead> & { editable: false });

export const CustomTableHead = ({
  tableHeaders = [],
  selectPage,
  unselectPage,
  checkedItemsState,
  deleteHandler,
  editable = true,
}: ITableHeadProps) => {
  const [currSortCounter, setCurrSortCounter] = useState(SortStatuses.DEFAULT);
  const [currSortColumn, setCurrSortColumn] = useState('');
  const { setParam, removeParams, currQueryParams } = useQuery();
  const classNames = useCustomTheadStyles();
  const selectedItems = Object.values(checkedItemsState || {}).filter((el) => el);

  const selectPageHandler = useCallback(
    (event) => {
      if (!unselectPage || !selectPage) {
        return;
      }

      event.stopPropagation();
      event.target.checked ? selectPage() : unselectPage();
    },
    [selectPage, unselectPage]
  );

  const sortBy = useCallback(
    (value: string) => {
      if (currSortColumn !== value) {
        removeParams([QueryParams.SORT_BY, QueryParams.SORT_BY_DESC, QueryParams.PAGE]);
        setParam(QueryParams.SORT_BY_DESC, value);
        setParam(QueryParams.PAGE, '1');
        setCurrSortCounter(SortStatuses.DESC);
      } else {
        if (currSortCounter === SortStatuses.DESC) {
          removeParams([QueryParams.SORT_BY_DESC, QueryParams.PAGE]);
          setParam(QueryParams.SORT_BY, value);
          setParam(QueryParams.PAGE, '1');
          setCurrSortCounter(SortStatuses.ASC);
        }

        if (currSortCounter === SortStatuses.ASC) {
          removeParams([QueryParams.SORT_BY, QueryParams.PAGE]);
          setCurrSortCounter(SortStatuses.DEFAULT);
          setParam(QueryParams.PAGE, '1');
        }

        if (currSortCounter === SortStatuses.DEFAULT) {
          setParam(QueryParams.SORT_BY_DESC, value);
          setParam(QueryParams.PAGE, '1');
          setCurrSortCounter(SortStatuses.DESC);
        }
      }

      setCurrSortColumn(value);
    },
    [removeParams, currSortColumn, currSortCounter]
  );

  useEffect(() => {
    const initialSortBy = currQueryParams.get(QueryParams.SORT_BY);
    const initialSortByDesc = currQueryParams.get(QueryParams.SORT_BY_DESC);

    setCurrSortColumn(initialSortBy || initialSortByDesc || '');
    let initialCurrSortCounter = SortStatuses.DEFAULT;

    if (initialSortBy) {
      initialCurrSortCounter = SortStatuses.ASC;
    }

    if (initialSortByDesc) {
      initialCurrSortCounter = SortStatuses.DESC;
    }

    setCurrSortCounter(initialCurrSortCounter);
  }, []);

  return (
    <TableHead>
      {editable && (
        <TableRow
          className={`${classNames.TableRow_selected} ${
            selectedItems.length > 0 ? classNames._show : classNames._hide
          }`}
        >
          <th className={classNames.TableCell_selected}>
            {selectedItems.length > 0 && (
              <div className={classNames.SelectedContainer}>
                <div>
                  <CustomHeaderCheckbox checked={true} onChange={selectPageHandler} />
                  <Typography variant="h1" className={classNames.SelectedNumber}>
                    {selectedItems.length} SELECTED
                  </Typography>
                </div>
                <DeleteButton className={classNames.DeleteButton} onClick={deleteHandler}>
                  DELETE
                </DeleteButton>
              </div>
            )}
          </th>
          <th />
        </TableRow>
      )}
      <TableRow className={classNames.TableRow}>
        <TableCell className={classNames.TableCheckboxSell}>
          {editable && <StyledCheckbox checked={false} onChange={selectPageHandler} />}
        </TableCell>
        {tableHeaders.map(({ sortName, text }) => {
          const sortData = sortName
            ? {
                status: currSortColumn === sortName ? currSortCounter : SortStatuses.DEFAULT,
                handler: () => sortBy(sortName),
              }
            : undefined;

          return <CustomTHeadTextCell key={text} text={text} sortData={sortData} />;
        })}
      </TableRow>
    </TableHead>
  );
};
