import { ICategory } from '../interfaces';
import { ILocation } from '../company/interfaces';
import { ILanguage } from '../../../intefaces';

export interface ICandidate {
  Id: string;
  FullName: string;
  Email: string;
  Languages: ILanguage[];
  LocationInfo: {
    City: string;
    Country: string;
  };
  Statistics: {
    ApplicationsCount: number;
    RejectsCount: number;
    ShortlistsCount: number;
  };
  Thumbnail: string;
  Status: number;
  CreatedOn: string;
}

export interface IJobHistoryItem {
  Title: string;
  CompanyName: string;
  From: string;
  To: string;
  Categories: ICategory[];
}

export enum WorkExperience {
  'No experience',
  'Few years',
  'More than three years',
}

export enum DriverLicenseCategory {
  None = 0,
  A1 = 1,
  B = 2,
  BE = 4,
  C = 8,
  CE = 16,
  D = 32,
  DE = 64,
}

export enum WorkHours {
  None = 0,
  Weekends = 1,
  NightShifts = 2,
  DayShifts = 4,
  All = 7,
}

export interface IGetCandidateDetails {
  Id: string;
  FullName: string;
  Thumbnail: string;
  Email: string;
  Status: 0;
  Birthday: string;
  ReadEventsOn: string;
  Role: 0;
  CompanyId: string;
  IsEmailConfirmed: boolean;
  Settings: {
    IsEmailNotifications: boolean;
    NewJobPush: true;
    GeneralPush: true;
  };
  Acceptance: {
    Version: string;
    Timestamp: string;
  };
  LastUsedOn: string;
  AboutMe: string;
  Location: ILocation;
  Experience: WorkExperience;
  DriverLicenseCategory?: DriverLicenseCategory;
  WorkPermission?: boolean;
  WorkHours?: WorkHours;
  Educations: string[];
  Languages: ILanguage[];
  JobHistory: IJobHistoryItem[];
  FacebookProfile: string;
  LinkedinProfile: string;
  Phone?: string;
}

export interface IGetCandidatesResponse {
  PageCount: number;
  TotalItemCount: number;
  PageIndex: number;
  Page: ICandidate[];
}
