import React, { useCallback, useState } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { debounce, TextField } from '@material-ui/core';
import { useSearchFieldStyles } from './SearchStyles';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { useQuery } from '../../utils/useQuery';
import { QueryParams } from '../../enums';
import CloseIcon from '@material-ui/icons/Close';

export const Search = () => {
  const classNames = useSearchFieldStyles();
  const { setParam, removeParams, currQueryParams } = useQuery();
  const [value, setValue] = useState(currQueryParams.get(QueryParams.QUERY) || '');

  const setSearchParams = useCallback(
    debounce((val: string) => {
      if (val.length >= 3) {
        setParam(QueryParams.QUERY, val);
        setParam(QueryParams.PAGE, '1');

        return;
      } else {
        removeParams([QueryParams.QUERY]);
      }
    }, 600),
    [removeParams, setParam]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = (e.currentTarget.value || '').substring(0, 40);
      setValue(value);
      setSearchParams(value);
    },
    [setValue, setSearchParams]
  );

  const clearInput = useCallback(() => {
    setValue('');
    removeParams([QueryParams.QUERY]);
  }, [setValue, removeParams]);

  return (
    <TextField
      className={classNames.Input}
      id="input-with-icon-textfield"
      placeholder="Search"
      value={value}
      onChange={handleInputChange}
      InputProps={{
        disableUnderline: true,
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            {value.length > 0 && <CloseIcon className={classNames.CloseIcon} onClick={clearInput} />}
          </InputAdornment>
        ),
      }}
    />
  );
};
