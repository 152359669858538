import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { MainLayout } from '../../../layouts/Home/MainLayout';
import { HttpService } from '../../../services/http/axios';
import useAxios from 'axios-hooks';
import { IGetVacancy, VACANCY_EXPERIENCE } from '../../../services/http/jobs/interfaces';
import { MainHeader } from './Header';
import { InfoItem } from '../../../components/Info';
import { createDateTime, DateTimeFormat } from '../../../utils/createDateTime';
import member from '../../../assets/member.svg';
import timeIcon from '../../../assets/jobDetails/timeIcon.svg';
import jobTypeIcon from '../../../assets/jobDetails/jobType.svg';
import languagesIcon from '../../../assets/language.svg';
import dateIcon from '../../../assets/birthday.svg';
import bag from '../../../assets/bag.svg';
import { useMainStyles } from './MainStyles';
import { Applications } from './Applications';
import { DeleteButton, EditButton } from '../../../components/DeleteEditButtons';
import { deleteByIds } from '../../../utils/deleteByIds';
import { CompaniesService } from '../../../services/http/company';
import { useNotificationContext } from '../../../utils/notificationContext';
import { useConfirm } from 'material-ui-confirm';
import { useHistory } from 'react-router';
import { DetailsDescription } from '../../../components/DetailsDescription';
import { QuestionsList } from './QuestionsList';
import { useAccount } from 'utils/useAccount';

const jobType: Record<number, string> = {
  1: 'Full-time',
  2: 'Part-time',
};

const getJobType = (n: number) => {
  if (n === 3) {
    return Object.values(jobType).join(', ');
  }

  return jobType[n];
};

const getExperience = (experience: VACANCY_EXPERIENCE) => {
  switch (experience) {
    case VACANCY_EXPERIENCE.ANY:
      return 'Any';
    case VACANCY_EXPERIENCE.OVER_YEAR:
      return 'Over 1 yr';
    case VACANCY_EXPERIENCE.OVER_THREE_YEARS:
      return 'Over 3 yrs';
  }
};

export const JobDetailsMain = () => {
  const { jobId, companyId } = useParams<{ jobId: string; companyId: string }>();
  const classNames = useMainStyles();
  const history = useHistory();
  const { setNotification } = useNotificationContext();
  const confirm = useConfirm();
  const { isAdmin } = useAccount();

  const [{ data: jobDetails, loading: detailsLoading }] = useAxios<IGetVacancy>(
    `${HttpService.env.adminAPI}/companies/${companyId}/jobs/${jobId}`,
    { useCache: false }
  );

  const deleteHandler = useCallback(
    () =>
      deleteByIds(
        [jobId],
        (jobId: string) => CompaniesService.deleteCompanyJob(companyId, jobId),
        setNotification,
        `${jobDetails?.Name} job deleted`,
        confirm,
        'This active job will be immediately deleted'
      )
        .then(history.goBack)
        .catch(() => {}),
    [jobId, companyId, CompaniesService.deleteCompanyJob, jobDetails?.Name, setNotification, confirm]
  );

  const editHandler = useCallback(() => {
    history.push(`/company/${companyId}/job/${jobId}/edit`);
  }, [jobId, companyId]);

  return (
    <MainLayout loading={detailsLoading} hasBackPath={true}>
      <>
        {jobDetails && (
          <>
            <MainHeader
              name={jobDetails.Name}
              companyName={jobDetails.Company.Name}
              location={jobDetails.Location}
              status={jobDetails.Status}
              wage={jobDetails.Wage}
            />
            <Grid container spacing={4} className={classNames.InfoContainer}>
              <InfoItem image={dateIcon} title="Date of Job creation">
                <Typography color="textPrimary" variant="subtitle1">
                  {createDateTime(jobDetails.PublishDate).toFormat(DateTimeFormat.FULL)}
                </Typography>
              </InfoItem>
              <InfoItem image={timeIcon} title="Start Date">
                <Typography color="textPrimary" variant="subtitle1">
                  {jobDetails.StartDate ? 'Immediate Start' : 'No exact'}
                </Typography>
              </InfoItem>
              <InfoItem image={bag} title="Job Category">
                <Typography color="textPrimary" variant="subtitle1">
                  {jobDetails.Category.Name}
                </Typography>
              </InfoItem>
              <InfoItem image={jobTypeIcon} title="Job Type">
                <Typography color="textPrimary" variant="subtitle1">
                  {getJobType(jobDetails.JobType)}
                </Typography>
              </InfoItem>
              <InfoItem image={member} title="Experience">
                <Typography color="textPrimary" variant="subtitle1">
                  {getExperience(jobDetails.Experience)}
                </Typography>
              </InfoItem>
              <InfoItem image={languagesIcon} title="Languages">
                <Typography color="textPrimary" variant="subtitle1">
                  {jobDetails.Languages.length ? jobDetails.Languages.join(', ') : "Doesn't matter"}
                </Typography>
              </InfoItem>
            </Grid>
            {jobDetails.Schedule && <DetailsDescription title="Schedule">{jobDetails.Schedule}</DetailsDescription>}
            <DetailsDescription title="Description">{jobDetails.Description}</DetailsDescription>
            {jobDetails.Form?.Questions && <QuestionsList questions={jobDetails.Form.Questions} />}
            {isAdmin && (
              <Grid container justify="flex-end" spacing={2} alignItems="center">
                {jobDetails.Status === 0 && (
                  <Grid item>
                    <EditButton onClick={editHandler} />
                  </Grid>
                )}
                {jobDetails.Status !== 2 && (
                  <Grid item>
                    <DeleteButton className={classNames.DeleteButton} onClick={deleteHandler} />
                  </Grid>
                )}
              </Grid>
            )}
            <Applications statistics={jobDetails.Statistics} />
          </>
        )}
      </>
    </MainLayout>
  );
};
