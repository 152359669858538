import { makeStyles } from '@material-ui/core/styles';

export const useDetailsFormStyles = makeStyles({
  Title: {
    marginTop: 10,
  },
  Logo: {
    margin: '20px 0',
  },
});

export const useInnerFormStyles = makeStyles({
  InnerForm: {
    width: 750,
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: 15,
  },
  MapContainerClassName: {
    height: 200,
    gridColumn: '1/3',
  },
  _full: {
    gridColumn: '1/3',
  },
  AddEditContainer: {
    margin: '8px 0',
    gridColumn: '1/3',
  },
  CancelSubmitContainer: {
    gridColumn: '1/3',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  CancelButton: {
    marginRight: 20,
  },
});
