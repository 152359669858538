import React from 'react';
import { ILocation } from '../../../../services/http/company/interfaces';
import { useMainHeaderStyles } from './HeaderStyles';
import { Typography } from '@material-ui/core';
import { Badge } from '../../../../components/Badge';
import { getJobStatusBadgeData } from '../../../../utils/getJobStatusBadgeData';
import wageImg from '../../../../assets/wage.svg';
import { useCommonMainHeaderStyles } from '../../../../styles/MainHeaderStyles';
import { LocationLink } from '../../../../components/LocationLink';

interface IMainHeaderProps {
  companyName: string;
  name: string;
  location: ILocation;
  status: number;
  wage?: number;
}

export const MainHeader = ({ companyName, name, location, status, wage }: IMainHeaderProps) => {
  const classNames = useMainHeaderStyles();
  const commonHeaderClassNames = useCommonMainHeaderStyles();
  const jobStatusBadgeDate = getJobStatusBadgeData(status);

  return (
    <header className={classNames.Header}>
      <section className={classNames.ProfileInfoSection}>
        <Typography variant="h5">{companyName}</Typography>
        <Typography classes={{ root: commonHeaderClassNames.Title }} variant="h1">
          {name}
        </Typography>
        <LocationLink location={location} />
      </section>
      <div>
        <Badge text={jobStatusBadgeDate.text} type={jobStatusBadgeDate.type} />
        {wage && (
          <span className={classNames.Wage}>
            <img className={classNames.WageImg} src={wageImg} /> {wage} Kr/ Hour
          </span>
        )}
      </div>
    </header>
  );
};
